import React from 'react';
import { useHistory } from 'react-router-dom';

import { BASE_ROUTE, FlowNames, SITEMINDER_PARAM_PREFIX } from 'src/app/Constants';
import CommonUtility from 'src/app/CommonUtility';
import { buildHsidPath, parseQueryParams, parseHsidPath } from 'src/utils/urlUtility';
import { logger } from 'src/app/Logger';

import type { ComponentType } from 'react';
import type { Location } from 'history';

interface RewriteConfig {
    test: (pathname: string, queryParams: Record<string, string>) => boolean;
    rewrite: (pathname: string, queryParams: Record<string, string>) => Partial<Location>;
}

// EXAMPLE: /rt/login?TARGET=https%3A%2F%2Faccounts.myuhc.com%3A3001%2Frt%2Fsecure%2Fauth%2Fcommunityplan%2Fen
// Also persists other query params listed in the code
export const siteminderUrlRewrite: RewriteConfig = {
    test(pathname, queryParams) {
        const isTargetParamValid = (targetParamValue: string) => {
            if (typeof targetParamValue !== 'string') {
                return false;
            }

            try {
                return !!new URL(decodeURIComponent(targetParamValue));
            } catch (error) {
                return false;
            }
        };
        return pathname === [BASE_ROUTE, FlowNames.LOGIN].join('/') && isTargetParamValid(queryParams.TARGET);
    },
    rewrite(pathname, queryParams) {
        const targetUrl = new URL(queryParams.TARGET.replace(SITEMINDER_PARAM_PREFIX, '').replace(/\$/g, ''));
        const { portalBrand, lang = 'en' } = parseHsidPath(targetUrl.pathname);
        const loginPath = buildHsidPath({
            flowName: FlowNames.LOGIN,
            portalBrand: portalBrand as string,
            lang,
        });

        const params: Record<string, string> = {
            TARGET: targetUrl.toString(),
        };

        if (queryParams.maxInvalidattempts) {
            params.errorCode = 'MAX_INVALID_ATTEMPTS_USER_LOCKED';
        }

        ['resume', 'reason', 'last_reason'].forEach(key => {
            if (queryParams[key]) {
                params[key] = queryParams[key];
            }
        });

        return {
            pathname: loginPath,
            search: `?${CommonUtility.stringifyQueryParams(params)}`,
        };
    },
};

// NAME: Query Param HSID URL
// EXAMPLE: /rt/login?portal=myuhc&lang=en
export const queryParamInboundHsidUrlRewrite: RewriteConfig = {
    test(pathname, queryParams) {
        const pathSegments = parseHsidPath(pathname);

        return (
            Boolean(!pathSegments.portalBrand && queryParams.portal) && Boolean(!pathSegments.lang && queryParams.lang)
        );
    },
    rewrite(pathname, { portal, lang }) {
        return {
            pathname: `${pathname.replace(BASE_ROUTE, '')}/${portal}/${lang}`,
        };
    },
};

// NAME: OBA Evaluate URL
// EXAMPLE: /rt/evaluate/:portal/:lang?TARGET=/rt/secure/auth/:portal/:lang&
// returnOnAASysError=true&RBASESSIONTOKEN=token&callback=ping.auth&portalBrand=myuhc&
// lang=en&REMEMBERME=1&aaToken=token&RBA_BASE_URL=/rba/url
// This will strip out unnecessary params, but a secondary rewrite will happen within the OBA components
export const obaEvaluateUrlRewrite: RewriteConfig = {
    test(pathname) {
        return pathname.startsWith([BASE_ROUTE, FlowNames.EVALUATE].join('/'));
    },
    rewrite(pathname, { REMEMBERME, aaToken, callback, RBASESSIONTOKEN: rbaSessionToken }) {
        const queryParams: Record<string, string | boolean> = {
            aaToken,
            callback,
            shouldTrustDevice: REMEMBERME === '1',
            rbaSessionToken,
        };
        const filteredQueryParams = Object.keys(queryParams).reduce(
            (acc, key) => (queryParams[key] !== undefined ? { ...acc, [key]: queryParams[key] } : acc),
            {}
        );
        return {
            pathname: pathname.replace(BASE_ROUTE, ''),
            search: `?${CommonUtility.stringifyQueryParams({
                ...filteredQueryParams,
            })}`,
        };
    },
};

/**
 * An array of rewrite config objects. Each rewrite config object consists of the following keys:
 * @property {Function} test A function that takes a URL pathname and object of decoded URL params and returns Boolean.
 *                           default to empty object. The `pathname` arg should default to an empty string and the
 *                           `queryParams` arg should
 * @property {Function} rewrite A function that takes a URL pathname and object of decoded URL params and returns an
 *                              object compatible with the React Router's `history.push|replace()` functions. The
 *                              arguments for `rewrite` don't need default values.
 */
const rewriteConfigs: RewriteConfig[] = [siteminderUrlRewrite, queryParamInboundHsidUrlRewrite, obaEvaluateUrlRewrite];

/**
 * The `InboundUrlRewriter` HOC will evaluate all the possible rewrite cases defined in `rewriteConfigs` and if one
 * is detected, it will update the browser URL. The purpose is to provide flexibility for external systems to
 * navigate a user to HSID with non-standard URLs that HSID doesn't work with internally and convert them to
 * standardized HSID URL paths.
 */
function withInboundUrlRewriter<P extends JSX.IntrinsicAttributes>(Component: ComponentType<P>): ComponentType<P> {
    const InboundUrlRewriter = (props: P) => {
        const history = useHistory();
        const { pathname, search } = window.location;
        const queryParams = parseQueryParams(search);
        const { rewrite } = rewriteConfigs.find(({ test }) => test(pathname, queryParams)) || {};

        if (rewrite) {
            const newLocation = rewrite(pathname, queryParams);
            logger.info('rewriting inbound URL', { url: newLocation });
            history.replace(newLocation);
        }

        return <Component {...props} />;
    };

    return InboundUrlRewriter;
}

export default withInboundUrlRewriter;
