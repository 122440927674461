import { UsaCountryCode } from './constants';

/**
 * Format an unformatted US/Canada phone number (10 numeric characters)
 * No Format for countries other than US/Canada phone number (7-15 characters)
 *
 * @param phoneNumber will be phone number as string ex "1231231234" or "123123123123"
 * @param countryCode use to format for countries with countryCode 1, default 1
 */
export const getIntlFormattedPhoneNumber = (phoneNumber: string, countryCode: string = UsaCountryCode): string => {
    if (!phoneNumber) {
        return '';
    }

    if (countryCode === UsaCountryCode) {
        if (phoneNumber.match(/^x{6}\d{4}$/)) {
            // check if it has masking character "x"
            return `(xxx) xxx-${phoneNumber.slice(-4)}`; // last 4 characters
        }
        return phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    }

    return phoneNumber;
};

// Used when we want to display (countryCode + phoneNumber)
export const getIntlFormattedPhoneNumberWithCountryCode = (
    phoneNumber: string,
    countryCode: string = UsaCountryCode, // If countryCode is missing default to UsaCountryCode, to support legacy users
    withPlus = true
): string => {
    // Number already formatted with countryCode
    // Prevents duplicating country code for fully formatted phone number hints from the back end, which should always include a +
    if (phoneNumber.startsWith('+')) {
        return phoneNumber;
    }
    if (!phoneNumber) {
        return '';
    }
    return [
        withPlus && countryCode ? '+' : '',
        countryCode ? `${countryCode} ` : '',
        getIntlFormattedPhoneNumber(phoneNumber, countryCode),
    ].join('');
};
