import React from 'react';
import PropTypes from 'prop-types';

import { svgProps } from './utils';

const propTypes = {
    variant: PropTypes.oneOf(['visible', 'hidden']),
};

const defaultProps = {
    variant: 'visible',
};

const paths = {
    visible:
        'M12 6c4.224 0 7.557 2.1 10 6.298-2.295 3.84-5.567 5.74-9.817 5.701-4.25-.038-7.645-2-10.183-5.883C4.443 8.04 7.776 6 12 6zm0 1.714c-2.3 0-4.166 1.92-4.166 4.286 0 2.367 1.866 4.286 4.167 4.286 2.301 0 4.166-1.92 4.166-4.286 0-2.367-1.865-4.286-4.166-4.286zm-.026 1.262a3 3 0 110 6 3 3 0 010-6z',
    hidden: 'M19.431 4.88a.5.5 0 010 .707L5.29 19.729a.5.5 0 11-.707-.707L18.724 4.88a.5.5 0 01.707 0zm-1.114 3.087c1.217.851 2.85 2.852 3.683 4.337-2.215 3.982-5.75 5.754-10 5.754-.52 0-2.114-.006-3.026-.562l9.343-9.529zM12.687 6c.64.012 2.42.31 2.961.566l-9.643 9.639C4.524 15.4 2.901 13.475 2 12.116 4.954 7.386 8.104 5.915 12.687 6z',
};

const EyeIcon = ({ variant }) => (
    <svg {...svgProps} width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" fillRule="evenodd" d={paths[variant]} />
    </svg>
);

EyeIcon.propTypes = propTypes;
EyeIcon.defaultProps = defaultProps;

export default EyeIcon;
