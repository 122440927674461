import compose from 'src/utils/compose';
import pipe from 'src/utils/pipe';
import identity from 'src/utils/identity';

import { StorageRecord } from './StorageRecord';

import type { StorageRecordData, StorageRecordMeta } from './StorageRecord';
import type { StorageDriver, StorageGetOptions } from './types';

/**
 * An in-memory implementation of a web Storage API (sessionStorage/localStorage). All implemented
 * methods should conform to the behavior of their native counterparts. Can be used for testing.
 */
const createMemoryStorage = (initialState: Record<string, string> = {}): Storage => {
    let state = { ...initialState };

    return {
        getItem(key: string) {
            return state[key] || null;
        },
        setItem(key: string, value: string) {
            state[key] = String(value);
        },
        removeItem(key: string) {
            delete state[key];
        },
        clear() {
            state = {};
        },
        key(index: number) {
            return Object.keys(state)[index] || null;
        },
        valueOf() {
            return state;
        },
        get length() {
            return Object.keys(state).length;
        },
    };
};

export interface WebStorageDriverEncoder {
    encode(this: void, value: string): string;
    decode(this: void, value: string): string;
}

const defaultEncoder: WebStorageDriverEncoder = {
    encode: identity,
    decode: identity,
};

const defaultNow = () => Date.now();

interface WebStorageDriverConfig {
    keyPrefix?: string;
    encoder?: WebStorageDriverEncoder;
    now?: () => number;
}

/**
 * Factory to create Storage drivers that work with interfaces that conforms to the Web Storage API
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Storage
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API
 *
 * @param {Storage} storage An object that conforms to the Web Storage API
 * @param {Object} config An object that configures aspects of the WebStorageDriver instance
 * @param {String} config.keyPrefix A string value that is prepended to all keys to help prevent
 *                                  collisions
 * @param {Object} config.encoder An object that provides `encode` and `decode` functions that will
 *                                encode and decode values being set and retrieved from the store
 */
const createWebStorageDriver =
    (storage: Storage) =>
    ({ keyPrefix = '', encoder = defaultEncoder, now = defaultNow }: WebStorageDriverConfig = {}): StorageDriver => {
        if (typeof encoder.encode !== 'function' || typeof encoder.decode !== 'function') {
            throw new TypeError(
                'WebStorageDriver instance requires `encoder.encode` and `encoder.decode` to both be set and be functions'
            );
        }

        const getKey = (key: string): string => `${keyPrefix}${key}`;
        const getRecord: (key: string) => StorageRecord | null = pipe(
            getKey,
            storage.getItem.bind(storage),
            StorageRecord.fromStorage
        );
        const hasRecordExpired = ({ expires }: StorageRecordData) => expires != null && now() > expires;

        const inst: StorageDriver = {
            put(key, value, options = {}) {
                const { isEncoded = false, expires = null, meta } = options;
                const preparedValue = compose(isEncoded ? encoder.encode : identity, JSON.stringify)(value) as string;

                const record = StorageRecord({
                    value: preparedValue,
                    expires: expires && Number.isFinite(expires) ? now() + expires : undefined,
                    createdAt: now(),
                    meta,
                });

                storage.setItem(getKey(key), record.toStorage());
            },
            get<T>(
                key: string,
                { remove = false, isEncoded = false, includeMeta = false }: StorageGetOptions = {}
            ): { meta: StorageRecordMeta; value: T } | T | null {
                const record = getRecord(key);

                if (record === null) {
                    return null;
                }

                if (hasRecordExpired(record)) {
                    this.remove(key);
                    return null;
                }

                const preparedResult = JSON.parse(isEncoded ? encoder.decode(record.value) : record.value) as T;

                if (remove) {
                    this.remove(key);
                }

                return includeMeta ? { meta: record.getMeta(), value: preparedResult } : preparedResult;
            },
            remove(key) {
                storage.removeItem(getKey(key));
            },
            has(key) {
                const record = getRecord(key);

                if (record === null) {
                    return false;
                }

                if (hasRecordExpired(record)) {
                    return false;
                }

                return true;
            },
            clear() {
                storage.clear();
            },
            updateMeta(key, meta = {}): void {
                // No metadata was provided
                if (Object.keys(meta).length === 0) {
                    return;
                }

                const record = getRecord(key);
                if (record === null) {
                    return;
                }

                const newRecord = record.update({
                    meta: {
                        ...record.meta,
                        ...meta,
                    },
                });

                storage.setItem(getKey(key), newRecord.toStorage());
            },
            get length() {
                return storage.length;
            },
        };

        return Object.defineProperties(inst, {
            isSupported: {
                get() {
                    const x = '__hsid__';
                    try {
                        storage.setItem(x, x);
                        storage.removeItem(x);
                        return true;
                    } catch (e) {
                        return false;
                    }
                },
            },
        });
    };

const MemoryStorageDriver = createWebStorageDriver(createMemoryStorage());
const SessionStorageDriver = createWebStorageDriver(
    typeof window !== 'undefined' && 'sessionStorage' in window ? window.sessionStorage : createMemoryStorage()
);
const LocalStorageDriver = createWebStorageDriver(
    typeof window !== 'undefined' && 'localStorage' in window ? window.localStorage : createMemoryStorage()
);

export { createMemoryStorage, createWebStorageDriver, MemoryStorageDriver, SessionStorageDriver, LocalStorageDriver };
