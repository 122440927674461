// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormErrorsSummary_root__wi4lW {\n  padding: 15px 10px;\n  background-color: #fbf1e2;\n  color: #ac2b00;\n  max-width: 468px;\n  text-align: left;\n}\n.FormErrorsSummary_root__wi4lW:focus {\n  outline: 2px solid #009de6;\n}\n.FormErrorsSummary_root__wi4lW a {\n  color: inherit !important;\n  text-decoration: underline;\n}\n\n.FormErrorsSummary_iconWrapper__\\+ooxu {\n  align-items: flex-start;\n}\n\n.FormErrorsSummary_fieldErrorList__7Gi3T {\n  list-style: disc;\n}\n.FormErrorsSummary_fieldErrorList__7Gi3T > * {\n  margin-top: 4px;\n}\n\n.FormErrorsSummary_fieldErrorLink__6EGqQ {\n  font-weight: normal;\n}", "",{"version":3,"sources":["webpack://./src/UI/Form/FormErrorsSummary/FormErrorsSummary.module.scss"],"names":[],"mappings":"AAGA;EACI,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;EACA,gBAAA;AAFJ;AAII;EACI,0BAAA;AAFR;AAKI;EACI,yBAAA;EACA,0BAAA;AAHR;;AAOA;EACI,uBAAA;AAJJ;;AAOA;EACI,gBAAA;AAJJ;AAMI;EACI,eAAA;AAJR;;AAQA;EACI,mBAAA;AALJ","sourcesContent":["@import 'src/sass/uhc/tokens/uhc.scss';\n@import 'src/sass/helpers/variables';\n\n.root {\n    padding: 15px 10px;\n    background-color: #fbf1e2;\n    color: #ac2b00;\n    max-width: 468px;\n    text-align: left;\n\n    &:focus {\n        outline: 2px solid $focus-ring-color;\n    }\n\n    a {\n        color: inherit !important;\n        text-decoration: underline;\n    }\n}\n\n.iconWrapper {\n    align-items: flex-start;\n}\n\n.fieldErrorList {\n    list-style: disc;\n\n    > * {\n        margin-top: 4px;\n    }\n}\n\n.fieldErrorLink {\n    font-weight: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FormErrorsSummary_root__wi4lW",
	"iconWrapper": "FormErrorsSummary_iconWrapper__+ooxu",
	"fieldErrorList": "FormErrorsSummary_fieldErrorList__7Gi3T",
	"fieldErrorLink": "FormErrorsSummary_fieldErrorLink__6EGqQ"
};
export default ___CSS_LOADER_EXPORT___;
