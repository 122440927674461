import { LanguageCode, isSupportedLanguage, SupportedLanguageCode } from 'src/utils/lang';

/**
 * @param lang language code from the url
 * @returns the language code or 'en' if not supported
 */
const getSupportedLanguage = (lang?: string | null): SupportedLanguageCode =>
    isSupportedLanguage(lang) ? lang : LanguageCode.EN;

export default getSupportedLanguage;
