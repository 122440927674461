import { ensureArray } from 'src/utils/arr';

import type { LogData, LogFn } from '../types';

type BatchLogsConfigArg = {
    maxEntries: number;
    timeoutMs: number;
};

export const batchLogs = ({ maxEntries, timeoutMs }: BatchLogsConfigArg, log: LogFn): LogFn => {
    let timerId: ReturnType<typeof setTimeout>;
    // NOTE we're letting `queue` be a mutable variable so that we reset it by assigning it a new empty queue rather
    // than truncate it. This will help with testing since reusing the referentially identical array makes testing this
    // difficult and will help with immutability outside of this function.
    let queue: LogData[] = [];
    const clearQueue = () => {
        queue = [];
    };

    const flushQueue = () => {
        if (!queue.length) return;
        log(queue);
        clearQueue();
    };

    // Without this check, we need to setup the browser environment for almost every test
    if (typeof document !== 'undefined') {
        // Flush the logs when the document visibility changes to "hidden"
        // See https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event#sending_end-of-session_analytics_on_transitioning_to_hidden
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden') {
                flushQueue();
            }
        });
    }

    return logData => {
        // Reset the timer
        clearTimeout(timerId);
        // Wrap the logData in an array if not already
        queue.push(...ensureArray(logData));
        if (queue.length >= maxEntries) {
            flushQueue();
        } else {
            // Set a timer to fire with all queued entries
            timerId = setTimeout(flushQueue, timeoutMs);
        }
    };
};
