import { sequence } from 'src/utils/sequence';
import compose from 'src/utils/compose';
import { HSID_BASE_URL } from 'src/app/Constants';

import { LogLevel } from './LogLevel';
import { logAtLevel } from './utils/logAtLevel';
import { batchLogs } from './utils/batchLogs';
import { logToConsole } from './LoggerDestination/ConsoleLogger';
import { logToAggregator } from './LoggerDestination/AggregatorLogger';

import type { ConsoleDriver } from './LoggerDestination/ConsoleLogger';
import type { ILogger, LogData, LogFn } from './types';

const hsid11AggregatorEndpoint = new URL('/direct-logger/ui-logger', HSID_BASE_URL);

type CreateDefaultLogFnConfig = {
    /** The minimum log level for the console LogDestination. Defaults to the `__CONSOLE_LOG_LEVEL` env var */
    consoleLevel?: string;
    /** An overridden console driver to use for the console logger */
    consoleDriver?: ConsoleDriver;
    /** The minimum log level for the aggregator LogDestination. Defaults to the `__CONSOLE_LOG_LEVEL` env var */
    aggregatorLevel?: string;
    /** An overridden aggregator `send` function */
    aggregatorSend?: Parameters<typeof logToAggregator>[0]['send'];
    /** An overridden aggregator logging endpoint */
    aggregatorUrl?: Parameters<typeof logToAggregator>[0]['url'];
};

/**
 * Create a LogFn for the HSID UI app
 *
 * Calling this function without an arg will create the default app LogFn implementation. Overriding any of the object
 * properties is useful for testing.
 *
 * @private
 * @param config Config object for the App LogFn
 * @returns With no arguments, the default LogFn for the HSID UI app, otherwise a configured LogGn for other purposes (like testing)
 */
export const createAppLogFn = ({
    consoleLevel = process.env.__CONSOLE_LOG_LEVEL,
    consoleDriver,
    aggregatorLevel = process.env.__AGGREGATOR_LOG_LEVEL,
    aggregatorSend,
    aggregatorUrl = hsid11AggregatorEndpoint,
}: CreateDefaultLogFnConfig = {}): LogFn => {
    const consoleLogger = logAtLevel(LogLevel.from(consoleLevel) ?? LogLevel.DISABLED, logToConsole({ consoleDriver }));

    const aggregatorLogger = compose(
        logAtLevel.bind(null, LogLevel.from(aggregatorLevel) ?? LogLevel.DISABLED),
        batchLogs.bind(null, { maxEntries: 5, timeoutMs: 300 })
    )(logToAggregator({ url: aggregatorUrl, send: aggregatorSend })) as LogFn;

    return sequence(consoleLogger, aggregatorLogger);
};

const getCommonLogData = () => ({
    url: globalThis.location?.href ?? '<unknown>',
});

const defaultNowDate = () => new Date();

type CreateLoggerConfig = {
    /** Configurable function to produce a Date for `LogData['timestamp']` */
    nowDate?: () => Date;
    /** Optional subset of `LogData['auditData']` to be sent with every logger call */
    boundAuditData?: Partial<LogData['auditData']>;
};

/**
 * Create a Logger instance
 *
 * @param [log] The LogFn to connect to the public API. Defaults to application configured LogFn
 * @param [config] Optional additional configuration for the logger
 * @returns A new ILogger instance
 */
export const createLogger = (
    log: LogFn = createAppLogFn(),
    { boundAuditData = {}, nowDate = defaultNowDate }: CreateLoggerConfig = {}
): ILogger => ({
    debug(message, auditData = {}) {
        log({
            level: LogLevel.DEBUG,
            message,
            timestamp: nowDate(),
            auditData: { ...getCommonLogData(), ...boundAuditData, ...auditData },
        });
    },
    info(message, auditData = {}) {
        log({
            level: LogLevel.INFO,
            message,
            timestamp: nowDate(),
            auditData: { ...getCommonLogData(), ...boundAuditData, ...auditData },
        });
    },
    warn(message, auditData = {}) {
        log({
            level: LogLevel.WARN,
            message,
            timestamp: nowDate(),
            auditData: { ...getCommonLogData(), ...boundAuditData, ...auditData },
        });
    },
    error(message, auditData = {}) {
        log({
            level: LogLevel.ERROR,
            message,
            timestamp: nowDate(),
            auditData: { ...getCommonLogData(), ...boundAuditData, ...auditData },
        });
    },
    fatal(message, auditData = {}) {
        log({
            level: LogLevel.FATAL,
            message,
            timestamp: nowDate(),
            auditData: { ...getCommonLogData(), ...boundAuditData, ...auditData },
        });
    },
    withData(partialAuditData) {
        return createLogger(log, { boundAuditData: partialAuditData, nowDate });
    },
});
