// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormActions_root__k5t1Q {\n  border-top: 1px solid #ccc;\n  padding-top: 24px;\n}", "",{"version":3,"sources":["webpack://./src/UI/Form/FormActions/FormActions.module.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,iBAAA;AACJ","sourcesContent":[".root {\n    border-top: 1px solid #ccc;\n    padding-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "FormActions_root__k5t1Q"
};
export default ___CSS_LOADER_EXPORT___;
