/**
 * Takes a callback and delay and returns the debounce function and teardown function.
 *
 * @param callback A callback function to be called after a delay.
 * @param delay The delay in ms after which the callback function needs to be called.
 * @returns A debounce function which on invoke debounces the callback function and calls it after set delay.
 */
const debounce = <A extends any[], R = void>(
    callback: (...args: A) => R,
    delay: number
): [(...args: A) => void, () => void] => {
    let timerId: ReturnType<typeof setTimeout>;
    const debouncedFunc = (...args: A) => {
        clearTimeout(timerId);
        timerId = setTimeout(() => {
            callback.apply(this, args);
        }, delay);
    };

    const cleanupDebounce = () => clearTimeout(timerId);
    return [debouncedFunc, cleanupDebounce];
};

export default debounce;
