import React from 'react';
import classNames from 'classnames';

import type { FC } from 'react';

import classes from './FormContainer.module.scss';

export interface FormContainerProps {
    id?: string;
    className?: string;
    removeTopBottomSpacing?: boolean;
    bg?: 'grey';
}

const FormContainer: FC<FormContainerProps> = ({ children, bg, removeTopBottomSpacing = false, className, id }) => (
    <div
        id={id}
        className={classNames(classes.root, className, {
            [classes.hasBackground]: typeof bg !== 'undefined',
            [classes.bgGrey]: bg === 'grey',
            [classes.removeTopBottomSpacing]: removeTopBottomSpacing,
        })}
    >
        {children}
    </div>
);

export default FormContainer;
