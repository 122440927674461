import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import AppStore from 'src/stores/AppStore';
import NotFound from 'src/app/NotFound';
import { isUiDisabled } from 'src/features/PortalData';
import { wrapComponentName } from 'src/utils/componentUtility';

// Flags to disable flows have the following structure:
// - A string that matches a flow name in the `FlowNames` enum or the URL segment
// - "Flow" suffix
// e.g. `${FlowNames.<flowNameEnum>}Flow`
/**
 *
 * @param {Object} configData The top-level Config data object
 * @param {FlowNames} flowName A string that corresponds to a `FlowNames` enum value
 */
const isFlowDisabled = (configData, flowName) => isUiDisabled(configData, `${flowName}Flow`);

/**
 * @param {FlowNames} flowName A string that corresponds to a FlowNames enum value
 */
const withFlowGuard = flowName => FlowComponent => {
    const FlowGuard = props => {
        const { location } = props;
        const { configData } = useContext(AppStore);

        // Don't render anything until we have a config object
        if (!configData) {
            return null;
        }

        // If the flow is disabled, render 404
        if (isFlowDisabled(configData, flowName)) {
            return <NotFound location={location.pathname} />;
        }

        return <FlowComponent {...props} />;
    };

    FlowGuard.displayName = wrapComponentName(FlowComponent, FlowGuard);
    FlowGuard.propTypes = {
        location: PropTypes.shape({
            pathname: PropTypes.string.isRequired,
        }).isRequired,
    };

    return FlowGuard;
};

export default withFlowGuard;
export { isFlowDisabled };
