import React, { useRef, useContext } from 'react';

import HelpModal from 'src/components/ModalForms/HelpModal/HelpModal';
import { FlowNames } from 'src/app/Constants';
import { QaIds } from 'src/app/QaConstants';
import AppStore from 'src/stores/AppStore';
import { getAEMValue } from 'src/app/AEMContentUtility';
import AnalyticUtility, { handleAnalyticsTriggerError } from 'src/app/AnalyticUtility';
import Title from 'src/UI/Title/Title';
import Text from 'src/UI/Text/Text';
import { Button } from 'src/UI/Button';
import { LayoutContainer } from 'src/components/Containers';
import { parseHsidPath } from 'src/utils/urlUtility';
import { useToggleState, useEffectOnMounted } from 'src/hooks';
import { isUiDisabled } from 'src/features/PortalData';
import format from 'src/utils/format';
import { Link } from 'src/UI/Link';

import classes from './Footer.module.scss';

const Footer = ({ location }) => {
    const { AEMData, configData } = useContext(AppStore);
    const { flowName } = parseHsidPath(location.pathname);
    const {
        FORGOT_USERNAME,
        REGISTER,
        LOGIN,
        FORGOT_PASSWORD,
        VERIFY_LOGIN,
        VERIFY_ACCOUNT,
        NO_ACCESS,
        FULL_STEP_UP,
        SETTINGS,
        ENSURE,
        EVALUATE,
    } = FlowNames;

    const [isHelpModalVisible, { on: showHelpModal, off: hideHelpModal }] = useToggleState(false);

    const modalRef = useRef();
    const a11yInfoRef = useRef();

    const getMaybeContent = contentKey => {
        const result = getAEMValue(AEMData, contentKey);
        return result !== `?${contentKey}` && result !== 'undefined' ? result : null;
    };

    const getHelpActionText = () => {
        switch (flowName) {
            case EVALUATE:
            case ENSURE:
            case LOGIN:
            case FORGOT_USERNAME:
            case FORGOT_PASSWORD:
            case VERIFY_LOGIN:
            case VERIFY_ACCOUNT:
                return getMaybeContent('LblSignInHelp');
            case REGISTER:
            case FULL_STEP_UP:
            case NO_ACCESS:
                return getMaybeContent('LblRegHelp');
            case SETTINGS:
                return getMaybeContent('LblSettingsHelp');
            default:
                return null;
        }
    };

    const isHelpModalDisabled = isUiDisabled(configData, 'helpModal');
    const a11yActionText = getMaybeContent('LblA11yInfo');
    const externalLinkA11yText = getAEMValue(AEMData, 'ExtLinkAllYText');
    const helpActionText = getHelpActionText();
    const extraFooterLinks = JSON.parse(getMaybeContent('ExtraFooterLinks') ?? '[]');

    const trackA11yInfoLink = e => {
        const linkClickedData = {
            processName: 'trackExitLink',
            linkFriendlyName: 'ACCESSIBILITY_INFORMATION',
            uhcLinkFriendlyName: 'ACCESSIBILITY_UHC_INFORMATION',
            linkTarget: e.target.href,
        };
        AnalyticUtility.onLinkClickedData(linkClickedData).catch(handleAnalyticsTriggerError(linkClickedData));
    };
    useEffectOnMounted(() => {
        const anchorTag = a11yInfoRef.current ? a11yInfoRef.current.querySelector('a') : undefined;
        if (anchorTag) {
            anchorTag.addEventListener('click', trackA11yInfoLink, false);
        }

        return () => {
            if (anchorTag) {
                anchorTag.removeEventListener('click', trackA11yInfoLink, false);
            }
        };
    });

    const renderExtraFooterLinks = () =>
        extraFooterLinks.map((link, index) => {
            const qaId = format(QaIds.FOOTER_EXTRA_LINK_TEMPLATE, { NUMBER: index + 1 });
            return (
                <Text id={qaId} size="b2" tag="li" key={qaId}>
                    <Link
                        variant="inline"
                        href={link.url}
                        target="_blank"
                        isExternal={link.isExternal}
                        analyticsData={{
                            processName: 'trackExitLink',
                            linkFriendlyName: 'FOOTER_EXTRA_LINK_TEMPLATE',
                            uhcLinkFriendlyName: 'UHC_FOOTER_EXTRA_LINK_TEMPLATE',
                            linkTarget: link.url,
                            additionalPlaceholderData: {
                                EXTRA_LINK_TEXT: link.label.toLowerCase(),
                            },
                            referringSection: `footer nav: ${link.label.toLowerCase()} `,
                        }}
                    >
                        {link.label} {link.isExternal ? <span className="sr-only">{externalLinkA11yText}</span> : null}
                    </Link>
                </Text>
            );
        });

    return (
        <footer id={QaIds.FOOTER_CONTAINER} className={classes.root} role="contentinfo">
            <LayoutContainer className={classes.container}>
                {/* Need Help text */}
                <Title headingLevel={2} size="h6" className="has-rds-mb-16">
                    {getAEMValue(AEMData, 'LblNeedHelp')}
                </Title>
                {/* Footer Content */}
                <Text id={QaIds.FOOTER_MESSAGE} size="b3" html>
                    {getAEMValue(AEMData, 'TxtMsgForFooter')}
                </Text>

                <ul className={classes.helpLinks}>
                    {!isHelpModalDisabled && helpActionText && (
                        <li>
                            <Button
                                id={QaIds.FOOTER_HELP_ACTION}
                                variant="link-standalone"
                                clicked={() => {
                                    showHelpModal();
                                }}
                            >
                                {helpActionText}
                            </Button>
                        </li>
                    )}

                    {a11yActionText && (
                        <Text id={QaIds.FOOTER_A11Y_ACTION} size="b2" tag="li" ref={a11yInfoRef} html>
                            {a11yActionText}
                        </Text>
                    )}

                    {renderExtraFooterLinks()}
                </ul>
            </LayoutContainer>

            {!isHelpModalDisabled && isHelpModalVisible && (
                <HelpModal flow={flowName} modalRef={modalRef} onModalClose={() => hideHelpModal()} />
            )}
        </footer>
    );
};

export default Footer;
