export { default as OBAService } from './OBAService/OBAService';

export {
    getErrorCode,
    getErrorDescription,
    getAuthenticatorMethodEnsue,
    hasQuestionChallenges,
    getQuestionChallenges,
    isQuestionChallenge,
    handleAttestationServiceError,
} from './OBAService/utils';

export { isPhoneAuthenticator, isQuestionAuthenticator } from './OBAService/types';

export { default as ShimService } from './ShimService/ShimService';

export { getShimErrorCode, getShimErrorDescription } from './ShimService/utils';

export type {
    AttestationAnswer,
    AttestationResponse,
    AuthenticateResponse,
    Authenticator,
    AuthenticatorResponse,
    Challenge,
    ChallengeResponse,
    OBAError,
    OBASuccessMeta,
    PhoneAttestationRequest,
    PhoneAuthenticator,
    PhoneChallenge,
    PhoneMethod,
    QuestionAuthenticator,
    QuestionChallenge,
    SecurityQuestion,
    QuestionAttestationRequest,
    ProfilingMetadataResponse,
} from './OBAService/types';

export type { ArrangeResponse, CompleteResponse, ShimMeta } from './ShimService/types';
