/* eslint-disable no-bitwise */
/**
 * Implementation of uuid4 generation with fallback support for older browser versions
 *
 * @see https://stackoverflow.com/a/2117523/2800218
 */
const uuid4 =
    typeof globalThis.crypto !== 'undefined' && typeof globalThis.crypto.randomUUID === 'function'
        ? globalThis.crypto.randomUUID.bind(crypto)
        : (): string =>
              '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
                  (Number(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))).toString(16)
              );

/**
 * Create a Trace ID value to send with logs and network calls
 *
 * @param prefix An optional prefix for the trace ID
 * @returns The trace ID string
 */
export const createTraceId = (prefix = ''): string => [prefix, uuid4()].filter(Boolean).join('-');
