import React from 'react';
import type { FC } from 'react';

import { LoadingDots } from 'src/UI/LoadingDots/LoadingDots';

import classes from './LoadingPage.module.scss';

type LoadingPageContentProps = {
    a11yText?: string;
};

export type LoadingPageProps = LoadingPageContentProps;

const LoadingPage: FC<LoadingPageProps> = ({ a11yText = 'Page loading...' }) => (
    <div className={classes.root}>
        <LoadingDots a11yText={a11yText} center />
    </div>
);

export default LoadingPage;
