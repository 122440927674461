import HSIDService from './HSIDService';

const LogoutService = {
    logoutUser() {
        return HSIDService.get('/content/en/healthsafeid/public/logout.html').catch(error => {
            throw error.response;
        });
    },

    getPingLogoutUrl(targetUrl) {
        const searchParams = new URLSearchParams({ HTTP_TARGETURL: targetUrl });
        return HSIDService.get(`/protected/hsid/ping/logout?${searchParams}`);
    },
};

export default LogoutService;
