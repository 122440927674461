/**
 * Utility function that enables a simplified one-liner with return that sets the key on an object
 *
 * This mutates the object passed in and then returns it. You should only use this inside of
 * a loop that is building up a new object programmatically. Object spreading could be used, but that
 * could add significant time and memory as each new object requires copying over the previous keys.
 * Otherwise, prefer immutable patterns.
 *
 * @param obj The object to set the prop on
 * @param key The key to set a value for
 * @param value The value to set
 */
const setProp = <T extends Record<string, unknown>>(obj: T, key: string | symbol, value: unknown): T =>
    Object.assign(obj, { [key]: value });

export default setProp;
