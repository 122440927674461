import { BASE_ROUTE, FlowNames } from 'src/app/Constants';

import setProp from 'src/utils/setProp';

import type { Location } from 'history';
import type { FlowName } from 'src/app/Constants';

const secureFlowNames: Partial<Record<FlowName, boolean>> = {
    [FlowNames.VERIFY_LOGIN]: true,
    [FlowNames.FULL_STEP_UP]: true,
    [FlowNames.SETTINGS]: true,
    [FlowNames.AUTH]: true,
};

type HsidPathProps = { flowName: FlowName; portalBrand: string; lang?: string; step?: string; includeBase?: boolean };
export const buildHsidPath = ({
    flowName,
    portalBrand,
    lang = 'en',
    step,
    includeBase = false,
}: HsidPathProps): string => {
    const secure = !!secureFlowNames[flowName];
    const path = [
        // TODO Remove need to strip leading `/` from `BASE_ROUTE`
        includeBase ? BASE_ROUTE.slice(1) : null,
        secure ? FlowNames.SECURE : null,
        flowName,
        portalBrand,
        lang,
        step,
    ]
        .filter(Boolean)
        .join('/');

    return `/${path}`;
};

/**
 * Break apart a valid HSID path into and object keyed with the parts
 *
 * @param path The HSID URL path including optional step segment
 * @returns An object with the HSID path parts
 */
export const parseHsidPath = (
    path = '' // TODO: make path required, update return type and tests
): { secure: boolean; flowName?: string; portalBrand?: string; lang?: string; step?: string } => {
    if (path === '') {
        return {
            secure: false,
            flowName: undefined,
            portalBrand: undefined,
            lang: undefined,
            step: undefined,
        };
    }

    const baseSegmentOffset = path.startsWith(BASE_ROUTE) ? 1 : 0;
    const parts = path.substring(1).split('/');
    // NOTE check for "secure" segment among path parts instead of as substring of raw path in case portal brand
    // contains "secure" substring
    const isSecureUrl = parts.includes(FlowNames.SECURE);
    const secureOffset = isSecureUrl ? 1 : 0;
    const totalOffset = baseSegmentOffset + secureOffset;

    return {
        secure: isSecureUrl,
        flowName: parts[0 + totalOffset],
        portalBrand: parts[1 + totalOffset],
        lang: parts[2 + totalOffset],
        step: parts[3 + totalOffset],
    };
};

type ParamsReducer = (result: Record<string, string>, [key, value]: [string, string]) => Record<string, string>;
export const parseQueryParams = (searchString = '', paramList: string[] = []): Record<string, string> => {
    if (searchString === '' || searchString === '?') {
        return {};
    }

    const reducer: ParamsReducer =
        paramList.length > 0
            ? (result, [key, value]) => (paramList.includes(key) ? Object.assign(result, { [key]: value }) : result)
            : (result, [key, value]) => Object.assign(result, { [key]: value });

    return Array.from(new URLSearchParams(searchString).entries()).reduce(reducer, {});
};

type CopiedLocationKeys = Partial<Pick<Location, 'pathname' | 'search' | 'hash'>>;
const copyLocationKeys = (location: Location): CopiedLocationKeys =>
    (['pathname', 'search', 'hash'] as (keyof CopiedLocationKeys)[]).reduce<CopiedLocationKeys>(
        (l, key) => (location[key] ? Object.assign(l, { [key]: location[key] }) : l),
        {}
    );

export const extractQueryParamsFromRouterLocation = (
    location: Location,
    paramList: string[] = []
): [Partial<Location>, Record<string, string>] => {
    if (paramList.length === 0) {
        return [copyLocationKeys(location), {}];
    }

    const params = new URLSearchParams(location.search);

    const consumedParams = paramList.reduce<Record<string, string>>((result, param) => {
        const value = params.get(param);

        if (value !== null) {
            params.delete(param);
            return setProp(result, param, value);
        }

        return result;
    }, {});

    return [
        {
            ...copyLocationKeys(location),
            search: `?${params.toString()}`,
        },
        consumedParams,
    ];
};

/**
 * Attempt to parse a string as a URL
 *
 * Because parsing a URL string can throw an error, this function captures the success or failure outcome as a returned
 * tuple:
 * - The first slot will be the parsed URL object if successfully parsed or `null` if not.
 * - The second slot will be the parsing Error object if parsing fails, or `null` if it succeeded.
 *
 * @param url A string that should be a full URL
 * @returns A tuple of the parsed URL and an Error: If `url` is a valid URL string, [URL, null]; otherwise [null, Error]
 */
export const parseUrl = (url: string): [URL | null, Error | null] => {
    try {
        const urlObj = new URL(url);
        return [urlObj, null];
    } catch (error) {
        return [null, error as Error];
    }
};

export const stripBaseSegment = (pathname: string): string =>
    pathname.startsWith(BASE_ROUTE) ? pathname.replace(BASE_ROUTE, '') : pathname;

export const stringifyQueryParams = (queryObject: Record<string, string> = {}): string =>
    Object.keys(queryObject)
        .map(key => `${key}=${encodeURIComponent(queryObject[key])}`)
        .join('&');

/**
 * Will form new url and take care of encoding the query parameters with white space, &, @, % etc.
 * @param {*} domain
 * @param {*} path ex: /secure/authenticate or /protected/accountreset/password
 * @param {*} queryParams object with query params you want to append at the end expect a object
 *                       ex: {queryParamName1: "value1", queryParamName2: "value2"}
 */
export const formURL = (domain: string, path: string, queryParams: Record<string, string> = {}): URL => {
    const newURL = new URL(path, domain);

    // will add query params to url
    // NOTE DE479502 Some calling functions will provide `null` to `queryParams` which doesn't trigger the default
    // value, so we use null coalescing for a more robust default value.
    Object.entries(queryParams).forEach(([name, value]) => {
        newURL.searchParams.append(name, value);
    });

    return newURL;
};
