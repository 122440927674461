import React from 'react';
import classNames from 'classnames';
import { RDS_ICON } from '../Icon';

const SystemPaginationArrowLeft = React.forwardRef<
    HTMLSpanElement, // The reference type - which element the final ref is attached to.
    React.HTMLAttributes<HTMLSpanElement>
>(({ className, ...spanProps }, forwardedRef) => {
    // System Icons are size 16 only
    const iconClasses = classNames(RDS_ICON, `${RDS_ICON}-16`, className);

    // Spans are used here for sizing the SVG and to keep in line with the current styles
    // TODO: explore decoupling the svg itself from the outer contents: https://jira.rallyhealth.com/browse/UCRON-1455
    return (
        <span ref={forwardedRef} {...spanProps} className={iconClasses}>
            <span className="h-rds-nav-button-back-caret">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    height="16"
                    viewBox="0 0 16 16"
                    width="16"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.003 8l1.27-1.268L10.7.3a1 1 0 011.319-.083l.094.083c.39.39.391 1.024.001 1.415l-6.271 6.29 6.271 6.293a1.002 1.002 0 01-1.32 1.498l-.094-.083L3.003 8z"
                        fillRule="evenodd"
                    />
                </svg>
            </span>
        </span>
    );
});

export default SystemPaginationArrowLeft;
