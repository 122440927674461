import { useState, useRef } from 'react';

interface ToggleApi {
    on: () => void;
    off: () => void;
    toggle: () => void;
}

const useToggleState = (initialState = false): [boolean, ToggleApi] => {
    const [state, setState] = useState(initialState);
    const api = useRef({
        on: () => setState(true),
        off: () => setState(false),
        toggle: () => setState(current => !current),
    });

    return [state, api.current];
};

export default useToggleState;
