import axios from 'axios';
import { HSID_BASE_URL } from 'src/app/Constants';

import { logger } from 'src/app/Logger';
import { LocalStorage } from 'src/app/Storage';
import { trimSpaceMiddleware } from 'src/features/Clients/utils';

const instance = axios.create({
    baseURL: HSID_BASE_URL,
    timeout: 60000,
});

/**
 * CORS issue:
 *     Comment out the below `instance.defaults.headers.common` code to resolve CORS issue
 * In real environment, uncomment `instance.defaults.headers.common`
 * and add specific headers as needed to apply CORS
 */
/*
instance.defaults.headers.common = {
    // 'Accept':'application/json',
    'Access-Control-Allow-Origin':"*",
    'Access-Control-Allow-Headers': "*",
    'Access-Control-Allow-Credentials':"true",
    'Access-Control-Allow-Methods':['GET', 'POST', 'PUT','DELETE']
  };
*/

instance.defaults.withCredentials = true;
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';

instance.interceptors.request.use(trimSpaceMiddleware, error => Promise.reject(error));
instance.interceptors.request.use(
    req => {
        logger.debug('Request Interceptors Reached', { request: req });
        return Promise.resolve(req);
    },
    error => {
        logger.debug('Request Interceptors Failed to Reached', { error });
        return Promise.reject(error);
    }
);
instance.interceptors.response.use(
    res => {
        logger.debug('Response Interceptors Reached', { response: res });
        // parseUrl utility method fails here since, res.config.url returns a relative path. Hence it is not used
        if (res.config.url.includes('/secure/') || res.config.url.includes('/protected/')) {
            LocalStorage.put('lastServerCallTs', Date.now());
        }
        return Promise.resolve(res);
    },
    error => {
        logger.debug('Response Interceptors Failed to Reached', { error });
        return Promise.reject(error);
    }
);
export default instance;
