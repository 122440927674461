import React from 'react';

import type { FC, ReactNode } from 'react';

export interface SvgIconProps {
    children: ReactNode;
    className?: string;
    size?: 'sm' | 'md';
}

const sizeMap = {
    sm: 16,
    md: 24,
} as const;

const getSizeProps = (sizeProp: NonNullable<SvgIconProps['size']>) => {
    const size = String(sizeMap[sizeProp]);
    return {
        width: size,
        height: size,
        viewBox: `0 0 ${size} ${size}`,
    } as const;
};

const SvgIcon: FC<SvgIconProps> = ({ children, className, size = 'md' }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        {...getSizeProps(size)}
        focusable="false"
        role="presentation"
        className={className}
    >
        {children}
    </svg>
);

export default SvgIcon;
