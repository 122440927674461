import type { UnaryVoidFunction } from 'src/app/types';

/**
 * Compose unary void functions into a single void unary function
 *
 * @param fns One or more unary void functions that take the same value type
 * @returns A unary void function
 *
 * @note If only 1 function is passed, that function will be returned instead of a new wrapping function
 */
export const sequence = <T>(...fns: UnaryVoidFunction<T>[]): UnaryVoidFunction<T> => {
    if (fns.length === 1) {
        return fns[0];
    }

    return (x: T) => {
        fns.forEach(fn => fn(x));
    };
};
