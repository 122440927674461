import React, { useState, useEffect } from 'react';
import he from 'he';

import type { FC } from 'react';

import setProp from 'src/utils/setProp';
import { logger } from 'src/app/Logger';

import ContentContext, { defaultContent, isDefaultContent } from './ContentContext';

const reJsonBoundaries = /^(?:\{(?!\{).+\}|\[.+\])$/;
/**
 * Test for if a value is unparsed JSON
 *
 * Tests for `{...}` and `[...]` as the heuristic for JSON and checks for leading `{{` in case a content value is a
 * content variable `{{...}}` to not match positively for a JSON value
 *
 * @private Exported for testing purposes
 */
export const isJsonContent = (contentValue: string) => reJsonBoundaries.test(contentValue);

// Function to pre-process the content to decode HTML entities, parse JSON, and anything else before providing it to the
// app so that it only needs to be done once per key.
const doNotCopyKeys = new Set(['Config', 'UIConfig']);
const processRawContent = (rawContent: Record<string, string>) =>
    Object.entries(rawContent).reduce((newContent, [key, value]) => {
        if (doNotCopyKeys.has(key)) {
            return newContent;
        }

        if (isJsonContent(value)) {
            try {
                return setProp(newContent, key, JSON.parse(he.decode(value)));
            } catch (error) {
                if (error instanceof SyntaxError && error.message.includes('JSON')) {
                    // Specialized handling for malformed JSON in the content key
                    logger.error(`Unable to parse JSON in ${key} content key due to invalid JSON`, {
                        error,
                        misc: `Raw content value: ${value}`,
                    });
                } else {
                    // Generic issue with JSON key
                    logger.error(`Failed to process content key ${key}`, { error: error as Error });
                }

                // NOTE maintain desired shape of JSON value but set an empty one
                return setProp(newContent, key, value.startsWith('[') ? [] : {});
            }
        }

        return setProp(newContent, key, he.decode(value));
    }, {});

interface ContentProviderProps {
    content: Record<string, string>;
}

const ContentProvider: FC<ContentProviderProps> = ({ children, content: contentProp = defaultContent }) => {
    // NOTE The content may not be ready when this component mounts the first time so check if it's the default content
    // when the state hook initializes and again on subsequent re-renders for when it is updated to actually pre-process
    // the raw content
    const [content, setContent] = useState<Record<string, string>>(
        isDefaultContent(contentProp) ? contentProp : processRawContent(contentProp)
    );
    useEffect(() => {
        // If the current state is the default content and the prop is not the default content, update the state
        if (isDefaultContent(content) && !isDefaultContent(contentProp)) {
            setContent(processRawContent(contentProp));
        }
        // NOTE We only want to re-run this effect when the `contentProp` changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentProp]);

    return <ContentContext.Provider value={content}>{children}</ContentContext.Provider>;
};

export default ContentProvider;
