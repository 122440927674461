import { LanguageCode, isSupportedLanguage, SupportedLocaleCode } from 'src/utils/lang';
import { LanguageCodeMap } from './Constants';

/**
 * returns a standardized locale parameter based on language code
 * @param lang language code from the url
 */
const getLocale = (lang?: string): SupportedLocaleCode =>
    isSupportedLanguage(lang) ? LanguageCodeMap[lang][1] : LanguageCodeMap[LanguageCode.EN][1];

export default getLocale;
