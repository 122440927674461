import { useRef } from 'react';

const useScrollManagement = () => {
    const scrollLockState = useRef({
        scrollPosition: null,
    });

    const lockScroll = () => {
        scrollLockState.current = {
            scrollPosition: window.scrollY,
        };
        window.scrollY = 0;
        document.body.style.setProperty('overflow', 'hidden');
    };

    const unlockScroll = () => {
        document.body.style.removeProperty('overflow');
        window.scrollY = scrollLockState.current.scrollPosition;
        scrollLockState.current = {
            scrollPosition: null,
        };
    };

    return { lockScroll, unlockScroll };
};

export default useScrollManagement;
