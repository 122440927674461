import SignInService from 'src/flows/SignIn/SignInService';

/**
 * US3836137, TA11559831
 * There's an issue with the remember me functionality on mobile. In UHC mobile, the iOS web view is opened
 * in incognito mode which doesn't persist the username in local storage (via CrossStorage) so the next time the
 * user opens the app the username is not filled in. To fix this issue we're exposing initiateStoreUserData for
 * mobile apps to use. The mobile app will check to see if the user has selected the remember me checkbox and
 * then save the username value in the app internally. When the user returns to the app and the app needs to
 * authenticate the user, the app will open HSID in a webview and also inject some JavaScript that will call
 * initiateStoreUserData with the username that the app stored internally. The goal is that while HSID is loading
 * the call to initiateStoreUserData will save the username in local storage and when the sign in page loads it will
 * retrieve the username from local storage and fill it in the username field.
 * */
if (typeof window !== 'undefined') {
    window.initiateStoreUserData = username => {
        SignInService.storeUserData(username);
    };
}
