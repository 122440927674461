import { Predicate } from 'src/app/types';

/**
 * @param dateValue string
 * Checks for the date in the format of 'yyyy-mm-dd'
 * also matches a date in yyyy-mm-dd format
 * from 1900-01-01 through prior to current day
 */

/**
 * Compare the given input with the date format, allowing us to
 * take built in functions to get the cuFrrent date to dynamically compare withs
 */

const reValidDate = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;

export const isValidDateOfBirth: Predicate<string> = dateYMD => {
    if (!reValidDate.test(dateYMD)) {
        return false;
    }

    const dayInMillSec = 86400000; // Milliseconds for one day
    const targetDate = new Date(dateYMD);
    const yesterday = new Date(Date.now() - dayInMillSec);
    const earliestDate = new Date(1900, 0, 1);

    const isAfterEarliestDate = targetDate > earliestDate;
    const isBeforeToday = targetDate < yesterday;

    return isAfterEarliestDate && isBeforeToday;
};
