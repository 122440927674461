import React from 'react';
import { CheckMark } from 'src/UI/Icon';
import Text from 'src/UI/Text/Text';

import type { FC } from 'react';

import classes from './Banner.module.scss';

export interface BannerProps {
    msg?: string;
    id?: string;
}

const Banner: FC<BannerProps> = ({ msg, id }) => (
    <div aria-live="polite" role="alert" className={classes.root}>
        {msg && (
            <div id={id} className={classes.container}>
                <CheckMark className={classes.checkMark} />
                <Text tag="span" size="b3" className={classes.text} html>
                    {msg}
                </Text>
            </div>
        )}
    </div>
);

export default Banner;
