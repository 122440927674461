import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';

import SvgIcon from './SvgIcon';

export interface CheckMarkProps extends EmptyComponent {
    className?: string;
}

const CheckMark: FC<CheckMarkProps> = ({ className }) => (
    <SvgIcon size="sm" className={className}>
        <polyline
            points="2,9 6,13 15,3"
            stroke="#19871A"
            strokeWidth="3"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
        />
    </SvgIcon>
);

export default CheckMark;
