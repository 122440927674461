import { useState } from 'react';
import { useConstant } from 'src/hooks';

import type { ModalIdentifier } from './constants';

export type ModalStateApi = {
    showModal: (modalId: ModalIdentifier) => void;
    closeModal: () => void;
};

export const useModalState = (): [ModalIdentifier | null, ModalStateApi] => {
    const [visibleModals, setVisibleModals] = useState<ModalIdentifier[]>([]);
    const topMostVisibleModal = visibleModals[visibleModals.length - 1];
    const modalApi = useConstant<ModalStateApi>({
        showModal: modalId => {
            setVisibleModals(current => current.concat(modalId));
        },
        closeModal: () => {
            setVisibleModals(current => current.slice(0, -1));
        },
    });

    return [topMostVisibleModal ?? null, modalApi];
};
