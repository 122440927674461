import type { PortalConfigData } from 'src/features/PortalData';
import { FlowNames } from 'src/app/Constants';
import { getRegisterUrl } from 'src/features/PortalData';
import type { HrefLinkProps, HsidHrefLinkProps } from 'src/UI/Link/Link';
import type { SupportedLanguageCode } from 'src/utils/lang';

type RegisterLinkProps = Pick<HrefLinkProps, 'href' | 'isExternal'> | Pick<HsidHrefLinkProps, 'hsidHref'>;

export const getRegisterLinkHrefProps = (
    configData: PortalConfigData,
    portalBrand: string,
    lang: SupportedLanguageCode
): RegisterLinkProps => {
    const registerUrl = getRegisterUrl(configData);
    // check if registerUrl is not defined,
    // use hsidHref to redirect to register flow
    if (!registerUrl) {
        return {
            hsidHref: { flowName: FlowNames.REGISTER, portalBrand, lang },
        };
    }

    // if there is a registerUrl in portal config data
    // use the registerUrl as the href if it exists
    return {
        href: registerUrl,
        isExternal: true,
    };
};
