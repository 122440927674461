import { useEffect } from 'react';
import AnalyticUtility, { handleAnalyticsTriggerError } from 'src/app/AnalyticUtility';

const useModalOnLoadAnalytics = (
    {
        processName,
        pageName,
        uhcPageName,
        referringBusinessUnit,
        isAuthenticated,
        siteSectionL1,
        uhcSiteSectionL1,
        uhcSiteSectionL2,
        uhcSiteSectionL3,
        lang,
        uhcFormName,
        referringSite,
    },
    dependencies = []
) => {
    useEffect(() => {
        const parentPage = {
            pageName: AnalyticUtility.pageName,
            uhcPageName: AnalyticUtility.uhcPagename,
            uhcCommondata: AnalyticUtility.uhcCommonDataForEachPage,
        };
        AnalyticUtility.setPageName(pageName);
        AnalyticUtility.setUHCPageName(uhcPageName);
        const onLoadAnalyticData = {
            processName,
            pageName,
            uhcPageName,
            referringBusinessUnit,
            isAuthenticated,
            siteSectionL1,
            uhcSiteSectionL1,
            uhcSiteSectionL2,
            uhcSiteSectionL3,
            lang,
            uhcFormName,
            referringSite,
        };
        AnalyticUtility.onLoadData(onLoadAnalyticData).catch(handleAnalyticsTriggerError(onLoadAnalyticData));
        return () => {
            // when we close the modal we will go back to parent page name for submit
            AnalyticUtility.setPageName(parentPage.pageName);
            AnalyticUtility.setUHCPageName(parentPage.uhcPageName);
            AnalyticUtility.setUHCCommonDataForEachPage(parentPage.uhcCommondata);
            AnalyticUtility.loadOptumPublishPageData(processName, { pageName: parentPage.pageName });
            AnalyticUtility.loadUhcPublishPageData(processName, { pageName: parentPage.uhcPageName });
        };
        // Note: disabling this rule to prevent analytics from firing more than intended
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

export default useModalOnLoadAnalytics;
