import React from 'react';
import classNames from 'classnames';

import Text from 'src/UI/Text/Text';
import { withContent } from 'src/components/ContentProvider';
import compose from 'src/utils/compose';

import type { WithContentProps } from 'src/components/ContentProvider';

import { AlertIcon } from '../Icon';

import classes from './FieldError.module.scss';

const mapContentToProps = {
    a11yText: 'TxtErrorPrefix',
};

type FieldErrorContentProps = WithContentProps<typeof mapContentToProps>;

type FieldErrorPublicProps = {
    fieldId: string;
    children?: string | null;
    id?: string;
};

export type FieldErrorProps = FieldErrorPublicProps & FieldErrorContentProps;

const FieldError: React.FC<FieldErrorProps> = ({ children, id, a11yText }) => (
    <div
        id={id}
        className={classNames(classes.root, 'is-flex', {
            'has-rds-mt-8': !!children,
        })}
        aria-live="polite"
        aria-atomic="true"
    >
        {children ? (
            <>
                <AlertIcon className={classes.icon} />

                <Text size="small" tag="span" className="has-rds-ml-8">
                    <span className="sr-only">{a11yText}</span>
                    {children}
                </Text>
            </>
        ) : null}
    </div>
);

export { FieldError };
export default compose(withContent(mapContentToProps))(FieldError) as React.FC<
    Omit<FieldErrorProps, keyof FieldErrorContentProps>
>;
