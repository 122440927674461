import React from 'react';

import type { FC } from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

export type HelpIconProps = Omit<SvgIconProps, 'children' | 'size'>;

const HelpIcon: FC<HelpIconProps> = ({ className }) => (
    <SvgIcon size="sm" className={className}>
        <circle cx="8" cy="8" r="8" fill="#196ECF" />
        <g fill="#fff">
            <path d="M4.335,6.3398 C4.446,3.9708 5.808,2.6578 8.112,2.6578 C10.241,2.6578 11.665,3.8918 11.665,5.7318 C11.665,6.9158 11.249,7.5248 9.616,8.6608 C9.105,9.0448 8.913,9.5258 8.913,10.3418 L6.928,10.3418 L6.928,10.2298 C6.943,8.5808 7.12,8.2448 8.448,7.1888 C9.152,6.6118 9.36,6.2918 9.36,5.6998 C9.36,4.9798 8.849,4.4988 8.08,4.4988 C7.056,4.4988 6.56,5.0748 6.511,6.3398 L4.335,6.3398 Z" />
            <rect x="7" y="11" width="2" height="2" />
        </g>
    </SvgIcon>
);

export default HelpIcon;
