import he from 'he';

// NOTE Temporarily reaching inside of a module to private API until AEMContentUtility is fully removed and the
// `withContent()` HOC handles our content loading. Don't repeat this elsewhere.
import { replacePlaceholders } from 'src/components/ContentProvider';
import type { PortalContentKey, RawPortalContentData } from 'src/features/PortalData/types';

const getAEMValue = (AEMData: RawPortalContentData | null, key: PortalContentKey): string => {
    // Short out if AEMData isn't passed which can happen in <App /> if content fails to load OR (for non-TS files) when
    // `key` cannot be enforced to be a valid content key.
    // TODO Refactor code to remove need for support of `null` arg
    // TODO Convert all consuming files to TS or migrate everything to use `withContent()` HOC
    if (AEMData === null || typeof AEMData[key] === 'undefined') {
        return `?${key as string}`;
    }

    // the reason to use he (HTML entity) library is, an issue found while decoding Terms of Use (modal) content
    // the current approach is not fully decoding it and skipping some of the end part of the content, verified from w3c
    // also same is happening there with this approach
    const decodedHtml = he.decode(replacePlaceholders(AEMData[key], AEMData));

    return decodedHtml || '';
};

export { getAEMValue };
