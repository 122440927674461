import React from 'react';
import PropTypes from 'prop-types';

import HsidPropTypes from 'src/prop-types';
import { QaClassNames } from 'src/app/QaConstants';
import SubHeading from 'src/UI/SubHeading/SubHeading';

const propTypes = {
    subHeadingRef: HsidPropTypes.reactRef,
    removeBottomSpacing: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    removeBottomSpacing: undefined,
    subHeadingRef: undefined,
};

const ModalSubHeading = ({ subHeadingRef, removeBottomSpacing, children }) => (
    <SubHeading
        className={QaClassNames.MODAL_SUBHEADING}
        subHeadingRef={subHeadingRef}
        removeBottomSpacing={removeBottomSpacing}
    >
        {children}
    </SubHeading>
);

ModalSubHeading.propTypes = propTypes;
ModalSubHeading.defaultProps = defaultProps;

export default ModalSubHeading;
