import { QaIds } from 'src/app/QaConstants';
import { logger } from 'src/app/Logger';
import useEffectOnMounted from './useEffectOnMounted';

type ScreenLoadFocusTargets = 'body' | 'pageTitle';

const useInitialFocus = (target: ScreenLoadFocusTargets = 'body'): void => {
    useEffectOnMounted(() => {
        let el: HTMLElement | null = null;
        if (target === 'pageTitle') {
            el = document.getElementById(QaIds.PAGE_HEADING);
            if (process.env.NODE_ENV !== 'test') {
                if (!el) {
                    logger.warn('useInitialFocus(): Unable to find page title element in current page.');
                }
            }
        }
        if (!el) {
            el = document.body;
        }
        el.focus();
    });
};

export default useInitialFocus;
