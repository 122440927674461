/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import classNames from 'classnames';
import RdsIcon from '../Icon/Icon';
import { ButtonProps, ButtonSize, ButtonVariants, ButtonWidth } from './Button.interface';

const getButtonClasses = (
    variant: ButtonVariants,
    colorBranded: boolean,
    size?: ButtonSize,
    width?: ButtonWidth,
    className?: string
): string =>
    classNames(
        `rds-${variant}-button`,
        {
            'is-rds-small': size === 'small',
            'is-rds-fullwidth': width === 'full',
            'is-white-labeled-btn': colorBranded && variant === 'primary',
            'is-white-labeled-btn-secondary': colorBranded && variant === 'secondary',
        },
        className
    );

const RdsButton = React.forwardRef<
    HTMLButtonElement, // The reference type - which element the final ref is attached to.
    ButtonProps
>(({ children, className, icon, size, variant = 'primary', colorBranded = true, width, ...others }, forwardedRef) => (
    <button ref={forwardedRef} {...others} className={getButtonClasses(variant, colorBranded, size, width, className)}>
        {icon && !size ? <RdsIcon className="has-rds-btn-icon-left" name={`button-${icon}`} /> : null}
        {children}
    </button>
));

export default RdsButton;
