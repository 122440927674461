import React, { useRef } from 'react';
import type { FC } from 'react';

import HelpModal from 'src/components/ModalForms/HelpModal/HelpModal';
import { QaIds } from 'src/app/QaConstants';
import { LayoutContainer } from 'src/components/Containers';
import { useToggleState, useAppStore } from 'src/hooks';
import { getSupportedLocales, isUiDisabled } from 'src/features/PortalData';
import { Button } from 'src/UI/Button';
import { withContent } from 'src/components/ContentProvider';
import compose from 'src/utils/compose';
import { FlowNames } from 'src/app/Constants';
import { ErrorBoundary } from 'src/components/ErrorBoundary';

import type { FlowName } from 'src/app/Constants';
import type { WithContentProps } from 'src/components/ContentProvider';
import type { EmptyComponent } from 'src/app/types';

import classes from './UtilityBar.module.scss';
import LanguageSelector from './subcomponents/LanguageSelector';

const mapContentToProps = {
    helpText: 'LblHelp',
} as const;

type UtilityBarContentProps = WithContentProps<typeof mapContentToProps>;

type UtilityBarPublicProps = {
    flowName: FlowName;
};

export type UtilityBarProps = UtilityBarContentProps & UtilityBarPublicProps & EmptyComponent;

const UtilityBar: FC<UtilityBarProps> = ({ helpText, flowName }) => {
    const { configData } = useAppStore();

    const [isHelpModalVisible, { on: showHelpModal, off: hideHelpModal }] = useToggleState(false);

    const modalRef = useRef<typeof HelpModal | null>();

    const isHelpModalDisabled = isUiDisabled(configData, 'helpModal');

    const supportedLanguageCodes = getSupportedLocales(configData);

    const hideLanguageSelector = supportedLanguageCodes.length < 2 || flowName === FlowNames.ENSURE;

    if (hideLanguageSelector && isHelpModalDisabled) return null;

    return (
        <div className={classes.root} id={QaIds.HEADER_UTLITY_NAV}>
            <LayoutContainer className={classes.container}>
                <div className={classes.nav}>
                    {hideLanguageSelector ? null : (
                        <ErrorBoundary>
                            <LanguageSelector
                                supportedLanguageCodes={supportedLanguageCodes}
                                flowName={flowName}
                                buttonClassName={classes.navItem}
                            />
                        </ErrorBoundary>
                    )}
                    {isHelpModalDisabled ? null : (
                        <Button
                            id={QaIds.HEADER_HELP_ACTION}
                            className={classes.navItem}
                            clicked={() => {
                                showHelpModal();
                            }}
                            variant="link-heading"
                        >
                            {helpText}
                        </Button>
                    )}
                </div>
            </LayoutContainer>

            {!isHelpModalDisabled && isHelpModalVisible && (
                <HelpModal flow={flowName} modalRef={modalRef} onModalClose={() => hideHelpModal()} />
            )}
        </div>
    );
};

export { UtilityBar };
export default compose(withContent(mapContentToProps)(UtilityBar)) as FC<
    Omit<UtilityBarProps, keyof UtilityBarContentProps>
>;
