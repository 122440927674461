import type { InternalAxiosRequestConfig, Method } from 'axios';

import { logger } from 'src/app/Logger';

type JSONValue = string | number | boolean | JSONObject | JSONArray;

type JSONObject = {
    [key: string]: JSONValue;
};
type JSONArray = JSONValue[];

// Recursively walks the object / array and trims spaces with mutation
const trimSpacesFromJSON = (jsonArg: JSONValue): JSONValue => {
    const json = jsonArg;
    if (typeof json === 'string') {
        return json.trim();
    }
    if (json && Array.isArray(json)) {
        json.forEach((value, i) => {
            json[i] = trimSpacesFromJSON(value);
        });
    } else if (json && typeof json === 'object') {
        Object.entries(json).forEach(([key, value]) => {
            json[key] = trimSpacesFromJSON(value);
        });
    }
    return json;
};

const methodsWithData = new Set<Method | string>(['POST', 'PUT']);
export const trimSpaceMiddleware = (configArg: InternalAxiosRequestConfig) => {
    const config = configArg;
    if (config.method && methodsWithData.has(config.method.toUpperCase())) {
        logger.debug('Request Interceptors: trimSpaceMiddleware');
        config.data = trimSpacesFromJSON(config.data as JSONValue);
    }
    return config;
};
