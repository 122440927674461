import type { FormFieldValidationResults } from '../types';
import type { FieldErrorItem, FormErrorsSummaryData, ListOnlyFormErrorsSummary } from './FormErrorsSummary';

/**
 * Convert a modern form validation result structure to a legacy summary format
 *
 * This can be used for anything where `{ elementId: string, elementLabel: string }[]` is needed from a
 * `FormFieldValidationResults`
 *
 * @param results An array of form field validation results
 * @returns An array of ErrorFieldItems
 */
export const convertValidationResultsToFieldErrorItems = (results: FormFieldValidationResults): FieldErrorItem[] => {
    const seen = new Set();
    return results.reduce<ListOnlyFormErrorsSummary['fields']>((data, { meta: { field, label } }) => {
        if (!seen.has(field)) {
            data.push({
                elementId: field,
                elementLabel: label,
            });
            seen.add(field);
        }

        return data;
    }, []);
};

/**
 * Convert FormFieldValidationResults into the error data structure for <FormErrorsSummary />
 *
 * @todo Add validationResults prop to <FormErrorsSummary /> to handle that internally
 *
 * @param results Array of validation failures
 * @returns `error` data for <FormErrorsSummary />
 */
export const convertValidationResultsToFormErrorsSummaryData = (
    results: FormFieldValidationResults
): FormErrorsSummaryData => {
    // Handle single form-level error (like failed service calls)
    if (results.length === 1 && results[0].meta.field === 'form') {
        return {
            message: results[0].reason,
        };
    }

    // Handle field validation failures
    return { fields: convertValidationResultsToFieldErrorItems(results) };
};
