import React from 'react';
import classNames from 'classnames';

import Text from 'src/UI/Text/Text';
import HtmlContent from 'src/UI/HtmlContent/HtmlContent';

import type { FC } from 'react';

export interface FieldLabelProps {
    children: string;
    fieldId: string;
    className?: string;
    isHidden?: boolean;
    subLabel?: string;
}

const FieldLabel: FC<FieldLabelProps> = ({ children, fieldId, className, isHidden = false, subLabel }) => (
    <Text
        htmlFor={fieldId}
        className={classNames('has-rds-text-black-bis is-block', { 'sr-only': isHidden }, className)}
        tag="label"
        aria-live="polite"
    >
        <HtmlContent content={children} />
        {subLabel && (
            <Text className="has-rds-mb-4 is-block" tag="span" size="small">
                {subLabel}
            </Text>
        )}
    </Text>
);

export default FieldLabel;
