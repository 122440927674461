// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoadingBar_root__mpa04 {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 10px;\n  color: #1898da;\n}\n\n.theme-optum .LoadingBar_root__mpa04 {\n  color: #316bbe;\n}\n\n.LoadingBar_bar__53TXp {\n  width: 0;\n  height: 100%;\n  background-color: currentColor;\n  -webkit-user-select: none;\n          user-select: none;\n  animation-name: LoadingBar_loadingBar__P\\+RbW;\n  animation-duration: 2s;\n  animation-timing-function: linear;\n  animation-iteration-count: 1;\n  animation-fill-mode: forwards;\n}\n\n@keyframes LoadingBar_loadingBar__P\\+RbW {\n  0% {\n    width: 0;\n  }\n  100% {\n    width: 100%;\n  }\n}", "",{"version":3,"sources":["webpack://./src/UI/LoadingBar/LoadingBar.module.scss","webpack://./src/sass/optum/_variables.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;AADJ;;AAIA;EACI,cCVS;ADSb;;AAIA;EACI,QAAA;EACA,YAAA;EACA,8BAAA;EACA,yBAAA;UAAA,iBAAA;EAGA,6CAAA;EACA,sBAAA;EACA,iCAAA;EACA,4BAAA;EACA,6BAAA;AAHJ;;AAMA;EACI;IACI,QAAA;EAHN;EAME;IACI,WAAA;EAJN;AACF","sourcesContent":["@import '../../sass/optum/variables';\n\n.root {\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 10px;\n    color: #1898da;\n}\n\n:global(.theme-optum) .root {\n    color: $optum-blue;\n}\n\n.bar {\n    width: 0;\n    height: 100%;\n    background-color: currentColor;\n    user-select: none;\n    // NOTE can't use `animation` property shorthand because CSS Module does weird things with the animation name\n    // https://github.com/css-modules/css-modules/issues/141#issuecomment-293092109\n    animation-name: loadingBar;\n    animation-duration: 2s;\n    animation-timing-function: linear;\n    animation-iteration-count: 1;\n    animation-fill-mode: forwards;\n}\n\n@keyframes loadingBar {\n    0% {\n        width: 0;\n    }\n\n    100% {\n        width: 100%;\n    }\n}\n","$white: #fff;\n$optum-blue: #316bbe;\n$optum-blue-dark: #00396c;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "LoadingBar_root__mpa04",
	"bar": "LoadingBar_bar__53TXp",
	"loadingBar": "LoadingBar_loadingBar__P+RbW"
};
export default ___CSS_LOADER_EXPORT___;
