import { useState } from 'react';

import type { Dispatch, SetStateAction } from 'react';
import type { PlainObject, Values } from 'src/app/types';

import useConstant from './useConstant';

export type ObjectStateApi<T extends PlainObject = PlainObject> = {
    set: Dispatch<SetStateAction<T>>;
    reset: () => void;
    setField: (fieldName: keyof T, fieldValue: Values<T>) => void;
    update: (newPartialState: Partial<T>) => void;
};

/**
 * Custom hook that wraps @see useState and provides better state updater primitive functions for dealing with POJO
 * state.
 */
export const useObjectState = <T extends PlainObject = PlainObject>(
    initialState: T = {} as T
): [T, ObjectStateApi<T>] => {
    const [state, setState] = useState(initialState);
    const api = useConstant<ObjectStateApi<T>>({
        /**
         * Overwrite the whole state. This is the default state setter.
         */
        set: setState,
        /**
         * Reset the state to the first `initialState`
         */
        reset: () => {
            // NOTE This will be the first initialState because `useConstant()` for the API captures the first value in
            // this function definition closure.
            setState(initialState);
        },
        /**
         * Set a single field to a specific value
         */
        setField: (fieldName, fieldValue) => {
            setState(current => ({
                ...current,
                [fieldName]: fieldValue,
            }));
        },
        /**
         * Update only the fields provided in the argument
         */
        update: newPartialState => {
            setState(current => ({
                ...current,
                ...newPartialState,
            }));
        },
    });

    return [state, api];
};
