import React, { useRef } from 'react';
import classNames from 'classnames';
import deepEqual from 'fast-deep-equal';

import { DropdownArrowIcon } from 'src/UI/Icon';
import noop from 'src/utils/noop';
import { noopValidator, ValidationFailure } from 'src/utils/validation';
import { useComponentApiDef, useEffectOnMounted, useMilestones, useToggleState } from 'src/hooks';

import type { FC, ReactElement } from 'react';
import type { EmptyComponent } from 'src/app/types';
import type { ComponentApi } from 'src/hooks';

import FieldLabel from '../FieldLabel';
import {
    useFieldState,
    FieldState,
    getFieldValue,
    isFieldTouched,
    isFieldValid,
    getFieldErrors,
} from '../hooks/useFieldState';
import { defaultRenderError, getFieldErrorId, triggerChangeEvent } from '../utils';

import type { RenderErrorData } from '../utils';
import type { BaseFormFieldProps } from '../types';

import classes from './DropdownField.module.scss';

export type DropdownFieldValue = string;

export type DropdownOption = {
    label?: string;
    value: DropdownFieldValue;
};

/**
 * Define this component's public API type
 */
export interface DropdownFieldApi extends ComponentApi {
    setFieldValue: (newValue: string) => void;
    focus: () => void;
    setFieldErrors: (errors: FieldState['errors']) => void;
    setFieldError: (error: string | FieldState['errors'][number]) => void;
}

export type DropdownFieldProps<ErrorData = string> = {
    options: DropdownOption[];
    emptyOptionLabel?: string;
    /**
     * Controls how the error should be constructed from the FieldState and fieldId. This is how to control the
     * complete appearance of the field's error message. By default, it shows the first validation error as a FieldError
     *
     * @var renderError
     */
    renderError?: (errorData: RenderErrorData) => ReactElement | null;
} & BaseFormFieldProps<HTMLSelectElement, ErrorData, DropdownFieldValue> &
    EmptyComponent;

const DropdownField: FC<DropdownFieldProps> = ({
    name,
    label,
    options,
    emptyOptionLabel,
    defaultValue = '',
    labelVariant = 'above',
    className,
    id,
    wasFormSubmitted = false,
    validate = noopValidator as NonNullable<DropdownFieldProps<string>['validate']>,
    onChange = noop,
    onValueChange = noop,
    onFocus = noop,
    onBlur = noop,
    onValidityChange = noop,
    required = false,
    api: setApi,
    renderError = defaultRenderError,
    'aria-describedby': describedBy,
}) => {
    const fieldId = id ?? name;
    const fieldErrorId = getFieldErrorId(fieldId);

    const fieldRef = useRef<HTMLSelectElement>(null);
    const [fieldState, { setFieldValue, setFieldErrors, clearFieldErrors, fieldWasTouched }] = useFieldState(
        FieldState({ value: defaultValue })
    );
    // Determining whether the field has been "touched" involves tracking multiple pieces of stateful data so we use
    // milestones to track those different goals until we get to the field being "touched."
    const [{ blurred: fieldWasBlurred, changed: fieldWasChanged }] = useMilestones(
        { blurred: true, changed: true },
        () => {
            fieldWasTouched();
        }
    );
    // State to track when the `<input>` element itself and its wrapper are currently focused to control visibility of
    // field controls
    const [isFieldElFocused, { on: focusFieldEl, off: blurFieldEl }] = useToggleState(false);

    useComponentApiDef<DropdownFieldApi>(
        {
            setFieldValue(newValue) {
                // Programmatically trigger a change event instead of setting the state manually in order to trigger all
                // the validation and internal state updates that way. Any parent components tracking the field value
                // should call this API function and update their state through the `onChange` prop handler rather than
                // calling this AND setting their state at the same time. This maintains unidirectional flow.
                if (fieldRef.current) {
                    triggerChangeEvent(fieldRef.current, newValue);
                }
            },
            focus() {
                (fieldRef.current as HTMLSelectElement).focus();
            },
            setFieldError(error) {
                const newError =
                    typeof error === 'string'
                        ? ValidationFailure({ reason: error, meta: { field: name, label } })
                        : error;
                setFieldErrors([newError]);
            },
            setFieldErrors(validationFailures) {
                setFieldErrors(validationFailures);
            },
        },
        setApi,
        name
    );

    useEffectOnMounted(() => {
        // Handle initial validation
        const initialValidationFailures = validate(getFieldValue(fieldState));

        // If we got a validation failure from initial validation, set it and communicate up
        if (initialValidationFailures.length > 0) {
            setFieldErrors(initialValidationFailures);
            onValidityChange(initialValidationFailures, true);
        }
    });

    return (
        <div id={`${fieldId}_container`} className={className}>
            <div className="has-rds-mb-8">
                <FieldLabel fieldId={fieldId} className={classes.label} isHidden={labelVariant === 'hidden'}>
                    {label}
                </FieldLabel>
            </div>

            <div
                className={classNames(classes.fieldWrapper, {
                    [classes.isFocused]: isFieldElFocused,
                    [classes.isError]:
                        !isFieldElFocused &&
                        (wasFormSubmitted || isFieldTouched(fieldState)) &&
                        !isFieldValid(fieldState),
                })}
            >
                <select
                    name={name}
                    ref={fieldRef}
                    id={fieldId}
                    className={classes.field}
                    value={getFieldValue(fieldState)}
                    onChange={ev => {
                        const { value: newValue, name: fieldName } = ev.target;
                        setFieldValue(newValue);

                        // Updated value validation
                        const validationFailures = validate(newValue);
                        // An empty array of validation failures should give us `undefined` for `firstError` whereas
                        // only checking for falsey means that a (for whatever reason) failure reason of '', 0, or
                        // `false` would get a false match so we check explicitly for `undefined`.
                        if (!deepEqual(getFieldErrors(fieldState), validationFailures)) {
                            if (validationFailures.length === 0) {
                                // If there's no validation failure, clear the error
                                clearFieldErrors();
                                onValidityChange([], false);
                            } else {
                                // If the validation failure has changed, set that and broadcast up
                                setFieldErrors(validationFailures);
                                onValidityChange(validationFailures, false);
                            }
                        }

                        fieldWasChanged();
                        onValueChange(newValue, fieldName, ev);
                        onChange(ev);
                    }}
                    onFocus={ev => {
                        focusFieldEl();
                        onFocus(ev);
                    }}
                    onBlur={ev => {
                        blurFieldEl();
                        fieldWasBlurred();
                        onBlur(ev);
                    }}
                    aria-required={required}
                    aria-describedby={classNames({
                        // TODO Switch to use FieldState selector (currently not working for some reason)
                        [fieldErrorId]: fieldState.errors.length > 0,
                        ...(typeof describedBy === 'string' ? { [describedBy]: true } : {}),
                    })}
                >
                    {emptyOptionLabel ? <option value="">{emptyOptionLabel}</option> : null}
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label ?? option.value}
                        </option>
                    ))}
                </select>

                <DropdownArrowIcon className={classes.icon} />
            </div>

            <div id={fieldErrorId}>{renderError({ fieldState, fieldId, wasFormSubmitted })}</div>
        </div>
    );
};

export default DropdownField;
