import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import warning from 'warning';

import { ReactComponent as LegendIndicatorGreenOutlineTriangleNotch } from 'src/assets/images/LegendIndicatorGreenOutlineTriangleNotch.svg';
import { ReactComponent as LegendIndicatorGreenOutlineSquareNotch } from 'src/assets/images/LegendIndicatorGreenOutlineSquareNotch.svg';
import { ReactComponent as LegendIndicatorGreenOutline } from 'src/assets/images/LegendIndicatorGreenOutline.svg';
import { ReactComponent as LegendIndicatorBlueOutlineSquareNotch } from 'src/assets/images/LegendIndicatorBlueOutlineSquareNotch.svg';
import { ReactComponent as LegendIndicatorGreenCircle } from 'src/assets/images/LegendIndicatorGreenCircle.svg';
import { ReactComponent as LegendIndicatorBlueSquare } from 'src/assets/images/LegendIndicatorBlueSquare.svg';

const legendIndicatorMap = {
    // Standard legend indicators
    GreenTriangleNotch: LegendIndicatorGreenOutlineTriangleNotch,
    GreenSquareNotch: LegendIndicatorGreenOutlineSquareNotch,
    // Temporary MyMedica. To be deleted
    GreenOutline: LegendIndicatorGreenOutline,
    BlueSquareNotch: LegendIndicatorBlueOutlineSquareNotch,
    // Temporary PLD + FedVip. To be deleted
    GreenCircle: LegendIndicatorGreenCircle,
    BlueSquare: LegendIndicatorBlueSquare,
};

const propTypes = {
    a11yText: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.keys(legendIndicatorMap)),
    src: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    src: undefined,
    type: undefined,
    className: undefined,
};

const CardLegendIndicator = ({ type, src, a11yText, className }) => {
    warning(
        type || src,
        'Failed prop type: One of props `src` or `type` is required but neither were provided in `CardLegendIndicator`.'
    );

    const indicator = legendIndicatorMap[type];

    if (!indicator) {
        // Prevent component from crashing the whole page if the `type` prop is even slightly off, like a typo
        return null;
    }

    if (type) {
        return createElement(indicator, { className, 'aria-label': a11yText });
    }

    return <img className={className} src={src} alt={a11yText} />;
};

CardLegendIndicator.propTypes = propTypes;
CardLegendIndicator.defaultProps = defaultProps;

export default CardLegendIndicator;
