import React from 'react';
import type { FC } from 'react';
import type { EmptyComponent } from 'src/app/types';
import SvgIcon from './SvgIcon';

export interface CircleCrossIconProps extends EmptyComponent {
    className?: string;
}

const CircleCrossIcon: FC<CircleCrossIconProps> = ({ className }) => (
    <SvgIcon size="md" className={className}>
        <circle cx="12" cy="12" r="12" fill="#990000" />
        <path d="M8 8 L16 16 M8 16 L16 8" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
);

export default CircleCrossIcon;
