import PropTypes from 'prop-types';

export const bannerApi = PropTypes.shape({
    show: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
});

export const loadingBarApi = PropTypes.shape({
    show: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
});

export const isLoadingBarVisible = PropTypes.bool;
