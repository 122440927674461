import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LayoutContainer } from 'src/components/Containers';

import classes from './ModalBody.module.scss';

const propTypes = {
    modalId: PropTypes.string.isRequired,
    containContent: PropTypes.bool,
    lightbox: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

const defaultProps = {
    containContent: true,
    lightbox: false,
};

const ModalBody = ({ modalId, containContent, lightbox, children }) =>
    containContent ? (
        <div id={`${modalId}_modalBody`} className={classes.root}>
            <LayoutContainer className={classes.container} removeEndingMargins>
                {children}
            </LayoutContainer>
        </div>
    ) : (
        <div
            id={`${modalId}_modalBody`}
            className={classNames(classes.root, {
                [classes.isLightbox]: lightbox,
            })}
        >
            {children}
        </div>
    );

ModalBody.propTypes = propTypes;
ModalBody.defaultProps = defaultProps;

export default ModalBody;
