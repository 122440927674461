import { useRef } from 'react';
import { useEffectOnMounted } from 'src/hooks';
import isEmptyObject from 'src/utils/isEmptyObject';

import useConstant from './useConstant';

type ApiFunction = (...args: any[]) => unknown;

export type ComponentApi = Record<string, ApiFunction>;

export type OpaqueApiSetter = {
    set(api: ComponentApi, id?: string): void;
    unset(id?: string): void;
};

/**
 * The type for a `useComponentApi()` that only holds one, non-namespaced component API
 */
type SingleComponentApi = ComponentApi;
/**
 * The type for a `useComponentApi()` that holds multiple namespaced component APIs
 */
type MultiComponentApi = Record<string, SingleComponentApi>;

/**
 * Define a public API for a component that can be passed up to parent components
 *
 * These hooks provide the ability for a component to expose a public API for performing programmatic actions defined by
 * the component. `useComponentApiDef()` is used to defined the component API and connect it to the paired
 * `useComponentApi()` which holds the API for one or more child components by the parent component. It borrows some
 * concepts from native DOM refs. Where a ref usually provides a variable to hold a native DOM node, requiring the
 * component holding it to manually operate on the DOM node, and API is a defined set of functionality for the component
 * which allows a component to define a set of actions for a parent to use which can provide consistency as public APIs
 * are meant to. For example, with a custom component:
 *
 * - Using a ref for the <input> element of the custom component, if you want to programmatically focus on the <input>,
 *   you would call the `.focus()` method on the DOM node. If there was any other behavior the component needed to
 *   happen when focusing on the element, each parent component would need to include that manually.
 * - Using an API for the custom component, the exposed `.focus()` function would not only focus on the <input> element,
 *   the custom component can define additional things that need to happen for consistency when programmatically
 *   focusing.
 *
 * These custom hooks store stable copies of the defined and held component APIs.
 *
 * - `useComponentApiDef()` takes one required arg (the defined API object) and 2 optional args: the `setApi` that comes
 *   from `useComponentApi()` and an id to optionally namespace the API for the holding component in case it needs to
 *   hold multiple component APIs. It returns the API defined by the first arg for use inside the defining component.
 * - `useComponentApi()` takes zero args and returns a tuple of the defined API(s) and the opaque `setApi` object which
 *   is passed to the child components that define an API that will be used. If the child components include namespaces,
 *   the API object have multiple APIs under namespaces (e.g. a form holding APIs for its form fields would have each
 *   component API namespaced under the `name` prop value of the form field component). If the child component does not
 *   define a namespace, then its API will be the top-level value of the API. These are referred to as MultiComponentApi
 *   and SingleComponentApi, respectively.
 *
 * SingleComponentApi example:
 * <code>
 * interface ChildApi extends ComponentApi {
 *   func1: (...) => void;
 *   func2: (...) => void;
 * }
 *
 * const Child = ({ api: setApi, onEvent, ...props }) => {
 *   useComponentApiDef<ChildApi>({
 *     func1: (...) => {...},
 *     func1: (...) => {...},
 *   }, setApi);
 *
 *   onEvent(...);
 *
 *   return (...);
 * };
 *
 * const Parent => (props) => {
 *   const [childApi, setApi] = useComponentApi<ChildApi>();
 *
 *   return (
 *     <Child api={setApi} onEvent={() => { childApi.func1(); }} />
 *   );
 * };
 * </code>
 *
 * MultiComponentApi example:
 * <code>
 * interface InputFieldAPi extends ComponentApi {
 *   func1: (...) => void;
 *   func2: (...) => void;
 * }
 *
 * const InputField = ({ name, api: setApi, onChange, ...props }) => {
 *   useComponentApiDef<ChildApi>({
 *     func1: (...) => {...},
 *     func1: (...) => {...},
 *   }, setApi, name);
 *
 *   onEvent(...);
 *
 *   return (...);
 * };
 *
 * const Form => (props) => {
 *   // The type for MultiComponentApi must resolved to an object of string keys and `ComponentApi` values. If all the
 *   // field component APIs are the same type, you can use Record<"fieldName1" | "fieldName2" | "fieldNameN", FieldApi>
 *   // but if you have different types of fields, you will need to explicitly define the object type for each key.
 *   const [fieldApis, setApi] = useComponentApi<Record<'firstName' | 'lastName', InputFieldApi>>();
 *
 *   return (
 *     <InputField name="firstName" api={setApi} onEvent={() => { fieldApis.firstName.func1(); }} />
 *     <InputField name="lastName" api={setApi} onEvent={() => { fieldApis.lastName.func1(); }} />
 *   );
 * };
 * </code>
 */

export const useComponentApiDef = <Api extends ComponentApi>(
    apiObject: Api,
    setApi?: OpaqueApiSetter,
    id?: string
): Api => {
    const apiDef = useRef<Api>({} as Api);
    useEffectOnMounted(() => {
        apiDef.current = apiObject;
        // Only attempt to set the API if the setter was provided
        if (setApi) {
            setApi.set(apiDef.current, id);
        }

        return () => {
            // Only attempt to unset the API if the setter was provided
            if (setApi) {
                setApi.unset(id);
            }
        };
    });
    return apiDef.current;
};

export const useComponentApi = <Api extends SingleComponentApi | MultiComponentApi>(
    forceSingleComponentApi = false
): [Api, OpaqueApiSetter] => {
    // Store the original setting to lock in single component API so that it can't be accidentally overridden later and
    // cause unexpected behavior
    const shouldBeSingleComponentApi = useConstant(forceSingleComponentApi);
    const api = useRef<Api>({} as Api);
    const setApi = useConstant<OpaqueApiSetter>({
        set: (componentApi, id) => {
            if (!shouldBeSingleComponentApi && id) {
                (api.current as MultiComponentApi)[id] = componentApi;
            } else {
                // If there are some components with that provide ids and one or more that don't, we want to avoid
                // clobbering all of the ones with namespaces by a component API without an ID
                if (!isEmptyObject(api.current)) {
                    throw new Error(
                        'Unable to set single API. API object appears to have namespaced APIs already attached and setting single API would override all of them.'
                    );
                }
                if (Object.keys(api.current).length > 0) {
                    throw new Error(
                        'Attempting to override single component API that is already set for this component.'
                    );
                }
                (api.current as SingleComponentApi) = componentApi;
            }
        },
        unset: id => {
            if (!shouldBeSingleComponentApi && id) {
                // If we have an `id` and ONLY if that `id` exists as a namespace on the `api` object do we delete it
                if ((api.current as MultiComponentApi)[id]) {
                    delete (api.current as MultiComponentApi)[id];
                }
            } else {
                (api.current as SingleComponentApi) = {};
            }
        },
    });

    return [api.current, setApi];
};
