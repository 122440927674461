import React from 'react';
import { CloseIcon } from 'src/UI/Icon';
import format from 'src/utils/format';

import type { FC, MouseEventHandler, FocusEventHandler } from 'react';
import { WithContentProps, withContent } from 'src/components/ContentProvider';
import type { EmptyComponent } from 'src/app/types';
import { PortalContentData } from 'src/features/PortalData/types';
import Button from './Button';

import classes from './CrossButton.module.scss';

type CrossButtonPublicProps = {
    id?: string;
    label: string;
    onClick: MouseEventHandler<HTMLButtonElement>;
    onBlur?: FocusEventHandler<HTMLButtonElement>;
} & EmptyComponent;

const mapContentToProps = ({ LblClearInput }: PortalContentData, { label }: CrossButtonPublicProps) => ({
    // NOTE: This overrides the `label` prop
    label: format(LblClearInput, { FIELD_NAME: label }),
});

type CrossButtonContentProps = WithContentProps<typeof mapContentToProps>;
export type CrossButtonProps = CrossButtonContentProps & CrossButtonPublicProps;

const CrossButton: FC<CrossButtonProps> = ({ id, label, onClick, onBlur, ...rest }) => (
    <Button
        variant="icon"
        id={id}
        className={classes.root}
        clicked={onClick}
        onBlur={onBlur}
        aria-label={label}
        {...rest}
    >
        <CloseIcon />
    </Button>
);

export default withContent(mapContentToProps)(CrossButton);
export { CrossButton };
