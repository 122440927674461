import reactRef from './reactRef';
import validationRules from './validationRules';
import formField from './formField';
import formFieldConfig from './formFieldConfig';
import { routerLocation, routerMatch, routerHistory } from './reactRouter';
import { bannerApi, isLoadingBarVisible, loadingBarApi } from './globalApi';

export default {
    reactRef,
    validationRules,
    formField,
    formFieldConfig,
    routerLocation,
    routerMatch,
    routerHistory,
    bannerApi,
    loadingBarApi,
    isLoadingBarVisible,
};
