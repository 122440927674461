import React from 'react';
import classNames from 'classnames';
import RdsIcon from '../Icon/Icon';
import { SystemPaginationArrowLeft } from '../Icon/Icons';
import { IconLinkSizeTypes, LinkVariants, LinkProps } from './Link.interface';

const IconLinkMap: Record<string, string> = {
    'external-inline': 'external-inline',
    'external-footer': 'external-footer',
    'icon-left': 'icon-left',
    'icon-right': 'icon-right',
    standalone: 'standalone',
};

const typographySizeToRightIconSize: Record<string, string> = {
    'body-2': 'lg',
    'body-3': 'md',
    sm: 'sm',
};

const getLinkClasses = (
    variant: LinkVariants,
    colorBranded: boolean,
    typographySize: IconLinkSizeTypes,
    isNeutralColored: boolean,
    className?: string
): string => {
    let variantClass;

    // Map to the shortened CSS classes for these variants
    if (variant.match('external')) {
        variantClass = variant.replace('external', 'ext');
    } else if (variant.match('standalone')) {
        variantClass = 'sa';
    }

    return classNames(
        `rds-link-${variantClass || variant}`,
        {
            'is-rds-body-2': !!(IconLinkMap[variant] && typographySize === 'body-2'),
            'is-rds-body-3': !!(IconLinkMap[variant] && typographySize === 'body-3'),
            'is-rds-sm': !!(IconLinkMap[variant] && typographySize === 'sm'),
            'is-white-labeled-link': colorBranded && variant.match('footer') === null,
            'is-rds-neutral-link': isNeutralColored,
        },
        className
    );
};

const RdsLink = React.forwardRef<
    HTMLAnchorElement, // The reference type - which element the final ref is attached to.
    LinkProps // props type for component
>(
    (
        {
            as,
            children,
            className,
            colorBranded = true,
            hasExternalLinkOutIcon = false,
            iconEnv = 'prod',
            isNeutralColor = false,
            leftIconName,
            leftIconSize,
            rightIconName,
            rightIconSize,
            typographySize = 'body-3',
            variant = 'inline',
            ...anchorAttributes
        },
        forwardedRef
    ) => {
        const Component = (as || 'a') as React.ElementType;
        const linkClasses = getLinkClasses(variant, colorBranded, typographySize, isNeutralColor, className);
        let iconRight = null;
        let iconLeft = null;

        // Separate from the other conditional logic because the hasExternalLinkOutIcon prop should be overrided
        // by the variant conditions below.
        if (variant === 'external-inline' || variant === 'external-footer' || hasExternalLinkOutIcon) {
            iconRight = (
                <RdsIcon
                    env={iconEnv}
                    name={`system-link-out-${typographySizeToRightIconSize[typographySize]}`}
                    size="16"
                    className={classNames({ 'has-rds-ml-8': hasExternalLinkOutIcon })}
                />
            );
        }

        // If hasExternalLinkOutIcon is true but one of the following right-sided icon variants is selected,
        // the variant selection will take precedence.
        if (variant === 'standalone') {
            iconRight = (
                <RdsIcon
                    env={iconEnv}
                    name={`system-arrow-${typographySizeToRightIconSize[typographySize]}`}
                    size="16"
                />
            );
        } else if (variant === 'icon-left') {
            iconLeft = <RdsIcon env={iconEnv} name={leftIconName} size={leftIconSize} />;
        } else if (variant === 'icon-right') {
            iconRight = <RdsIcon env={iconEnv} name={rightIconName} size={rightIconSize} />;
        } else if (variant === 'back') {
            iconLeft = <SystemPaginationArrowLeft />;
        }

        return (
            <Component ref={forwardedRef} className={linkClasses} {...anchorAttributes}>
                {iconLeft}
                {children}
                {iconRight}
            </Component>
        );
    }
);

export default RdsLink;
