import React from 'react';
import classNames from 'classnames';

import type { FC } from 'react';

import classes from './LayoutContainer.module.scss';

interface LayoutContainerProps {
    id?: string;
    className?: string;
    removeEndingMargins?: boolean;
}

const LayoutContainer: FC<LayoutContainerProps> = ({ id, className, removeEndingMargins = false, children }) => (
    <div
        id={id}
        className={classNames(classes.root, className, { [classes.removeEndingMargins]: removeEndingMargins })}
    >
        {children}
    </div>
);

export default LayoutContainer;
