import { Predicate } from 'src/app/types';

const isEmptyFieldValue: Predicate<string> = value => value === '';

const isEmptyToggleState: Predicate<boolean> = toggleState => !toggleState;

/**
 * Test if a form field value is not an empty value
 *
 * Empty values for form fields can vary:
 * - For most text-based fields, empty string is the empty value
 * - For checkboxes and radios, for the field `value` prop, empty string is the empty value while for just requiring the
 *   field to be selected, empty value is `false`
 *
 * @param value a field value
 * @returns true if the value is filled, false if it is not
 */
export const isRequiredField: Predicate<string | boolean> = value => {
    if (typeof value === 'string') {
        return !isEmptyFieldValue(value);
    }

    return !isEmptyToggleState(value);
};
