import type { FieldLiveValidationErrorsProps } from './FieldLiveValidationErrors';

export const generateItemsFromConfig = (
    config: Array<{ id?: string; label: string }>
): FieldLiveValidationErrorsProps['items'] =>
    config.reduce((items, { id, label }) => {
        if (id) {
            items.push({ id, label });
        }

        return items;
    }, [] as FieldLiveValidationErrorsProps['items']);
