export const loadScript = (url = ''): Promise<void> =>
    new Promise((resolve, reject) => {
        // Immediately reject if receiving invalid source
        if (!url) reject();
        const sj = document.createElement('script');
        sj.onload = _ => resolve();
        sj.onerror = reject;
        sj.async = true;
        sj.src = url;
        const s = document.getElementsByTagName('script')[0];
        if (s.parentNode) {
            s.parentNode.insertBefore(sj, s);
        }
    });
