import type { IStorage, StorageDriver } from './types';

const Storage = (storageDriver: StorageDriver): IStorage => ({
    put(key, value, options = {}) {
        storageDriver.put(key, value, options);
    },
    get(key, options = {}) {
        return storageDriver.get(key, options);
    },
    remove(key, options = {}) {
        storageDriver.remove(key, options);
    },
    has(key, options = {}) {
        return storageDriver.has(key, options);
    },
    clear(options = {}) {
        return storageDriver.clear(options);
    },
    updateMeta(key, meta = {}) {
        return storageDriver.updateMeta(key, meta);
    },
});

export default Storage;
