/**
 * Because TypeScript uses structural typing and LogLevel enum values have no custom fields and should be typed
 * nonimally (e.g. is-a typing), the enum values will match an empty object type (e.g. `Record<PropertyKey, never>`)
 * which defeats the purpose because `{}` would match the type. The solution is to use a private and unique key on each
 * LogLevel enum value that can't be faked externally. Symbols fit this but we need to get the type of a single Symbol
 * instance which requires a few steps and a small amount of unintuitive type definition. This solution is based on
 * {@link https://stackoverflow.com/a/59120564}.
 */
// Define the unique, private Symbol value
const instSymbol: unique symbol = Symbol('LogLevelInst');
// Get the type for this specific Symbol value
type LogLevelInstanceSymbol = typeof instSymbol;

// TypeScript doesn't support using a specific Symbol type as a specific key for an object type, but the mapped type
// syntax seems to work. Now the `LogLevel` type must be an object with the specific Symbol instance above defined as a
// property key.
type LogLevel = { [Key in LogLevelInstanceSymbol]: true };

type LogLevelConstructor = {
    (name: string, value: number): LogLevel;
    prototype: {
        constructor: LogLevelConstructor;
    };
    /**
     * Convert a primitive string or value to a LogLevel instance
     *
     * @param nameOrValue The string or number to attempt to get the LogLevel value for
     * @returns A LogLevel that matches the name or value, or undefined if no matching value is found
     */
    from(nameOrValue?: string | number): LogLevel | undefined;
    /**
     * A predicate function to test if a value is a LogLevel instance
     *
     * @param x Any value
     * @returns true if the value is a LogLevel instance, false otherwise
     */
    isLogLevel(x: unknown): x is LogLevel;
    /** Log very low-level, granular information, usually to trace data through code paths */
    DEBUG: LogLevel;
    /** Log informational info, usually high-level waypoints through various code paths */
    INFO: LogLevel;
    /** Log warnings for unexpected outcomes that don't break the application but should signal potential issues */
    WARN: LogLevel;
    /** Log significant errors that aren't expected, like unexpected server responses for specific endpoints */
    ERROR: LogLevel;
    /** Log critical failures that break the application */
    FATAL: LogLevel;
    /** @note This level should never send a log entry and is used to disable a specific Log Destination handler */
    DISABLED: LogLevel;
};

/**
 * Private state used to prevent further creation of LogLevel instances
 */
let isClosed = false;

/**
 * A richer enum definition than TypeScript provides
 *
 * TypeScript enums only type check the underlying value but not the string "reverse lookup" value. LogLevel needs to be
 * usable as both a number for log level filtering and a string for the log message output. JS allows objects to behave
 * as string and number primitives in different contexts using well defined type coercion rules. And for type checking,
 * LogLevel values should match the type of the namespace value and be instances of the namespace value.
 *
 * This implementation groups all of this together in one place. TypeScript requires more explicitness to coerce objects
 * to strings and numbers in some cases, but we have single enum values that work in multiple cases:
 * - Satisfies `instanceof` comparison: `LogLevel.DEBUG instanceof LogLevel === true`
 * - Each enum value is only `===` equivalent to itself. TS enums can compare to literal number or string values.
 * - Can be order-compared using `>`, `>=`, `<`, `<=` for log filtering
 * - Can output the correct string representation using `String(LogLevel.DEBUG)`
 */
const LogLevel: LogLevelConstructor = (name, value) => {
    if (isClosed) {
        throw new Error('LogLevel has been frozen and cannot define more instances');
    }
    // Satisfies `instanceof` capabilities
    const inst = <LogLevel>Object.create(LogLevel.prototype);
    Object.assign(inst, {
        [Symbol.toPrimitive](hint: string) {
            return hint === 'string' ? name : value;
        },
        valueOf() {
            return value;
        },
        toString() {
            return name;
        },
        toJSON() {
            return name;
        },
        // Satisfies TS structural typing to differentiate from an empty object
        [instSymbol]: true,
    });
    // Prevent the LogLevel instance from being modified
    return Object.freeze(inst);
};

LogLevel.prototype = {
    constructor: LogLevel,
};

// Define all enum values. They can be detached from the namespace for shorthand use as well.
LogLevel.DEBUG = LogLevel('DEBUG', 10);
LogLevel.INFO = LogLevel('INFO', 20);
LogLevel.WARN = LogLevel('WARN', 30);
LogLevel.ERROR = LogLevel('ERROR', 40);
LogLevel.FATAL = LogLevel('FATAL', 50);
LogLevel.DISABLED = LogLevel('DISABLED', 100);

const logLevelNames = ['DEBUG', 'INFO', 'WARN', 'ERROR', 'FATAL', 'DISABLED'] as const;
LogLevel.from = function from(nameOrValue) {
    if (!nameOrValue) {
        return undefined;
    }

    const foundName = logLevelNames.find(name => {
        const inst = LogLevel[name];
        return nameOrValue === String(inst) || nameOrValue === Number(inst);
    });
    return foundName ? LogLevel[foundName] : undefined;
};

LogLevel.isLogLevel = function isLogLevel(x): x is LogLevel {
    return x instanceof LogLevel;
};

// Freeze the LogLevel enum to prevent creation of more instances and any modifications
isClosed = true;
Object.freeze(LogLevel);

export { LogLevel };
