import React from 'react';
import classNames from 'classnames';

import type { FC } from 'react';

import classes from './ContentContainer.module.scss';

interface ContentContainerProps {
    id?: string;
    className?: string;
    centerText?: boolean;
}

const ContentContainer: FC<ContentContainerProps> = ({ children, centerText = true, className, id }) => (
    <div
        id={id}
        className={classNames(classes.root, className, {
            [classes.centered]: centerText,
        })}
    >
        {children}
    </div>
);

export default ContentContainer;
