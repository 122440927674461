import React from 'react';
import classNames from 'classnames';

import type { ElementType, ReactNode, ReactElement } from 'react';

import classes from './Stack.module.scss';

export type ValidElements = Pick<JSX.IntrinsicElements, 'div' | 'section' | 'fieldset' | 'header' | 'footer' | 'aside'>;

interface BaseProps<C extends ElementType> {
    as?: C;
    className?: string;
    id?: string;
    children: ReactNode;
}

export type StackProps<C extends ElementType = 'div'> = BaseProps<C>;

const getComponentClassNames = (className?: string) => classNames('is-flex', classes.root, className);

/**
 * A component that applies consistent vertical spacing between direct children components
 *
 * @note Based on https://every-layout.dev/layouts/stack/
 */
const Stack = <C extends keyof ValidElements = 'div'>({
    as,
    className,
    id,
    children,
    ...props
}: StackProps<C>): ReactElement => {
    const Component = as || ('div' as ElementType);
    return (
        <Component {...props} id={id} className={getComponentClassNames(className)}>
            {children}
        </Component>
    );
};

Stack.composeProps = (
    props: { [prop: string]: unknown; className?: string } = {}
): { [prop: string]: unknown; className: string } => ({
    ...props,
    className: getComponentClassNames(props.className),
});

export default Stack;
