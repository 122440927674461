import React, { useContext, useMemo } from 'react';

import type { FC } from 'react';
import { rewriteContentModalLinks, rewriteContentNavigationLinks } from 'src/utils/html';
import compose from 'src/utils/compose';
import AppStore from 'src/stores/AppStore';
import { isNativeApp } from 'src/features/PortalData';

interface HtmlContentProps {
    content: string;
    block?: boolean;
    className?: string;
}

const HtmlContent: FC<HtmlContentProps> = ({ content, block = false, className }) => {
    const { portalBrand, lang, configData } = useContext(AppStore);
    const nativeApp = isNativeApp(configData);
    // Memoize the output so that we're not doing DOM manipulations on each re-render
    const processedContent = useMemo(
        () =>
            (
                compose(rewriteContentNavigationLinks(portalBrand, lang), rewriteContentModalLinks(nativeApp)) as (
                    html: string
                ) => string
            )(content),
        [content, portalBrand, lang, nativeApp]
    );

    return React.createElement(block ? 'div' : 'span', {
        className,
        dangerouslySetInnerHTML: { __html: processedContent },
    });
};

export default HtmlContent;
