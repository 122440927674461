import { getFormattedDate } from 'src/utils/dateTimeUtility';
import { loadScript } from 'src/utils/html';
import { getFormattedPhoneNumber, getPlainPhoneNumber, maskPhoneNumber } from 'src/utils/phone';
import { formURL, parseQueryParams, stringifyQueryParams } from 'src/utils/urlUtility';

const CommonUtility = {
    /**
     * @deprecated Use import { maskPhoneNumber } from 'src/utils/phone'; instead
     * returns value of masked phone number except the last n characters as number and remaining with 'x' replaced.
     * if value already masked, returns the value without change
     * @param {*} phoneVal
     * @param {*} unmaskedDigitsCount number of digits to show, default 4
     * @param {*} maskChar case-insensitive default 'x'
     */
    maskPhoneNumber(phoneVal, unmaskedDigitsCount = 4, maskChar = 'x') {
        return maskPhoneNumber(phoneVal, unmaskedDigitsCount, maskChar);
    },
    /**
     * @deprecated Use import { loadScript } from 'src/utils/html'; instead
     * @param url
     */
    loadScript(url) {
        return loadScript(url);
    },
    /**
     * @deprecated Use import { formURL } from 'src/utils/urlUtility'; instead
     * Will form new url and take care of encoding the query parameters with white space, &, @, % etc.
     * @param {*} domain
     * @param {*} path ex: /secure/authenticate or /protected/accountreset/password
     * @param {*} queryParams object with query params you want to append at the end expect a object
     *                       ex: {queryParamName1: "value1", queryParamName2: "value2"}
     */
    formURL(domain, path, queryParams) {
        return formURL(domain, path, queryParams ?? {});
    },
    /**
     * @deprecated use withContent() HOC to handle JSON data in AEM
     * @param str
     */
    // TODO: remove remaining uses and delete this function
    isJson(str) {
        let jsonValue = '';
        try {
            jsonValue = JSON.parse(str);
        } catch (e) {
            return false;
        }
        return jsonValue;
    },
    /**
     * @deprecated Use import { getFormattedPhoneNumber } from 'src/utils/phone'; instead
     * @param {*} phoneNumber will be phone number as string ex "1231231234"
     * @param countryCode is optional but  will add the country code in front
     * will return as (123) 123-1234 or +1 (123) 123-1234
     * @param withPlus prepend "+" to the formatted phone number if countryCode is also provided: "+1 (123) 123-1234"
     */
    getFormattedPhoneNumber(phoneNumber, countryCode, withPlus) {
        return getFormattedPhoneNumber(phoneNumber, countryCode ?? null, withPlus ?? false);
    },
    /**
     * @deprecated Use import { getPlainPhoneNumber } from 'src/utils/phone'; instead
     * @param {*} phoneNumber given +1 (123) 123-1234
     * will return 11231231234
     */
    getPlainPhoneNumber(phoneNumber) {
        return getPlainPhoneNumber(phoneNumber);
    },
    /**
     * @deprecated Use import { getFormattedDate } from 'src/utils/dateTimeUtility'; instead
     * @param {*} date to split the values into day,month and year
     * returns string format of day, month and year.
     */
    getFormattedDate(date) {
        return getFormattedDate(date);
    },
    /**
     * @deprecated Use import { parseQueryParams } from 'src/utils/urlUtility'; instead
     * @param searchString
     */
    getQueryParamsFromUrl(searchString) {
        return parseQueryParams(searchString);
    },
    /**
     * @deprecated Use import { stringifyQueryParams } from 'src/utils/urlUtility'; instead
     * @param queryObject
     */
    stringifyQueryParams(queryObject) {
        if (queryObject) {
            return stringifyQueryParams(queryObject);
        }
        throw new TypeError('Query Object cannot be empty');
    },
};

export default CommonUtility;
