import { FlowNames } from 'src/app/Constants';

import type { Values } from 'src/app/types';

export const SignInFlowSteps = {
    SIGN_IN: 'login',
    TFA_CONFIRM: 'tfa-confirm',
    TFA_CHALLENGE: 'tfa-challenge',
    COMPLETE: 'complete',
    UPDATE_RECOVERY: 'update-recovery',
    TFA_SETUP: 'tfa-setup',
    TFA_VERIFICATION: 'tfa-verification',
} as const;

export type SignInFlowStep = Values<typeof SignInFlowSteps>;

export const signInRoutePath = `/${FlowNames.LOGIN}/:portal/:lang?/:step?`;

export const TMX_RESOURCE = '/fp/tags.js';
export const TMX_ORIGIN = 'https://rba-screen.healthsafe-id.com';
