import { useState } from 'react';

import type { Dispatch, SetStateAction } from 'react';

import { FieldConfig } from 'src/models';
import getInitialCurrentState from 'src/utils/getInitialCurrentState';

import type { Field } from 'src/models';

const useInitialFieldState = (value?: string, defaultValue = ''): [Field, Dispatch<SetStateAction<Field>>] =>
    useState(
        FieldConfig({
            value: value || defaultValue,
            currentState: getInitialCurrentState(value),
        })
    );

export default useInitialFieldState;
