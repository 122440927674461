import { useEffect, useRef, useState } from 'react';

import { useEffectOnMounted, useConstant } from 'src/hooks';
import debounce from 'src/utils/debounce';

import type { FC, ReactElement } from 'react';

export interface DebounceProps {
    delay: number;
    children: ReactElement;
    debounceFn?: typeof debounce;
}

const Debounce: FC<DebounceProps> = ({ children, delay, debounceFn = debounce }) => {
    // Initialize ref to hold next content to render. Also intializing ref with children so that typescript doesn't complain about ref being undefined.
    const nextChildren = useRef<DebounceProps['children']>(children);
    // Store the most recent children on every render in the ref so that it doesn't trigger re-render.
    nextChildren.current = children;
    // Initialize state that holds actually rendered content.
    const [renderedChildren, setRenderedChildren] = useState<DebounceProps['children']>(nextChildren.current);
    // Create stable function reference for debounced state setter.
    const [debouncedSetRenderedChildren, cleanupDebounce] = useConstant(debounceFn(setRenderedChildren, delay));
    useEffect(() => {
        // On each render pass, if the next children is different than the currently rendered children, call debounced state updater
        if (nextChildren.current !== renderedChildren) {
            debouncedSetRenderedChildren(nextChildren.current);
        }
    });
    // Cleaning up the setTimeout timers on component unmount
    useEffectOnMounted(() => cleanupDebounce);
    // Render children from state
    return renderedChildren;
};

export default Debounce;
