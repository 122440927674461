import { useRef } from 'react';

const useFocusManagement = () => {
    const focusState = useRef(null);

    const rememberFocus = (newFocusTarget = null) => {
        focusState.current = document.activeElement;
        if (newFocusTarget) {
            let target = {};
            if (newFocusTarget.current) {
                target = newFocusTarget.current;
            } else if (newFocusTarget.nodeName) {
                target = newFocusTarget;
            }
            if (typeof target.focus === 'function') {
                target.focus();
            }
        }
    };

    const restoreFocus = () => {
        focusState.current.focus();
        focusState.current = null;
    };

    return { rememberFocus, restoreFocus };
};

export default useFocusManagement;
