import React, { DetailedHTMLProps } from 'react';
import classNames from 'classnames';

import type { ElementType, ReactElement, HTMLAttributes } from 'react';

import HtmlContent from 'src/UI/HtmlContent/HtmlContent';

import type { ForwardedRef } from 'src/app/types';

const sizePropToRdsClassMap = {
    h1: 'is-rds-h1',
    h2: 'is-rds-h2',
    h3: 'is-rds-h3',
    h4: 'is-rds-h4',
    h5: 'is-rds-h5',
    h6: 'is-rds-h6',
    b1: 'is-rds-body-1',
    b2: 'is-rds-body-2',
    b3: 'is-rds-body-3',
    small: 'is-rds-sm',
    micro: 'is-rds-micro',
} as const;

type AcceptedTitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';

interface BaseTitleProps {
    size?: keyof typeof sizePropToRdsClassMap;
    headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
    className?: string;
    html?: boolean;
}

interface HeadingTitleProps
    extends BaseTitleProps,
        DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
    headingLevel: 1 | 2 | 3 | 4 | 5 | 6;
}

interface DivTitleProps extends BaseTitleProps, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    headingLevel?: never;
}

export type TitleProps = HeadingTitleProps | DivTitleProps;

const TitleInner = (
    { children, size, headingLevel, className, html, ...props }: TitleProps,
    ref?: ForwardedRef<AcceptedTitleTag>
): ReactElement => {
    const TagName = (typeof headingLevel !== 'undefined' ? `h${headingLevel}` : 'div') as ElementType;

    return (
        <TagName {...props} className={classNames(size ? sizePropToRdsClassMap[size] : undefined, className)} ref={ref}>
            {html && typeof children === 'string' ? <HtmlContent content={children} /> : children}
        </TagName>
    );
};

TitleInner.displayName = 'Title';

const Title = React.forwardRef(TitleInner) as typeof TitleInner;

export default Title;
