import React from 'react';

import type { FC } from 'react';

import SvgIcon, { SvgIconProps } from './SvgIcon';

export type LockIconProps = { className?: SvgIconProps['className'] };

const LockIcon: FC<LockIconProps> = ({ className }) => (
    <SvgIcon size="md" className={className}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
        />
    </SvgIcon>
);

export default LockIcon;
