import { LocalStorage } from 'src/app/Storage';
import noop from 'src/utils/noop';
import HSIDService from 'src/service/HSIDService';
import { minutes } from 'src/utils/dateTimeUtility';

import { PortalConfigData } from './config/PortalConfigData';

// 10 minutes
const MAX_AGE_MINUTES = minutes(10);
const getMaxAgeForCache = () => Date.now() + MAX_AGE_MINUTES;
// Revalidate when the maxAge is in the past
const shouldRevalidateData = cachedData => cachedData.meta.maxAge < Date.now();

// Factory with helpers to standardize creating PortalData objects.
// NOTE In most cases, the static functions should be used
const PortalData = ({ data = null, ...meta } = {}) => ({ data, ...meta });
Object.assign(PortalData, {
    empty() {
        return PortalData();
    },
    loaded({ data, hash, shouldRevalidate = false }) {
        return PortalData({
            data,
            shouldRevalidate,
            hash,
        });
    },
    revalidated({ data = null }) {
        return PortalData({ data });
    },
});

// Generate a content hash from a string
const getContentHash = async content => {
    // Source: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
    const digestBuffer = await crypto.subtle.digest('SHA-256', new TextEncoder('utf-8').encode(content));
    return Array.from(new Uint8Array(digestBuffer))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
};

// Generate a content hash based on the payload of an Axios response object
const getContentHashFromResponse = ({ headers, data }) =>
    headers.etag || getContentHash(typeof data === 'string' ? data : JSON.stringify(data));

const PortalConfigService = {
    /**
     * Low level utility to generate the storage key
     */
    getStorageKey(portalBrand, lang) {
        return `config:${portalBrand}:${lang}`;
    },
    /**
     * Low level utility to write the data to the cache. Should only cache the raw response.
     */
    cache(portalBrand, lang, data, hash) {
        LocalStorage.put(this.getStorageKey(portalBrand, lang), data, { meta: { hash, maxAge: getMaxAgeForCache() } });
    },
    /**
     * Low level utility to update the maxAge metadata
     */
    refreshMaxAge(portalBrand, lang) {
        LocalStorage.updateMeta(this.getStorageKey(portalBrand, lang), { maxAge: getMaxAgeForCache() });
    },
    /**
     * Low-level utility to make the network call to get the data and normalize the response
     */
    // TODO Uncomment once backend support is added. Needs CORS header approval to send `If-None-Match`
    // fetch(portalBrand, lang, { hash } = {}) {
    fetch(portalBrand, lang) {
        const config = {
            // headers: {
            //     ...(hash ? { 'If-None-Match': hash } : null),
            // },
            withCredentials: false,
        };

        return HSIDService.get(`/uiconfig/${portalBrand}/${lang}`, config).then(async response => ({
            ...response,
            headers: {
                ...response.headers,
                etag: await getContentHashFromResponse(response),
            },
        }));
    },
    /**
     * Load the data either from the cache or from the network, caching it if loaded from network
     */
    load(portalBrand, lang) {
        return new Promise((resolve, reject) => {
            let cachedData = null;
            try {
                cachedData = LocalStorage.get(this.getStorageKey(portalBrand, lang), { includeMeta: true });
            } catch (e) {
                // If this fails, it probably means the cached data was bad or old and so we let
                // the normal flow of the `if/else()` fallback to fetch fresh data and overwrite
            }
            if (cachedData) {
                resolve(
                    PortalData.loaded({
                        data: PortalConfigData(cachedData.value),
                        hash: cachedData.meta.hash,
                        shouldRevalidate: shouldRevalidateData(cachedData),
                    })
                );
            } else {
                this.fetch(portalBrand, lang).then(({ data, headers }) => {
                    this.cache(portalBrand, lang, data, headers.etag);
                    resolve(
                        PortalData.loaded({
                            data: PortalConfigData(data),
                            hash: headers.etag,
                        })
                    );
                }, reject);
            }
        });
    },
    /**
     * Revalidate the data against the network, updating the cache if the data has changed. Provide the updated data
     * if it has changed or `null` if it hasn't changed.
     */
    revalidate(portalBrand, lang, hash, shouldRevalidate = false) {
        if (!shouldRevalidate) {
            return Promise.resolve(PortalData.empty());
        }

        return this.fetch(portalBrand, lang, { hash }).then(({ headers, data }) => {
            if (headers.etag === hash) {
                // Update the maxAge window to prevent unnecessary fetches during a session
                this.refreshMaxAge(portalBrand, lang);
                // Signal no updated data
                return PortalData.empty();
            }

            this.cache(portalBrand, lang, data, headers.etag);
            return PortalData.revalidated({ data });
        });
    },
};

const PortalContentService = {
    /**
     * Low level utility to generate the storage key
     */
    getStorageKey(portalBrand, lang) {
        return `content:${portalBrand}:${lang}`;
    },
    /**
     * Low level utility to write the data to the cache. Should only cache the raw response.
     */
    cache(portalBrand, lang, data, hash) {
        LocalStorage.put(this.getStorageKey(portalBrand, lang), data, { meta: { hash, maxAge: getMaxAgeForCache() } });
    },
    /**
     * Low level utility to update the maxAge metadata
     */
    refreshMaxAge(portalBrand, lang) {
        LocalStorage.updateMeta(this.getStorageKey(portalBrand, lang), { maxAge: getMaxAgeForCache() });
    },
    /**
     * Low-level utility to make the network call to get the data and normalize the response
     */
    // TODO Uncomment once backend support is added. Needs CORS header approval to send `If-None-Match`
    // fetch(portalBrand, lang, { hash } = {}) {
    fetch(portalBrand, lang) {
        const config = {
            // headers: {
            //     ...(hash ? { 'If-None-Match': hash } : null),
            // },
        };

        return HSIDService.get(`/hsid2/content/${portalBrand}/${lang}`, config).then(async response => ({
            ...response,
            headers: {
                ...response.headers,
                etag: await getContentHashFromResponse(response),
            },
        }));
    },
    /**
     * Load the data either from the cache or from the network, caching it if loaded from network
     */
    load(portalBrand, lang) {
        return new Promise((resolve, reject) => {
            let cachedData = null;
            try {
                cachedData = LocalStorage.get(this.getStorageKey(portalBrand, lang), { includeMeta: true });
            } catch (e) {
                // If this fails, it probably means the cached data was bad or old and so we let
                // the normal flow of the `if/else()` fallback to fetch fresh data and overwrite
            }
            if (cachedData) {
                resolve(
                    PortalData.loaded({
                        data: cachedData.value,
                        hash: cachedData.meta.hash,
                        shouldRevalidate: shouldRevalidateData(cachedData),
                    })
                );
            } else {
                this.fetch(portalBrand, lang).then(({ data, headers }) => {
                    this.cache(portalBrand, lang, data, headers.etag);
                    resolve(
                        PortalData.loaded({
                            data,
                            hash: headers.etag,
                        })
                    );
                }, reject);
            }
        });
    },
    /**
     * Revalidate the data against the network, updating the cache if the data has changed. Provide the updated data
     * if it has changed or `null` if it hasn't changed.
     */
    revalidate(portalBrand, lang, hash, shouldRevalidate = false) {
        if (!shouldRevalidate) {
            return Promise.resolve(PortalData.empty());
        }

        return this.fetch(portalBrand, lang, { hash }).then(({ headers, data }) => {
            if (headers.etag === hash) {
                // Update the maxAge window to prevent unnecessary fetches during a session
                this.refreshMaxAge(portalBrand, lang);
                // Signal no updated data
                return PortalData.empty();
            }

            this.cache(portalBrand, lang, data, headers.etag);
            return PortalData.revalidated({ data });
        });
    },
};

const PortalDataService = {
    getPortalData(
        portalBrand,
        lang,
        { onInitialData = noop, onInitialError = noop, onUpdatedData = noop, onUpdatedError = noop } = {}
    ) {
        Promise.all([PortalConfigService.load(portalBrand, lang), PortalContentService.load(portalBrand, lang)]).then(
            ([configData, contentData]) => {
                onInitialData({ config: configData.data, content: contentData.data });

                Promise.all([
                    PortalConfigService.revalidate(portalBrand, lang, configData.hash, configData.shouldRevalidate),
                    PortalContentService.revalidate(portalBrand, lang, contentData.hash, contentData.shouldRevalidate),
                ]).then(([updatedConfigData, updatedContentData]) => {
                    onUpdatedData({
                        config: updatedConfigData.data,
                        content: updatedContentData.data,
                    });
                }, onUpdatedError);
            },
            onInitialError
        );
    },
};

export default PortalDataService;
