import { ensureArray } from 'src/utils/arr';
import { LogLevel } from '../LogLevel';

import type { LogFn, LogFormatter } from '../types';

type ConsoleLoggerFnName = 'debug' | 'info' | 'warn' | 'error';

/**
 * Lookup `console` function names based on LogLevel enum values
 *
 * @param level The LogLevel being used
 * @returns The `console` function to call
 */
const getConsoleMethodName = (level: LogLevel): ConsoleLoggerFnName => {
    switch (level) {
        case LogLevel.INFO:
            return 'info';
        case LogLevel.WARN:
            return 'warn';
        case LogLevel.ERROR:
            return 'error';
        case LogLevel.FATAL:
            return 'error';
        case LogLevel.DEBUG:
        default:
            return 'debug';
    }
};

/**
 * @private Exported for test purposes
 */
export type ConsoleDriver = Pick<Console, ConsoleLoggerFnName>;

type ConsoleLoggerConfig = {
    /**
     * An object with console logging functions
     */
    consoleDriver?: ConsoleDriver;
    /**
     * A function to format a {@link LogData} entry as a string to be output
     */
    formatter?: LogFormatter;
};

const defaultConsoleLogFormatter: LogFormatter = logData => {
    const { level, message, ...rest } = logData;

    return `[${String(level)}] ${message} ${JSON.stringify(rest)}`;
};

/**
 * Create a log() function that outputs log information to the platform-appropriate console (browser console,
 * STDOUT/STDERR, etc)
 *
 * @public
 * @param [config] The config for the console logger
 * @returns A {@link LogFn} function that outputs to the platform console
 */
export const logToConsole =
    ({ formatter = defaultConsoleLogFormatter, consoleDriver = globalThis.console }: ConsoleLoggerConfig = {}): LogFn =>
    logData => {
        ensureArray(logData).forEach(ld => {
            consoleDriver[getConsoleMethodName(ld.level)](formatter(ld));
        });
    };
