import Storage from './Storage';
import { SessionStorageDriver, LocalStorageDriver, MemoryStorageDriver } from './WebStorageDriver';

import type { WebStorageDriverEncoder } from './WebStorageDriver';

import type { IStorage } from './types';

const keyPrefix = 'uhg.hsid.';

const encoder: WebStorageDriverEncoder = {
    encode: input => window.btoa(window.btoa(input)),
    decode: input => window.atob(window.atob(input)),
};

const SessionStorage = Storage(SessionStorageDriver({ keyPrefix, encoder }));
const LocalStorage = Storage(LocalStorageDriver({ keyPrefix, encoder }));

export { Storage, SessionStorageDriver, LocalStorageDriver, SessionStorage, LocalStorage, MemoryStorageDriver };

export type { IStorage, WebStorageDriverEncoder };
