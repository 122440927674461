import React from 'react';
import PropTypes from 'prop-types';

import { EyeIcon } from 'src/UI/Icon';

import { withContent } from 'src/components/ContentProvider';
import Button from './Button';

const propTypes = {
    isToggled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const defaultProps = {
    isToggled: false,
};

const mapContentToProps = ({ LblHide, LblShow }, { isToggled, label }) => ({
    // NOTE: This overrides the `label` prop
    label: (isToggled ? LblHide : LblShow).replace('[FIELD_NAME]', label),
});

const EyeButton = ({ isToggled, label, onClick, ...rest }) => (
    <Button variant="icon" clicked={onClick} aria-label={label} {...rest}>
        <EyeIcon variant={isToggled ? 'hidden' : 'visible'} />
    </Button>
);

EyeButton.propTypes = propTypes;
EyeButton.defaultProps = defaultProps;

export default withContent(mapContentToProps)(EyeButton);
export { EyeButton };
