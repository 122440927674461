import React from 'react';
import classNames from 'classnames';
import ButtonGroup from 'src/UI/ButtonGroup/ButtonGroup';
import type { FC } from 'react';
import classes from './FormActions.module.scss';

export interface FormActionsProps {
    className?: string;
}

const FormActions: FC<FormActionsProps> = ({ children, className }) => (
    <ButtonGroup layout="responsive" className={classNames(classes.root, className)}>
        {children}
    </ButtonGroup>
);

export default FormActions;
