/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';
import { getCdnUrl, getSvgContent, rallyIconContent } from '../utils';
import { IconProps } from './Icon.interface';

export const RDS_ICON = 'rds-icon';

const getIconClasses = (name?: string, size?: string, className?: string): string =>
    [RDS_ICON, name || '', size ? `${RDS_ICON}-${size}` : '', className].join(' ');

const RdsIcon = React.forwardRef<
    HTMLSpanElement, // The reference type - which element the final ref is attached to.
    IconProps
>(({ className, env = 'prod', name, size, ...spanProps }, forwardedRef) => {
    const iconClasses = getIconClasses(name, size, className);
    const [loadedIcon, setLoadedIcon] = useState('');

    useEffect(() => {
        if (name) {
            const url = getCdnUrl(name, env);
            // iconContent can be an svg | '' | undefined. It will be cached as empty string if a previous request for that icon failed.
            // This precludes the use of rallyIconContent's map `has` method, since it will return true for an empty string.
            const iconContent = rallyIconContent.get(url);

            if (iconContent) {
                // synchronous
                setLoadedIcon(iconContent);
            } else {
                // retrieve svg from CDN
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-floating-promises
                getSvgContent(url).then((svg: any) => {
                    setLoadedIcon(svg || '');
                });
            }
        }
    }, [env, name]);

    // Spans are used here for sizing the SVG and for being able to inject the SVG contents from the CDN
    // TODO: explore decoupling the svg itself from the outer contents: https://jira.rallyhealth.com/browse/UCRON-1455
    return name ? (
        <span ref={forwardedRef} {...spanProps} className={iconClasses}>
            <span dangerouslySetInnerHTML={{ __html: loadedIcon }} />
        </span>
    ) : null;
});

export default RdsIcon;
