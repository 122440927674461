import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';

import SvgIcon from './SvgIcon';

export interface DropdownArrowProps extends EmptyComponent {
    className?: string;
    alt?: string;
}

const DropdownArrow: FC<DropdownArrowProps> = ({ className }) => (
    <SvgIcon size="sm" className={className}>
        <polygon points="1,4 15,4 8,10" fill="#2D2D39" />
    </SvgIcon>
);

export default DropdownArrow;
