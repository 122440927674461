import { FlowNames } from 'src/app/Constants';

import type { Values } from 'src/app/types';

export const RegistrationFlowSteps = {
    BASIC_INFO: 'credentials',
    NONMEMBER_INFO: 'personal-info',
    VERIFY_EMAIL: 'verify-email',
    MEMBER_INFO: 'eligibility',
    TFA_SETUP: 'recovery-option',
    TFA_CONFIRM: 'recovery-verify',
    COMPLETED: 'completed',
} as const;

export type RegistrationFlowStep = Values<typeof RegistrationFlowSteps>;

export const registerRoutePath = `/${FlowNames.REGISTER}/:portal/:lang?/:step?`;
