import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { LayoutContainer } from 'src/components/Containers';
import Title from 'src/UI/Title/Title';
import { QaClassNames } from 'src/app/QaConstants';

import CloseButton from '../CloseButton/CloseButton';
import classes from './ModalHeader.module.scss';

const propTypes = {
    modalId: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    lightbox: PropTypes.bool,
    isGeneric: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
};

const defaultProps = {
    // NOTE: undefined, because `Modal` calls this and defines a default
    lightbox: undefined,
    isGeneric: false,
    hideCloseButton: false,
};

const ModalHeader = ({ modalId, value, lightbox, hideCloseButton, isGeneric, handleCloseModal }) => (
    <div
        id={`${modalId}_modalHeader`}
        className={classNames(classes.root, {
            [classes.lightbox]: lightbox,
            [classes.generic]: isGeneric,
        })}
    >
        <LayoutContainer className={classes.container}>
            {!hideCloseButton ? (
                <div className={classes.closeButton}>
                    <CloseButton
                        id={`${modalId}_closeButton`}
                        modalTitleId={`${modalId}_modalTitle`}
                        handleClose={handleCloseModal}
                    />
                </div>
            ) : null}
            <div className={classes.title}>
                <Title
                    id={`${modalId}_modalTitle`}
                    headingLevel={2}
                    size={lightbox ? 'h2' : 'h1'}
                    className={classNames(QaClassNames.MODAL_HEADING, 'has-rds-text-grey-darker')}
                    html
                >
                    {value}
                </Title>
            </div>
        </LayoutContainer>
    </div>
);

ModalHeader.propTypes = propTypes;
ModalHeader.defaultProps = defaultProps;

export default ModalHeader;
