import React from 'react';
import type { FC } from 'react';

import { InputField } from 'src/UI/Form';
import { useComponentApi, useComponentApiDef } from 'src/hooks';
import noop from 'src/utils/noop';
import {
    getPlainPhoneNumber as getIntlPlainPhoneNumber,
    getIntlFormattedPhoneNumber,
    UsaCountryCode,
} from 'src/utils/phone';
import { noopValidator } from 'src/utils/validation';

import type { Validator } from 'src/utils/validation';
import type { EmptyComponent } from 'src/app/types';
import type { BaseFormFieldProps } from './types';
import type { InputFieldApi, InputFieldProps } from './InputField';

export type PhoneFieldProps = BaseFormFieldProps<HTMLInputElement> &
    Pick<InputFieldProps, 'renderError' | 'placeholder'> &
    EmptyComponent & {
        countryCode?: string;
    };

// disallow alphabets
const removeDisallowedCharacters = (value: string) => value.replace(/[^0-9()-]/g, '');

const PhoneField: FC<PhoneFieldProps> = ({
    name,
    label,
    subLabel,
    countryCode = UsaCountryCode,
    placeholder,
    defaultValue = '',
    labelVariant,
    required,
    validate = noopValidator,
    onFocus,
    onBlur,
    onValidityChange = noop,
    onChange,
    onValueChange = noop,
    renderError,
    wasFormSubmitted,
    api: setApi,
    'aria-describedby': describedBy,
}) => {
    const [fieldApi, setFieldApi] = useComponentApi<Record<string, InputFieldApi>>();

    // TODO FUTURE: US5937523 - DRY useComponentApiDef
    useComponentApiDef<
        InputFieldApi & {
            setFieldValue(phoneNumber: string, countryCode: string): void;
        }
    >(
        {
            setFieldValue(phoneNumber) {
                fieldApi[name].setFieldValue(getIntlFormattedPhoneNumber(phoneNumber, countryCode));
            },
            focus() {
                fieldApi[name].focus();
            },
            setFieldError(error) {
                fieldApi[name].setFieldError(error);
            },
            setFieldErrors(validationFailures) {
                fieldApi[name].setFieldErrors(validationFailures);
            },
            resetField() {
                fieldApi[name].resetField();
            },
        },
        setApi,
        name
    );

    return (
        <InputField
            api={setFieldApi}
            aria-describedby={describedBy}
            autoComplete="tel"
            defaultValue={getIntlFormattedPhoneNumber(defaultValue, countryCode)}
            filter={removeDisallowedCharacters}
            id={name}
            label={label}
            subLabel={subLabel}
            labelVariant={labelVariant}
            maxLength={16}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onValidityChange={onValidityChange}
            onValueChange={(newValue, fieldName, ev) => onValueChange(getIntlPlainPhoneNumber(newValue), fieldName, ev)}
            placeholder={placeholder}
            renderError={renderError}
            required={required}
            type="tel"
            validate={
                ((newValue: string) => validate(getIntlPlainPhoneNumber(newValue))) as Validator<
                    string,
                    string,
                    { field: string; label: string; validationId?: string }
                >
            }
            wasFormSubmitted={wasFormSubmitted}
        />
    );
};

export default PhoneField;
