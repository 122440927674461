import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';

import SvgIcon from './SvgIcon';

export interface CalendarIconProps extends EmptyComponent {
    className?: string;
}

const CalendarIcon: FC<CalendarIconProps> = ({ className }) => (
    <SvgIcon size="md" className={className}>
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M15.4629 1.09121V2.51306H19.4629C20.0152 2.51306 20.4629 2.96078 20.4629 3.51306V5.49518H0.462891V3.51306C0.462891 2.96078 0.910606 2.51306 1.46289 2.51306H5.46289V1.09121C5.46289 0.421179 6.71289 0.393103 6.71289 1.09121V2.51306H14.2129V1.09121C14.2129 0.327643 15.4629 0.343359 15.4629 1.09121ZM19.2129 12.9952H14.2129V9.24518H19.2129V12.9952ZM19.2129 17.9952H14.2129V14.2369L19.2129 14.2452V17.9952ZM12.9629 12.9952H7.96289V9.24518H12.9629V12.9952ZM12.9629 17.9952H7.96289V14.2369H12.9629V17.9952ZM6.71289 12.9952H1.71289V9.24518H6.71289V12.9952ZM6.71289 17.9952H1.71289V14.2452H6.71289V17.9952ZM0.462891 19.5244V6.52441H20.4629V19.5244C20.4629 20.0767 20.0152 20.5244 19.4629 20.5244H1.46289C0.910606 20.5244 0.462891 20.0767 0.462891 19.5244Z"
        />
    </SvgIcon>
);

export default CalendarIcon;
