export const setCookie = (name: string, value: string, days?: number): void => {
    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value || ''}${expires}; path=/`;
};

export const setCookieWithoutExpiration = (name: string, value: string): void => {
    setCookie(name, value);
};

export const getCookie = (name: string): string | null => {
    const cookieName = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(cookieName) === 0) return c.substring(cookieName.length, c.length);
    }
    return null;
};

export const deleteCookie = (name: string): void => {
    document.cookie = `${name}=; Max-Age=-99999999;`;
};
