import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';

import { withContent } from 'src/components/ContentProvider';
import { AlertIcon } from 'src/UI/Icon';
import Text from 'src/UI/Text/Text';
import { Stack } from 'src/components/Containers';
import compose from 'src/utils/compose';
import format from 'src/utils/format';

import type { ReactElement } from 'react';
import type { EmptyComponent } from 'src/app/types';
import type { WithContentProps } from 'src/components/ContentProvider';

import Title from 'src/UI/Title/Title';
import classes from './FormErrorsSummary.module.scss';

export type FieldErrorItem = {
    elementId: string;
    elementLabel: string;
};

export type TextOnlyFormErrorsSummary = { message: string | ReactElement };
export type ListOnlyFormErrorsSummary = { fields: FieldErrorItem[] };
export type TextAndListFormErrorsSummary = TextOnlyFormErrorsSummary & ListOnlyFormErrorsSummary;

export type FormErrorsSummaryData =
    | TextOnlyFormErrorsSummary
    | ListOnlyFormErrorsSummary
    | TextAndListFormErrorsSummary;

type FormErrorsSummaryPublicApiProps = {
    /**
     * @note `id` is required for QA locators
     */
    id: string;
    center?: boolean;
    error: FormErrorsSummaryData;
    headingLevel?: 2 | 3 | 4 | 5 | 6;
} & EmptyComponent;

const hasSummary = (error: FormErrorsSummaryData): boolean =>
    'message' in error && (typeof error.message === 'string' || React.isValidElement(error.message));
const hasFields = (error: FormErrorsSummaryData): boolean => 'fields' in error && Array.isArray(error.fields);

// NOTE leaving `isTextOnlyError` in but commented in case it is needed in the future since it rounds out the
// `FormErrorsSummaryData` union checks
// const isTextOnlyError = (error: FormErrorsSummaryData): error is TextOnlyFormErrorsSummary => hasSummary(error) && !hasFields(error);

const isListOnlyError = (error: FormErrorsSummaryData): error is ListOnlyFormErrorsSummary =>
    hasFields(error) && !hasSummary(error);

const isTextAndListError = (error: FormErrorsSummaryData): error is TextAndListFormErrorsSummary =>
    hasSummary(error) && hasFields(error);

const mapContentToProps = {
    listErrorSummaryText: 'HdrSummaryErr',
} as const;

type FormErrorsSummaryContentProps = WithContentProps<typeof mapContentToProps>;

export type FormErrorsSummaryProps = FormErrorsSummaryPublicApiProps & FormErrorsSummaryContentProps;

const renderListErrorSummary = (
    error: ListOnlyFormErrorsSummary | TextAndListFormErrorsSummary,
    listErrorSummaryText: FormErrorsSummaryContentProps['listErrorSummaryText']
) => format(listErrorSummaryText, { NUMBER_OF: error.fields.length });

/**
 * Form level error summary.
 *
 * Replaces <SummaryError />
 */
const FormErrorsSummary = ({
    id,
    center = false,
    error,
    headingLevel = 2,
    listErrorSummaryText,
}: FormErrorsSummaryProps): ReactElement => {
    const rootEl = useRef<HTMLDivElement>(null);

    const renderFieldErrorLink = ({ elementId, elementLabel }: FieldErrorItem) => (
        <a className={classes.fieldErrorLink} id={`${elementId}_fieldError`} href={`#${elementId}`}>
            {elementLabel}
        </a>
    );

    const renderSummary = (message: string | ReactElement) =>
        typeof message === 'string' ? <Text html>{message}</Text> : message;

    const renderFirstLine = () => {
        // There is a list of fields so the first line is the count summary and optional single error field link
        if (isListOnlyError(error) || isTextAndListError(error)) {
            return (
                <Title headingLevel={headingLevel} size="b3">
                    {renderListErrorSummary(error, listErrorSummaryText)}
                </Title>
            );
        }

        return renderSummary(error.message);
    };

    useEffect(() => {
        // Focus on this element when it renders or the `error` prop changes
        if (error && rootEl.current) {
            rootEl.current.focus();
        }
    }, [error]);

    return (
        <article
            id={id}
            className={classNames(classes.root, 'has-rds-pv-16 has-rds-ph-8', { 'has-rds-mh-auto': center })}
            tabIndex={-1}
            ref={rootEl}
            role="alert"
        >
            <div className="is-flex">
                <div className={`has-rds-mr-8 ${classes.iconWrapper}`}>
                    <AlertIcon />
                </div>
                <Stack>
                    {renderFirstLine()}
                    {(isListOnlyError(error) || isTextAndListError(error)) && error.fields.length >= 1 ? (
                        <ul className={`has-rds-pl-16 has-rds-mt-none ${classes.fieldErrorList}`}>
                            {error.fields.map(field => (
                                <Text tag="li" key={field.elementId}>
                                    {renderFieldErrorLink(field)}
                                </Text>
                            ))}
                        </ul>
                    ) : null}
                    {isTextAndListError(error) ? renderSummary(error.message) : null}
                </Stack>
            </div>
        </article>
    );
};

export { FormErrorsSummary };
export default compose(withContent(mapContentToProps))(FormErrorsSummary) as (
    props: Omit<FormErrorsSummaryProps, keyof FormErrorsSummaryContentProps>
) => JSX.Element;
