import React from 'react';

import { logger } from 'src/app/Logger';
import noop from 'src/utils/noop';

/**
 * @note With TS, optional props on class components must be defined as required and then have the default value set on
 * `static defaultProps = {...}`
 * @see https://stackoverflow.com/a/37282264
 */
export type ErrorBoundaryProps = {
    /**
     * The fallback `children` to display when a component error is caught. Shows nothing by default.
     * @default null
     * @optional
     */
    fallback: React.ReactElement | null;
    /**
     * Custom error handler that receives the same arguments as {@link import('react').Component.componentDidCatch}
     * @default () => {}
     * @optional
     */
    onError: (error: Error, errorInfo: React.ErrorInfo) => void;
    /**
     * The `children` to render under normal circumstances
     */
    children: React.ReactElement;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

/**
 * General purpose Error Boundary component
 *
 * @see https://legacy.reactjs.org/docs/error-boundaries.html
 */
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    static defaultProps = {
        fallback: null,
        onError: noop,
    };

    /**
     * <ErrorBoundary> constructor
     *
     * @note Apparently class component props have to be explicitly redefined in `constructor()` signature
     * @see https://fettblog.eu/typescript-react/components/#constructors
     *
     * @constructor
     */
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(/* error: Error */) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logger.error('ErrorBoundary caught an error', { error: { js: error, react: errorInfo } });
        this.props.onError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback;
        }

        return this.props.children;
    }
}
