import { LanguageCode, isSupportedLanguage, SupportedHtmlLangCode } from 'src/utils/lang';
import { LanguageCodeMap } from './Constants';

/**
 * returns HTML lang attribute based on the language code
 * @param lang language code from the url
 */
const getLangCode = (lang?: string): SupportedHtmlLangCode =>
    isSupportedLanguage(lang) ? LanguageCodeMap[lang][0] : LanguageCodeMap[LanguageCode.EN][0];

export default getLangCode;
