// make sure we can handle window.load even the event had been fired
const windowLoadPromise = new Promise(resolve => {
    if (typeof window !== 'undefined') {
        window.addEventListener('load', () => resolve(undefined), false);
    } else {
        resolve(undefined);
    }
});

// attach window.load event
export const attachWindowLoad = (fn: () => void) => windowLoadPromise.finally(fn);
