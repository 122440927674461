import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Title from 'src/UI/Title/Title';
import Text from 'src/UI/Text/Text';
import { withContent } from 'src/components/ContentProvider';
import { Button } from 'src/UI/Button';
import noop from 'src/utils/noop';

import PlusMinusIcon from './PlusMinusButton';
import classes from './Accordion.module.scss';

const propTypes = {
    id: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    content: PropTypes.arrayOf(
        PropTypes.shape({
            Q: PropTypes.string.isRequired,
            A: PropTypes.string.isRequired,
        })
    ).isRequired,
    viewAllLabel: PropTypes.string.isRequired,
    hideAllLabel: PropTypes.string.isRequired,
    onToggle: PropTypes.func,
    onToggleAll: PropTypes.func,
};

const defaultProps = {
    onToggle: noop,
    onToggleAll: noop,
};

const mapContentToProps = {
    viewAllLabel: 'LblViewAnswer',
    hideAllLabel: 'LblHideAnswer',
};

const Accordion = ({ id, header, content, viewAllLabel, hideAllLabel, onToggle, onToggleAll }) => {
    const [items, setItems] = useState(
        content.map((item, i) => ({
            id: i,
            title: item.Q,
            body: item.A,
            isOpen: false,
        }))
    );
    const [isGroupOpen, setIsGroupOpen] = useState(false);

    const toggleItem = itemId => {
        const modifiedItems = items.map(item => (item.id === itemId ? { ...item, isOpen: !item.isOpen } : item));
        setItems(modifiedItems);
        onToggle(modifiedItems[itemId].isOpen, modifiedItems[itemId]);
        setIsGroupOpen(modifiedItems.every(item => item.isOpen));
    };

    const toggleAllItems = () => {
        setItems(items.map(item => ({ ...item, isOpen: !isGroupOpen })));
        onToggleAll(isGroupOpen);
        setIsGroupOpen(!isGroupOpen);
    };

    const allToggleTitleId = `${id}-accordionSectionTitle`;

    return (
        <div>
            <div className={`${classes.header} has-rds-pb-16 has-rds-pt-32`}>
                <Title size="b3" headingLevel={3} className={classes.headerText} id={allToggleTitleId}>
                    {header}
                </Title>
                <Button
                    variant="link-icon-left"
                    clicked={toggleAllItems}
                    aria-expanded={isGroupOpen}
                    aria-describedby={allToggleTitleId}
                >
                    <span className={classes.headerButtonWrapper}>
                        <PlusMinusIcon isOpen={isGroupOpen} className={`${classes.headerIcon} has-rds-mr-8`} />
                        <Text tag="span" size="b2" className={classes.headerLabel}>
                            {isGroupOpen ? hideAllLabel : viewAllLabel}
                        </Text>
                    </span>
                </Button>
            </div>
            {items.map(item => (
                <div key={item.id} className={`${classes.body} has-rds-mb-8`}>
                    <Button
                        variant="link-heading"
                        aria-expanded={item.isOpen}
                        className={`${classes.titleButton} has-rds-p-16`}
                        clicked={() => toggleItem(item.id)}
                    >
                        <span className={classes.titleButtonWrapper}>
                            <Text className={classes.titleText} size="b1" tag="span" html>
                                {item.title}
                            </Text>
                            <PlusMinusIcon isOpen={item.isOpen} className={classes.titleIcon} />
                        </span>
                    </Button>

                    {item.isOpen && (
                        <Text size="b2" className="has-rds-p-16" html>
                            {item.body}
                        </Text>
                    )}
                </div>
            ))}
        </div>
    );
};

Accordion.propTypes = propTypes;
Accordion.defaultProps = defaultProps;

export default withContent(mapContentToProps)(Accordion);
export { Accordion };
