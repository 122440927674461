/**
 * returns value of masked phone number except the last n characters as number and remaining with 'x' replaced.
 * @param {*} phoneVal
 * @param {*} unmaskedDigitsCount number of digits to show, default 4
 * @param {*} maskChar default 'x'
 */
export const maskPhoneNumber = (phoneVal: string, unmaskedDigitsCount = 4, maskChar = 'x'): string => {
    if (!phoneVal || phoneVal?.length < 4) {
        return '';
    }
    return (
        maskChar.repeat(phoneVal.length - unmaskedDigitsCount) + phoneVal.slice(phoneVal.length - unmaskedDigitsCount)
    );
};
