import { Predicate } from 'src/app/types';

/**
 * @param dateValue string
 * Checks for the date in the format of 'yyyy-mm-dd'
 * also matches a date in yyyy-mm-dd format
 */

/**
 * Compare the given input with the date format, allowing us to
 * take built in functions to get the current date to dynamically compare with
 * Not needed to have the year range parameters.
 */

export const isCompleteDateFieldValue: Predicate<string> = x =>
    /^\d{4}[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])$/.test(x || '');
