import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';

import SvgIcon from './SvgIcon';

export interface CloseIconProps extends EmptyComponent {
    className?: string;
}

const CloseIcon: FC<CloseIconProps> = ({ className }) => (
    <SvgIcon size="sm" className={className}>
        <g stroke="currentColor" strokeWidth="1.25" strokeLinecap="round">
            <line x1="1" y1="1" x2="15" y2="15" />
            <line x1="1" y1="15" x2="15" y2="1" />
        </g>
    </SvgIcon>
);

export default CloseIcon;
