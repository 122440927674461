import PropTypes from 'prop-types';

export const routerLocation = PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    // NOTE: `PropTypes.any` allowed here because React Router state can store any value
    state: PropTypes.any,
});

export const routerMatch = PropTypes.shape({
    isExact: PropTypes.bool.isRequired,
    params: PropTypes.shape({}).isRequired,
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

export const routerHistory = PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
});
