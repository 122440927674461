import type { Values } from 'src/app/types';

export const ModalDataAttributeIds = {
    CONSUMER_COMMUNICATIONS: 'consumerNotice',
    LEARN_MORE: 'learnMore',
    PRIVACY_POLICY: 'privacyPolicy',
    PRIVACY_POLICY_IFRAME: 'privacyPolicyIframe',
    TERMS_OF_USE: 'termsOfUse',
    TERMS_OF_USE_IFRAME: 'termsOfUseIframe',
    TERMS_OF_USE_TYPO: 'termsOfuser',
    TEXTING_TERMS: 'textingTerms',
    TRUSTED_DEVICE: 'trustedDevice',
} as const;

export type ModalIdentifier = Values<typeof ModalDataAttributeIds>;
