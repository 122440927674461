import React from 'react';
import classNames from 'classnames';

import Text from 'src/UI/Text/Text';
import { SvgIcon } from 'src/UI/Icon';
import { withContent } from 'src/components/ContentProvider';
import compose from 'src/utils/compose';
import { seconds } from 'src/utils/dateTimeUtility';
import { Debounce } from 'src/components/Debounce';

import type { FC, ReactElement } from 'react';
import type { EmptyComponent } from 'src/app/types';
import type { WithContentProps } from 'src/components/ContentProvider';
import type { ValidationFailure } from 'src/utils/validation';

import classes from './FieldLiveValidationErrors.module.scss';

const renderValidDecoration = (): ReactElement<SVGElement> => (
    <polyline
        points="4,8 7,11 12,5"
        stroke="#FFF"
        strokeWidth="2"
        strokeLinejoin="round"
        strokeLinecap="round"
        fill="none"
    />
);

const renderInvalidDecoration = (): ReactElement<SVGElement> => <rect fill="#FFF" x="4" y="7" width="8" height="2" />;

type LiveValidationStatus = 'default' | 'valid' | 'invalid';

const renderDecoration = (status: LiveValidationStatus): ReactElement<SVGElement> | null => {
    switch (status) {
        case 'valid':
            return renderValidDecoration();
        case 'invalid':
            return renderInvalidDecoration();
        default:
            return null;
    }
};

const mapContentToProps = {
    validItemA11yText: 'LblHasBeenMet',
    invalidItemAllyText: 'LblHasNotBeenMet',
};

type FieldLiveValidationErrorsContentProps = WithContentProps<typeof mapContentToProps>;

export type FieldLiveValidationErrorsPublicProps = {
    heading: string;
    items: Array<{ id: string; label: string }>;
    validationResults?: ValidationFailure<string, { validationId?: string }>[];
    shouldHighlightFailures: boolean;
    shouldShowFailures?: boolean;
    id?: string;
} & EmptyComponent;

export type FieldLiveValidationErrorsProps = FieldLiveValidationErrorsPublicProps &
    FieldLiveValidationErrorsContentProps;

const FieldLiveValidationErrors: FC<FieldLiveValidationErrorsProps> = ({
    heading,
    items,
    validationResults = [],
    shouldHighlightFailures = false,
    shouldShowFailures = false,
    id,
    validItemA11yText,
    invalidItemAllyText,
}) => {
    const hasFailureFor = (vid: string) =>
        !!validationResults.find(({ meta: { validationId } }) => validationId === vid);

    const getFieldLiveValidationScreenReaderText = (): string =>
        items
            .map(
                ({ id: validationId, label }) =>
                    `${label} ${hasFailureFor(validationId) ? invalidItemAllyText : validItemA11yText}`
            )
            .join('; ');

    return (
        <div id={id} className="has-rds-mt-8" aria-live="polite" aria-atomic>
            {shouldShowFailures && (
                <>
                    <Text
                        size="b3"
                        className={classNames('has-rds-mb-8', {
                            [classes.invalid]: shouldHighlightFailures && validationResults.length > 0,
                        })}
                    >
                        {heading}
                    </Text>
                    <Text tag="ul" size="b3" aria-hidden="true">
                        {items.map(({ id: validationId, label }) => {
                            const hasFailure = hasFailureFor(validationId);
                            return (
                                <li
                                    key={validationId}
                                    className={classNames('is-flex', classes.item, {
                                        [classes.default]: !shouldHighlightFailures && hasFailure,
                                        [classes.valid]: !hasFailure,
                                        [classes.invalid]: shouldHighlightFailures && hasFailure,
                                    })}
                                >
                                    <SvgIcon size="sm" className={classes.icon}>
                                        <circle cx="8" cy="8" r="8" fill="currentColor" />
                                        {renderDecoration(
                                            classNames({
                                                default: !shouldHighlightFailures && hasFailure,
                                                valid: !hasFailure,
                                                invalid: shouldHighlightFailures && hasFailure,
                                            }) as LiveValidationStatus
                                        )}
                                    </SvgIcon>
                                    {label}
                                </li>
                            );
                        })}
                    </Text>
                    <Debounce delay={seconds(1)}>
                        <Text className="sr-only">{getFieldLiveValidationScreenReaderText()}</Text>
                    </Debounce>
                </>
            )}
        </div>
    );
};

export { FieldLiveValidationErrors };
export default compose(withContent(mapContentToProps))(FieldLiveValidationErrors) as FC<
    Omit<FieldLiveValidationErrorsProps, keyof FieldLiveValidationErrorsContentProps>
>;
