import type { Predicate } from 'src/app/types';

const whiteSpaceRegEx = /\s/;

/**
 * Test if a string contains no whitespace.
 *
 * We have to check for non-empty string because an empty string inherently has no whitespace and will return a false
 * positive.
 *
 * @param value string value to test
 * @returns true if the string contains no whitespace, false otherwise
 */
export const hasNoWhiteSpace: Predicate<string> = value => !whiteSpaceRegEx.test(value);
