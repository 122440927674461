import { ensureArray } from 'src/utils/arr';

import type { LogLevel } from '../LogLevel';
import type { LogFn } from '../types';

/**
 * Wrap a LogFn to be conditionally called at a specified log level or above
 *
 * @param level The minimum level required to call the wrapped LogFn
 * @param log The LogFn to call
 * @returns A LogFn
 */
export const logAtLevel =
    (level: LogLevel, log: LogFn): LogFn =>
    logData => {
        ensureArray(logData).forEach(ld => {
            if (ld.level >= level) {
                log(ld);
            }
        });
    };
