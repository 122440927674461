import { TEST_ORIGIN } from 'src/utils/dev';

/**
 * Generate the Walterson Service origin from the provided origin
 *
 * @private
 * @param origin The origin to convert
 * @returns The Walterson origin
 */
export const getWaltersonApiHost = (origin: string = TEST_ORIGIN): string =>
    origin.includes('localhost')
        ? origin
        : origin
              .split('//')
              .map((a, i) => (i === 1 ? `id.${a.replace('www.', '')}` : a))
              .join('//');
