import { Predicate } from 'src/app/types';

/**
 * Test if two values are === equivalent using a multi-step function/configurable predicate
 *
 * This is basically `===` as a function so that `===` can be used as a Predicate function
 *
 * @param targetValue The value to test against
 * @returns A Predicate function that takes a single value and returns true if the values are equivalent, false if not
 */
export const isEqualTo =
    (targetValue: unknown): Predicate<any> =>
    value =>
        targetValue === value;
