// FIXME:
// eslint-disable-next-line import/no-cycle
import { keepingTrackOfAllhandleCloseModalMethods, keepingTrackOfAllMemoryRef } from '../Modal';

const numberOfModals = []; // To have the id of modals are or is opened
let dialogElement; // Getting the Modal Pop-Up
let focusableElements; // What are the focusable elements in the modal
const focusedElementBeforeOpen = []; // List of elements Before opening the Modal the element was focused

const handleKeyDown = (e, handleCloseModal, firstFocusableElement, lastFocusableElement) => {
    const KEY_TAB = 9;
    const KEY_ESC = 27;

    /* So that the Focus does not goes out of the modal */
    function handleBackwardTab() {
        if (document.activeElement === focusableElements) {
            e.preventDefault();
            lastFocusableElement.focus();
        }
    }
    function handleForwardTab() {
        if (document.activeElement === lastFocusableElement) {
            e.preventDefault();
            firstFocusableElement.focus();
        }
    }
    /* redirect last tab to first input */
    lastFocusableElement.addEventListener('keydown', ev => {
        if (ev.which === 9 && !ev.shiftKey) {
            ev.preventDefault();
            firstFocusableElement.focus();
        }
    });
    /* redirect first shift+tab to last input */
    firstFocusableElement.addEventListener('keydown', ev => {
        if (ev.which === 9 && ev.shiftKey) {
            ev.preventDefault();
            lastFocusableElement.focus();
        }
    });

    /* End */

    switch (e.keyCode) {
        case KEY_TAB:
            // Don't move focus if there's only 1 focusable element and it's currently focused
            if (focusableElements.length === 1 && document.activeElement === focusableElements[0]) {
                e.preventDefault();
                break;
            }
            if (e.shiftKey) {
                handleBackwardTab();
            } else {
                handleForwardTab();
            }
            break;
        case KEY_ESC:
            if (dialogElement === e.currentTarget) {
                // document.getElementById(dialogEl.id).style.display='none';
                // FIXME:
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                closeModal(handleCloseModal);
            }
            break;
        default:
            break;
    }
};

const ModalUtility = (refOfCurrentElement, handleCloseModal, idNotToBePushed) => {
    if (refOfCurrentElement) {
        dialogElement = refOfCurrentElement;

        // Remove the scroll of the Main - DOM
        document.body.style.setProperty('overflow', 'hidden');
        // Add aria-hidden true on the Main -DOM
        document.getElementById('root').setAttribute('style', 'display:none');
        if (!idNotToBePushed) {
            numberOfModals.push(refOfCurrentElement);
            focusedElementBeforeOpen.push(document.activeElement);
        }
    }
    // List of all the focusable elements are pushed in to the stack
    if (dialogElement)
        focusableElements = dialogElement.querySelectorAll(
            'a[href], area[href],input:not([disabled]), select:not([disabled]), textarea:not([disabled]),button:not([disabled]),[tabindex="0"]'
        );
    // Adding Array Properties
    focusableElements = Array.prototype.slice.call(focusableElements);
    const firstFocusableElement = focusableElements[0]; // First focus able element
    if (firstFocusableElement && !idNotToBePushed) {
        firstFocusableElement.focus();
    }
    const lastFocusableElement = focusableElements[focusableElements.length - 1]; //  Last element in the modal to be focused

    dialogElement.addEventListener('keydown', event => {
        handleKeyDown(event, handleCloseModal, firstFocusableElement, lastFocusableElement);
    });
};

export function closeModal(handleCloseModal) {
    if (handleCloseModal) {
        handleCloseModal();
    }
    numberOfModals.pop();
    if (numberOfModals.length > 0) {
        ModalUtility(
            numberOfModals[numberOfModals.length - 1], // the last ID
            null,
            true
        );
    }
    if (numberOfModals.length === 0) {
        // when all the Modal are closed then make the over flow of dom auto
        document.body.style.removeProperty('overflow');
        // When all modal are closed remove the attribute of aria-hidden.
        document.getElementById('root').removeAttribute('style');
    }
    if (focusedElementBeforeOpen.length > 0) {
        focusedElementBeforeOpen[focusedElementBeforeOpen.length - 1].focus(); // focus to the last active element
        focusedElementBeforeOpen.pop();
    }
    keepingTrackOfAllhandleCloseModalMethods.pop();
    keepingTrackOfAllMemoryRef.pop();
}

export default ModalUtility;
