import type { Predicate } from 'src/app/types';

/**
 * @param regex regex to test aganist
 * @returns a function which takes the string and returns
 *  false if there is a match between the regex and the string. Otherwise, true.
 */
export const createDisallowedCharactersPredicate =
    (regex: RegExp): Predicate<string> =>
    x =>
        !regex.test(x);
