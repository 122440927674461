import axios from 'axios';
import { trimSpaceMiddleware } from '../utils';
import { getWaltersonApiHost } from './utils';

import type { ServiceClient } from '../types';

const instance = axios.create({
    baseURL: getWaltersonApiHost(),
    timeout: 60000,
});

instance.defaults.withCredentials = true;
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.put['Content-Type'] = 'application/json';
instance.interceptors.request.use(trimSpaceMiddleware, error => Promise.reject(error));

export default instance as ServiceClient;
