import { isAikyamLoginEnabled, isAikyamMFAEnabled } from 'src/features/PortalData';
import { getCookie } from 'src/utils/cookieUtility';
import { TEST_ORIGIN } from 'src/utils/dev';

import type { PortalConfigData } from 'src/features/PortalData';

export const shouldPointToAikyamStage = (): boolean => getCookie('AYPROBE') === '1';

export const shouldUseAikyamLogin = (config: PortalConfigData): boolean =>
    getCookie('ALPROBE') === '1' || isAikyamLoginEnabled(config);

export const shouldPointToAikyamPilotNonProd = (): boolean => getCookie('AYPROBE') === '2';

/**
 * Generate the Aikyam Service origin from the provided origin
 *
 * @param origin The current origin
 * @returns The environment-appropriate Aikyam domain
 */
export const getAikyamDomain = (origin: string = TEST_ORIGIN): string => {
    let aikyamDomain = process.env.__AIKYAM_DOMAIN;
    // HSID stage domain points to Aikyam's nonprod domain by default or other Aikyam's domains based on the boolean value.

    if (process.env.DEPLOY_ENV === 'stage') {
        if (shouldPointToAikyamStage()) {
            aikyamDomain = process.env.__AIKYAM_STAGE_DOMAIN || aikyamDomain;
        } else if (shouldPointToAikyamPilotNonProd()) {
            aikyamDomain = process.env.__AIKYAM_PILOT_NONPROD_DOMAIN || aikyamDomain;
        }
    }

    return aikyamDomain || origin;
};

export const shouldUseAikyamURL = (config: PortalConfigData): boolean => isAikyamMFAEnabled(config);
