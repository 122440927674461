import { useRef } from 'react';
import { getTheme } from 'src/features/PortalData';
import { logger } from 'src/app/Logger';
import { attachWindowLoad } from 'src/utils/windowLoad';
import { useAppStore, useEffectOnMounted } from 'src/hooks';

interface CaipWebChatContainerProps {
    shouldLoadWebChat?: boolean;
}

export const CaipWebChatContainer: React.FC<CaipWebChatContainerProps> = ({ shouldLoadWebChat }) => {
    const webChatInitiatingRef = useRef(false);
    const { configData } = useAppStore();
    const theme = getTheme(configData);

    const initWebChat = () => {
        if (webChatInitiatingRef.current || !shouldLoadWebChat) return;

        // to ensure the 'init' function is only called once to prevent multiple invocations
        webChatInitiatingRef.current = true;

        // start to import the js
        import('src/app/WebChatUtility')
            .then(({ CaipWebChatUtility }) => {
                // initialize webchat
                CaipWebChatUtility.initialize(theme);
            })
            .catch((error: Error) => {
                logger.warn('Error in Loading WebChatUtility', { error });
            });
    };

    useEffectOnMounted(() => {
        attachWindowLoad(initWebChat)
            .then(() => {
                logger.info('Attached initWebChat to window.onload');
            })
            .catch((error: Error) => {
                logger.error('Failed to attach initWebChat to window.onload', { error });
            });
    });

    return null;
};
