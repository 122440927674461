import type { Values } from 'src/app/types';

/**
 * Enum of HSID UI features to help group logs
 *
 * This will grow organically as improved logging is added to the codebase. Entries will exist for each of the flows,
 * plus features that are opted in by portal config.
 */
export const LogFeature = {
    Login: 'Login',
    Registration: 'Registration',
    FullStepUp: 'FullStepUp',
} as const;

export type LogFeature = Values<typeof LogFeature>;
