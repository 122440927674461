/**
 * StorageRecord types
 *
 * @private
 */
export interface StorageRecordMetaField {
    [index: string]: unknown;
}

export interface StorageRecordMeta extends StorageRecordMetaField {
    createdAt: number;
    expires?: number;
}

export interface StorageRecordData {
    value: string;
    createdAt: number;
    expires?: number;
    meta?: StorageRecordMetaField;
}

export interface StorageRecordPrototype {
    update(this: StorageRecord, newFields: Partial<StorageRecordData>): StorageRecord;
    toStorage(this: StorageRecord): string;
    getMeta(this: StorageRecord): StorageRecordMeta;
}

export type StorageRecord = StorageRecordData & StorageRecordPrototype;

export interface StorageRecordFactory {
    (data: StorageRecordData): StorageRecord;
    prototype: StorageRecordPrototype;
    fromStorage(this: void, rawRecord: string): StorageRecord | null;
}

/**
 * An interface that represents the `StorageRecord` as it is persisted into storage. The keys are shortened to obscure
 * meaning to people who might snoop. Inline comments below indicate the corresponding `StorageRecord` key.
 */
export interface SerializedStorageRecord {
    // `value` key
    v: string;
    // `createdAt` key
    c: number;
    // `expires` key
    e?: number;
    // `meta` key
    m?: StorageRecordMetaField;
}

/**
 * A model factory to manage the reading in and out of an entry in storage to enable support of
 * custom functionality when working with the records.
 *
 * @note The value stored in storage has single-character keys to obscure meaning
 *
 * @private
 */
export const StorageRecord: StorageRecordFactory = ({ value, expires, createdAt, meta }) => {
    const props: PropertyDescriptorMap = {
        value: {
            value,
            enumerable: true,
        },
        createdAt: {
            value: createdAt,
            enumerable: true,
        },
    };

    if (Number.isFinite(expires)) {
        props.expires = {
            value: expires,
            enumerable: true,
        };
    }

    if (meta) {
        props.meta = {
            value: meta,
            enumerable: true,
        };
    }

    return Object.create(StorageRecord.prototype, props) as StorageRecord;
};

/**
 * Convert the raw data stored in a web storage system into a StorageRecord instances
 *
 * @param rawRecord the raw data record straight from the storage system
 * @returns a StorageRecord instance based on the retrieved data
 */
StorageRecord.fromStorage = function fromStorage(this: void, rawRecord: string): StorageRecord | null {
    const record = JSON.parse(rawRecord) as SerializedStorageRecord | null;
    if (record == null) {
        return null;
    }

    const { v: value, c: createdAt, e: expires, m: meta } = record;

    return StorageRecord({
        value,
        createdAt,
        ...(expires ? { expires } : null),
        ...(meta ? { meta } : null),
    });
};

StorageRecord.prototype = {
    /**
     * Update the values of a StorageRecord instance immutably
     *
     * @param newFields an object containing a subset of supported data keys to update
     * @returns a new StorageRecord instance with the updated data keys
     */
    update(this: StorageRecord, newFields: Partial<StorageRecordData> = {}): StorageRecord {
        return StorageRecord({
            ...this,
            ...newFields,
        });
    },
    /**
     * Get the metadata associated with a stored entry
     *
     * @returns an object with the metadata of the stored entry
     */
    getMeta(this: StorageRecord): StorageRecordMeta {
        const { expires, createdAt, meta } = this;
        return {
            ...meta,
            createdAt,
            ...(expires ? { expires } : null),
        };
    },
    /**
     * Convert the StorageRecord into a format ready to be persisted in web storage
     *
     * @returns the stringified instance
     */
    toStorage(this: StorageRecord): string {
        const { value: v, expires: e, createdAt: c, meta: m } = this;
        const record = { v, c, ...(e ? { e } : null), ...(m ? { m } : null) };
        return JSON.stringify(record);
    },
};
