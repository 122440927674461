import { useRef } from 'react';
import { logger } from 'src/app/Logger';
import { attachWindowLoad } from 'src/utils/windowLoad';
import { loadScript } from 'src/utils/html';
import { useEffectOnMounted } from 'src/hooks';

interface LivePersonContainerProps {
    shouldLoadWebChat?: boolean;
}

export const LivePersonChatContainer: React.FC<LivePersonContainerProps> = ({ shouldLoadWebChat }) => {
    const livePersonChatInitiatingRef = useRef(false);
    const livePersonScriptUrl = process.env.__LIVE_PERSON_WEB_CHAT;

    const initLivePersonWebChat = () => {
        if (livePersonChatInitiatingRef.current || !shouldLoadWebChat) return;

        // to ensure the 'init' function is only called once to prevent multiple invocations
        livePersonChatInitiatingRef.current = true;

        // start to import the js
        loadScript(livePersonScriptUrl)
            .then(() => {
                logger.info(`Live Person Chat Script Loaded`);
            })
            .catch((error: Error) => {
                logger.warn('Error in Loading LivePerson WebChat', { error });
            });
    };

    useEffectOnMounted(() => {
        attachWindowLoad(initLivePersonWebChat)
            .then(() => {
                logger.info('Attached initLivePersonWebChat to window.onload');
            })
            .catch((error: Error) => {
                logger.error('Failed to attach initLivePersonWebChat to window.onload', { error });
            });
    });

    return null;
};
