import { ComponentState } from 'src/app/Constants';

import type { ComponentStates } from 'src/app/Constants';

export interface Field {
    value: string;
    error: string;
    currentState: ComponentStates;
}

const FieldConfig = ({
    value = '',
    error = '',
    currentState = ComponentState.DEFAULT,
}: Partial<Field> = {}): Field => ({
    value,
    error,
    currentState,
});

export default FieldConfig;
