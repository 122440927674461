import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';

import classes from './ButtonGroup.module.scss';

export interface ButtonGroupProps {
    children: ReactNode;
    layout?: 'responsive' | 'vertical' | 'horizontal';
    className?: string;
}

const ButtonGroup: FC<ButtonGroupProps> = ({ children, layout = 'vertical', className }) => (
    <div
        className={classNames(classes.root, className, {
            [classes.layoutResponsive]: layout === 'responsive',
            [classes.layoutHorizontal]: layout === 'horizontal',
            [classes.layoutVertical]: layout === 'vertical',
        })}
    >
        {children}
    </div>
);

export default ButtonGroup;
