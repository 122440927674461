import { AttestationResponse } from 'src/service/Walterson/OBAService/types';
import axios from 'axios';
import noop from 'src/utils/noop';

import type { AxiosError } from 'axios';
import type { Authenticator, Challenge, OBAMeta, QuestionChallenge } from './types';

export const getErrorCode = (meta?: OBAMeta): string => meta?.errors?.[0].error ?? '';
export const getErrorDescription = (meta?: OBAMeta): string => meta?.errors?.[0].errorDescription ?? '';

export const getAuthenticatorMethodEnsue = (authenticator?: Authenticator): string =>
    authenticator?.methods[0].ensue ?? '';

export const isQuestionChallenge = (challenge: Challenge): challenge is QuestionChallenge =>
    (challenge as QuestionChallenge).question !== undefined;

export const getQuestionChallenges = (challenges: Challenge[]): QuestionChallenge[] =>
    challenges.filter(isQuestionChallenge);

export const hasQuestionChallenges = (challenges: Challenge[]): boolean => getQuestionChallenges(challenges).length > 0;

export const handleAttestationServiceError =
    ({
        onVerificationFailed = noop,
        onGeneralError = noop,
        onLockOut = noop,
        onExpiredToken = noop,
    }: {
        onVerificationFailed?: (error: AxiosError<AttestationResponse>) => void;
        onLockOut?: (error: AxiosError<AttestationResponse>) => void;
        onExpiredToken?: (error: AxiosError<AttestationResponse>) => void;
        onGeneralError?: (error: Error | AxiosError<AttestationResponse>) => void;
    } = {}) =>
    (error: Error | AxiosError<AttestationResponse>): void => {
        if (axios.isAxiosError(error) && error.response?.data?.meta) {
            const errorCode = getErrorCode(error.response?.data?.meta);
            switch (errorCode) {
                case 'verification_failed':
                    onVerificationFailed(error);
                    break;
                case 'session_invalid':
                    onExpiredToken(error);
                    break;
                case 'oba_locked':
                    onLockOut(error);
                    break;
                default:
                    onGeneralError(error);
            }
        } else {
            onGeneralError(error);
        }
    };
