import React, { FC } from 'react';
import type { MutableRefObject, MouseEventHandler } from 'react';
import Modal from '../Modal';
import classes from './IframeModal.module.scss';
import { getModalAnalyticsData } from '../utils';

export type IframeModalProps = {
    onClose: MouseEventHandler<HTMLButtonElement>;
    modalRef: MutableRefObject<HTMLDivElement>;
    id: string;
    header: string;
    pageName: string;
    src: string;
};

const IframeModal: FC<IframeModalProps> = ({ onClose, modalRef, id, header, pageName, src }) => (
    <Modal
        handleCloseModal={onClose}
        modalRef={modalRef}
        id={id}
        header={header}
        analyticsData={getModalAnalyticsData(pageName)}
        containContent={false}
    >
        <iframe title={header} width="100%" className={classes.iframe} referrerPolicy="origin" src={src} sandbox="" />
    </Modal>
);

export default IframeModal;
