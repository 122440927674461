/**
 * The PortalData feature
 *
 * All code in this feature pertains to Portal Data: Content and Config:
 * - `PortalDataService.ext` is the API to fetch and load Portal Data
 * - `types.ts` contains types pertaining to Portal Data
 * - `config/` contains code specific to Portal Config
 *   - `PortalConfigData.ext` Defines the complete Portal Config Data structure as a type and constructor
 *   - `selectors.ext` contains selector functions that accept the Config object and return values based on the Config
 * - `content/` contains code specific to Portal Content
 *   - `types.ext` contains types for portal Content
 *   - `fixtures.ext` contains non-production utilities to generate test content data
 *
 * @note The code in here will be reorganized into `content` and `config` folders and then will be
 * re-exported from the top-level export files
 */

export { default as PortalDataService } from './PortalDataService';
export { PortalConfigData } from './config/PortalConfigData';
export * from './config/selectors';
export { portalConfigRegExpSettingToRegExp } from './config/utils';
