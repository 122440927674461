// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntlPhoneField_phoneNumberContainer__FJf0\\+ > * + * {\n  margin-left: 16px;\n  flex: 0 0 25%;\n}\n.IntlPhoneField_phoneNumberContainer__FJf0\\+ > :last-child {\n  flex: 1 1 auto;\n}", "",{"version":3,"sources":["webpack://./src/UI/Form/IntlPhoneField.module.scss"],"names":[],"mappings":"AAEI;EACI,iBAAA;EACA,aAAA;AADR;AAKI;EACI,cAAA;AAHR","sourcesContent":[".phoneNumberContainer {\n    // country code\n    > * + * {\n        margin-left: 16px;\n        flex: 0 0 25%;\n    }\n\n    // phone number\n    > :last-child {\n        flex: 1 1 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"phoneNumberContainer": "IntlPhoneField_phoneNumberContainer__FJf0+"
};
export default ___CSS_LOADER_EXPORT___;
