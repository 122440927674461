import React from 'react';

import type { FC, ReactElement, ReactNodeArray } from 'react';

type FormProps = JSX.IntrinsicElements['form'] & {
    /**
     * @note `id` prop is required for forms for QA scripting purposes
     */
    id: string;
    children: ReactElement | ReactNodeArray;
};

/**
 * The Form component is a wrapper around the native <form> element and provides additional super powers or requirements
 * for HSID forms.
 */
const Form: FC<FormProps> = ({ id, children, ...props }) => (
    <form id={id} {...props}>
        {children}
    </form>
);

export default Form;
