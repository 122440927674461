import React, { useRef } from 'react';
import classNames from 'classnames';
import deepEqual from 'fast-deep-equal';
import { IMaskInput, IMask } from 'react-imask';

import noop from 'src/utils/noop';
import { useToggleState, useComponentApiDef, useEffectOnMounted } from 'src/hooks';

import { withContent } from 'src/components/ContentProvider';
import format from 'src/utils/format';
import compose from 'src/utils/compose';
import { CheckIcon } from 'src/UI/Icon';
import { noopValidator, ValidationFailure } from 'src/utils/validation';
import { useMilestones } from 'src/hooks/useMilestones';

import type { FC, ReactElement } from 'react';
import type { EmptyComponent } from 'src/app/types';
import type { PortalContentData } from 'src/features/PortalData/types';
import type { ComponentApi } from 'src/hooks';

import FieldLabel from './FieldLabel';
import {
    useFieldState,
    FieldState,
    doesFieldHaveValue,
    getFieldValue,
    isFieldTouched,
    isFieldValid,
    getFieldErrors,
} from './hooks/useFieldState';
import { CrossButton, EyeButton } from '../Button';
import { defaultRenderError, getFieldErrorId, getFieldValidId, triggerChangeEvent } from './utils';
import classes from './InputField.module.scss';

import type { RenderErrorData } from './utils';
import type { BaseFormFieldProps } from './types';

const SSN_MASK = '00-0000';

const applySsnMask = (value: string): string => IMask.pipe(value, { mask: SSN_MASK }) as string;

const applySsnPasswordMask = (value: string): string => IMask.pipe(value, { mask: '000000' }) as string;

/**
 * Define this component's public API type
 */
export interface SsnFieldApi extends ComponentApi {
    setFieldValue: (newValue: string) => void;
    focus: () => void;
    setFieldErrors: (errors: FieldState['errors']) => void;
    setFieldError: (error: string | FieldState['errors'][number]) => void;
}

type SsnFieldPublicApiProps<ErrorData = string> = {
    /**
     * Controls how the error should be constructed from the FieldState and fieldId. This is how to control the
     * complete appearance of the field's error message. By default, it shows the first validation error as a FieldError
     *
     * @var renderError
     */
    renderError?: (errorData: RenderErrorData) => ReactElement | null;
    // Dev props
    devShowValid?: boolean;
} & BaseFormFieldProps<HTMLInputElement, ErrorData> &
    EmptyComponent;

interface SsnFieldContentProps {
    validMessage: string;
}

const mapContentToProps = (
    { LblInputValid }: PortalContentData,
    { label }: SsnFieldPublicApiProps
): SsnFieldContentProps => ({
    validMessage: format(LblInputValid, {
        FIELD_NAME: label,
    }),
});

export type SsnFieldProps = SsnFieldPublicApiProps & SsnFieldContentProps;

/**
 * The SsnField is the base component for text-style <input> elements (e.g. `type`= text, password, url, email, tel).
 */
const SsnField: FC<SsnFieldProps> = ({
    name,
    label,
    defaultValue = '', // accepts value in the 00-00000 format
    labelVariant = 'above',
    id,
    wasFormSubmitted = false,
    validate = noopValidator as NonNullable<SsnFieldPublicApiProps<typeof defaultValue>['validate']>,
    onChange = noop,
    onValueChange = noop,
    onFocus = noop,
    onBlur = noop,
    onValidityChange = noop,
    required = false,
    api: setApi,
    renderError = defaultRenderError,
    // Dev props
    devShowValid = false,
    'aria-describedby': describedBy,
}) => {
    const fieldId = id ?? name;
    const fieldErrorId = getFieldErrorId(fieldId);
    const fieldValidId = getFieldValidId(fieldId);

    const fieldRef = useRef<HTMLInputElement>(null);
    const fieldControlFocusedRef = useRef<boolean>(false);
    const [fieldState, { setFieldValue, setFieldErrors, clearFieldErrors, fieldWasTouched }] = useFieldState(
        FieldState({ value: defaultValue })
    );
    // Determining whether the field has been "touched" involves tracking multiple pieces of stateful data so we use
    // milestones to track those different goals until we get to the field being "touched."
    const [{ blurred: fieldWasBlurred, changed: fieldWasChanged }] = useMilestones(
        { blurred: true, changed: true },
        () => {
            fieldWasTouched();
        }
    );
    // State to track when the `<input>` element itself and its wrapper are currently focused to control visibility of
    // field controls
    const [isFieldElFocused, { on: focusFieldEl, off: blurFieldEl }] = useToggleState(false);
    const [isFieldWrapperFocused, { on: focusFieldWrapper, off: blurFieldWrapper }] = useToggleState(false);

    const [shouldDisplaySsn, { toggle: toggleSsnVisibility }] = useToggleState(false);

    const shouldShowFieldControls = isFieldWrapperFocused && doesFieldHaveValue(fieldState);

    const isValid = devShowValid;

    const commonProps = {
        name,
        id: fieldId,
        type: shouldDisplaySsn ? 'text' : 'password',
        placeholder: shouldDisplaySsn ? SSN_MASK.replaceAll('0', '_') : '',
        className: classNames(classes.field, 'has-rds-pv-8 has-rds-ph-16'),
        inputMode: 'numeric' as const,
        'aria-required': required,
        'aria-describedby': classNames(fieldErrorId, {
            [fieldValidId]: isValid,
            ...(typeof describedBy === 'string' ? { [describedBy]: true } : {}),
        }),
        value: shouldDisplaySsn ? getFieldValue(fieldState) : applySsnPasswordMask(getFieldValue(fieldState)),
        onFocus: (ev: React.FocusEvent<HTMLInputElement>) => {
            focusFieldWrapper();
            focusFieldEl();
            onFocus(ev);
        },
        onBlur: (ev: React.FocusEvent<HTMLInputElement>) => {
            if (!(fieldControlFocusedRef.current || ev.currentTarget?.parentElement?.contains(ev.relatedTarget))) {
                blurFieldWrapper();
            }

            fieldControlFocusedRef.current = false;
            fieldWasBlurred();
            blurFieldEl();
            onBlur(ev);
        },
    };

    const checkValidationState = (newValue: string) => {
        // Updated value validation
        const validationFailures = validate(newValue);
        // Check to see if the validation state is different from the last change
        if (!deepEqual(getFieldErrors(fieldState), validationFailures)) {
            if (validationFailures.length === 0) {
                // If there's no validation failure, clear the error
                clearFieldErrors();
                onValidityChange([], false);
            } else {
                // If the validation failure has changed, set that and broadcast up
                // setFieldError(firstError);
                setFieldErrors(validationFailures);
                onValidityChange(validationFailures, false);
            }
        }
    };

    useComponentApiDef<SsnFieldApi>(
        {
            setFieldValue(newValue) {
                // Programmatically trigger a change event instead of setting the state manually in order to trigger all
                // the validation and internal state updates that way. Any parent components tracking the field value
                // should call this API function and update their state through the `onChange` prop handler rather than
                // calling this AND setting their state at the same time. This maintains unidirectional flow.
                if (fieldRef.current) {
                    triggerChangeEvent(fieldRef.current, newValue);
                }
            },
            focus() {
                (fieldRef.current as HTMLInputElement).focus();
            },
            setFieldError(error) {
                const newError =
                    typeof error === 'string'
                        ? ValidationFailure({ reason: error, meta: { field: name, label } })
                        : error;
                setFieldErrors([newError]);
            },
            setFieldErrors(validationFailures) {
                setFieldErrors(validationFailures);
            },
        },
        setApi,
        name
    );

    useEffectOnMounted(() => {
        // Handle initial validation
        const initialValidationFailures = validate(getFieldValue(fieldState));

        // If we got a validation failure from initial validation, set it and communicate up
        if (initialValidationFailures.length > 0) {
            setFieldErrors(initialValidationFailures);
            onValidityChange(initialValidationFailures, true);
        }
    });

    const handleSSNControlFocus = () => {
        fieldControlFocusedRef.current = true;
    };

    return (
        <div id={`${fieldId}_container`}>
            <div className="has-rds-mb-8">
                <FieldLabel fieldId={fieldId} className={classes.label} isHidden={labelVariant === 'hidden'}>
                    {label}
                </FieldLabel>
            </div>

            <div
                className={classNames(classes.fieldWrapper, 'is-flex', {
                    [classes.isFocused]: isFieldElFocused,
                    [classes.isError]:
                        !isFieldElFocused &&
                        (wasFormSubmitted || isFieldTouched(fieldState)) &&
                        !isFieldValid(fieldState),
                })}
            >
                {shouldDisplaySsn ? (
                    <IMaskInput
                        mask={SSN_MASK}
                        onAccept={value => {
                            const newValue = value as string;
                            if (newValue === getFieldValue(fieldState)) return;
                            setFieldValue(newValue);
                            checkValidationState(newValue);
                            fieldWasChanged();
                            // TODO Figure out how to take the InputEvent that is the third arg for onAccept and make it consistent with the onValueChange and onChange handlers that receive the ChangeEvent
                            onValueChange(newValue, name);
                        }}
                        inputRef={fieldRef}
                        {...commonProps}
                    />
                ) : (
                    <input
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                            const { value: newValue, name: fieldName } = ev.target;
                            const ssnValue = applySsnMask(newValue);
                            if (ssnValue === getFieldValue(fieldState)) return;
                            setFieldValue(ssnValue);
                            checkValidationState(ssnValue);
                            fieldWasChanged();
                            onValueChange(ssnValue, fieldName, ev);
                            onChange(ev);
                        }}
                        ref={fieldRef}
                        {...commonProps}
                    />
                )}

                {shouldShowFieldControls ? (
                    <div className={`${classes.fieldControls} is-flex has-rds-pr-16`}>
                        <span className={`${classes.fieldControlWrapper} is-flex`}>
                            <CrossButton
                                id={`${fieldId}-clear-action`}
                                label={label}
                                onMouseDown={handleSSNControlFocus}
                                onClick={() => {
                                    if (fieldRef.current) {
                                        triggerChangeEvent(fieldRef.current, '');
                                        fieldRef.current.focus();
                                    }
                                }}
                            />
                        </span>
                        <span className={`${classes.fieldControlWrapper} is-flex`}>
                            <EyeButton
                                isToggled={shouldDisplaySsn}
                                label={label}
                                onMouseDown={handleSSNControlFocus}
                                onClick={() => {
                                    toggleSsnVisibility();
                                }}
                            />
                        </span>
                    </div>
                ) : null}
                <div aria-live="polite" className={classes.validMessage}>
                    {isValid ? (
                        <div id={fieldValidId}>
                            <CheckIcon fill="green" />

                            <span className="sr-only">{`${label} is valid`}</span>
                        </div>
                    ) : null}
                </div>
            </div>

            <div id={fieldErrorId}>{renderError({ fieldState, fieldId, wasFormSubmitted })}</div>
        </div>
    );
};

export { SsnField };
// TODO Remove manual typing when `compose` and HOC type modification code is in place
export default compose(withContent(mapContentToProps))(SsnField) as FC<Omit<SsnFieldProps, keyof SsnFieldContentProps>>;
