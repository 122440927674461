import type { PlainObject } from 'src/app/types';

/**
 * Private utilities for use inside of `utils/html`. Functions in this file should not be re-exported for public use. If
 * one needs to be used outside of `utils/html`, move it to its own file and re-export from there.
 *
 * @private
 */

/**
 * Shorthand function to create a TextNode
 */
export const textNode = (text: string): Text => document.createTextNode(text);

/**
 * Shorthand function to create a DOM node based on tag name, optional object of attributes, and any number of children.
 */
export const createElement = (
    tag: keyof JSX.IntrinsicElements,
    attributes: PlainObject = {},
    ...children: Array<Element | string>
): HTMLElement => {
    const el = document.createElement(tag);
    Object.entries(attributes).forEach(([attrName, attrValue]) => {
        el.setAttribute(attrName, attrValue);
    });
    children.forEach(c => {
        const child = typeof c === 'string' ? textNode(c) : c;
        el.appendChild(child);
    });
    return el;
};

/**
 * Standardizes wrapping an HTML string in a DOM fragment to allow for manipulation.
 * @param html HTML string to modify
 * @param modify Callback function that is given the fragment element to perform modifications on. It should not return
 *               anything since the argument is expected to be mutated.
 * @returns Modified HTML string
 */
export const modifyHtml = (html: string, modify: (wrapper: HTMLDivElement) => void): string => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = html;
    modify(wrapper);
    return wrapper.innerHTML;
};
