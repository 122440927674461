// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CloseButton_button__GIQc\\+ {\n  box-sizing: content-box;\n  height: 40px;\n  width: 40px;\n  background-color: #fff;\n  display: block;\n  border: 1px solid #808080;\n  border-radius: 50em;\n  color: #196ecf;\n}\n.CloseButton_button__GIQc\\+:hover {\n  background-color: #f3f3f3;\n}\n.CloseButton_button__GIQc\\+:focus {\n  background-color: #fff;\n}\n.CloseButton_button__GIQc\\+:active {\n  box-shadow: 0 0 0 4px #f3f3f3;\n}\n\n.CloseButton_icon__YnRsb {\n  outline: none;\n  padding: 12px;\n}", "",{"version":3,"sources":["webpack://./src/UI/Modal/CloseButton/CloseButton.module.scss"],"names":[],"mappings":"AAGA;EACI,uBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;EACA,cAAA;EACA,yBAAA;EACA,mBAAA;EACA,cAAA;AAFJ;AAII;EACI,yBAAA;AAFR;AAKI;EACI,sBAAA;AAHR;AAMI;EACI,6BAAA;AAJR;;AAQA;EACI,aAAA;EACA,aAAA;AALJ","sourcesContent":["@import 'src/sass/uhc/tokens/uhc.scss';\n@import 'src/sass/helpers/mixins';\n\n.button {\n    box-sizing: content-box;\n    height: 40px;\n    width: 40px;\n    background-color: #fff;\n    display: block;\n    border: 1px solid $rds-color-neutral-50;\n    border-radius: 50em;\n    color: #196ecf;\n\n    &:hover {\n        background-color: #f3f3f3;\n    }\n\n    &:focus {\n        background-color: #fff;\n    }\n\n    &:active {\n        box-shadow: 0 0 0 4px #f3f3f3;\n    }\n}\n\n.icon {\n    outline: none;\n    padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "CloseButton_button__GIQc+",
	"icon": "CloseButton_icon__YnRsb"
};
export default ___CSS_LOADER_EXPORT___;
