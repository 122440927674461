import HSIDService from 'src/service/HSIDService';
import { CrossStorage } from 'src/app/Constants';
import { CrossStorageClient } from 'cross-storage';
import { logger } from 'src/app/Logger';
import { AikyamClient } from 'src/features/Clients';

const SigninServiceLinks = {
    GET_USERID: '/protected/userid/v2',
    LOGIN: '/hsid/login/authenticate',
    TMX: '/protected/tmx/info',
    AIKYAM_TMX: '/rest/protected/tmx/info',
    EMAIL_CONFIRM_AND_TOKEN_VERIFY: '/protected/account/email/confirmation',
    PRE_AUTH: '/protected/login/preAuth',
    HSID_PING_URL: '/hsid/pingurl',
    HSID_RBA_URL: '/hsid/rbaurl',
};
const SignInService = {
    getUserId(userInput) {
        // let getUserIdLink = "/protected/userid/v2";

        const postData = {
            email: userInput,
            updateCurrentInput: false,
        };

        return HSIDService.post(SigninServiceLinks.GET_USERID, postData)
            .then(response => response)
            .catch(error => {
                throw error;
            });
    },
    /**
     * 200 with {code: 1000} when user logs in successfully
     * 500 with {code: 2000} when request is missing username, portal, or password
     * 500 with {code: 2001} when user is not found
     * 500 with {code: 2002} when user has hsid lock
     * 500 with {code: 2003} when user has rba lock
     * 500 with {code: 2004} when user has disabled hsid
     * 500 with {code: 2005} when user needs to update terms
     */
    login(portalBrand, username, password, tmxSessionToken, rememberMe, termsUpdated, lang, targetUrl) {
        const postData = {
            username,
            password,
            tmxSessionToken,
            rememberMe,
            portalBrand,
            language: lang,
            domainUrl: window.location.origin,
            termsUpdated,
            // Only set the `targetUrl` field if we have one
            ...(targetUrl ? { targetUrl } : null),
        };
        return HSIDService.post(SigninServiceLinks.LOGIN, postData, {
            headers: {
                Accept: 'application/json',
            },
        })
            .then(response => response)
            .catch(error => {
                throw error.response;
            });
    },

    getTMXProfileInfo(request, useAikyamEndpoint = false) {
        return (useAikyamEndpoint ? AikyamClient : HSIDService)
            .get(useAikyamEndpoint ? SigninServiceLinks.AIKYAM_TMX : SigninServiceLinks.TMX, { params: request })
            .then(response => response)
            .catch(error => {
                throw error;
            });
    },

    getUsername() {
        const crossStorageClient = new CrossStorageClient(process.env.__CROSS_STORAGE_URL, {
            frameId: process.env.__CROSS_STORAGE_FRAME_ID,
        });
        return crossStorageClient
            .onConnect()
            .then(() => crossStorageClient.get(CrossStorage.UNAMEKEY))
            .then(res => {
                if (res) {
                    const username = window.atob(window.atob(res));
                    return username;
                }
                return '';
            })
            .catch(error => {
                logger.warn('Failed to retrieve remembered username', { error });
                return '';
            });
    },

    storeUserData(username) {
        const crossStorageClient = new CrossStorageClient(process.env.__CROSS_STORAGE_URL, {
            frameId: process.env.__CROSS_STORAGE_FRAME_ID,
        });
        return crossStorageClient
            .onConnect()
            .then(() => {
                const encUsername = window.btoa(window.btoa(username));

                return crossStorageClient.set(CrossStorage.UNAMEKEY, encUsername);
            })
            .catch(error => {
                logger.warn('Failed to store remembered username', { error });
            });
    },

    clearUserData() {
        const crossStorageClient = new CrossStorageClient(process.env.__CROSS_STORAGE_URL, {
            frameId: process.env.__CROSS_STORAGE_FRAME_ID,
        });
        return crossStorageClient
            .onConnect()
            .then(() => crossStorageClient.del(CrossStorage.UNAMEKEY))
            .catch(error => {
                logger.warn('Failed to clear remembered username', { error });
            });
    },

    sendConfirmationMailAndVerifyToken(request) {
        return HSIDService.post(SigninServiceLinks.EMAIL_CONFIRM_AND_TOKEN_VERIFY, request)
            .then(response => response)
            .catch(error => {
                throw error;
            });
    },
    loginPreAuth(postData) {
        return HSIDService.post(SigninServiceLinks.PRE_AUTH, postData)
            .then(response => response)
            .catch(error => {
                throw error;
            });
    },
    hsidPingUrl(postData) {
        return HSIDService.post(SigninServiceLinks.HSID_PING_URL, postData)
            .then(response => response)
            .catch(error => {
                throw error;
            });
    },
    hsidRbaUrl(params) {
        return HSIDService.get(SigninServiceLinks.HSID_RBA_URL, { params }).then(response => response.data || {});
    },
    sendLDAPErrorEmail(request) {
        return HSIDService.get('/protected/notify/ldap/lockedEmail', { params: request })
            .then(response => response)
            .catch(error => {
                throw error;
            });
    },
};
export default SignInService;
