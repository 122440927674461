import { AemDataAttributes, FlowName } from 'src/app/Constants';
import { buildHsidPath } from 'src/utils/urlUtility';

import type { SupportedLanguageCode } from 'src/utils/lang';

import { modifyHtml } from './utils';

/**
 * Rewrite navigation content links a[data-goto-flow] for proper functionality and accessibility
 *
 * @note Written to be a multi-step function to work with `compose()` and `pipe()`
 */
export const rewriteContentNavigationLinks =
    (portalBrand: string, lang: SupportedLanguageCode) =>
    (html: string): string =>
        modifyHtml(html, wrapper => {
            wrapper.querySelectorAll<HTMLAnchorElement>(`a[${AemDataAttributes.GOTO_FLOW}]`).forEach(element => {
                // Because the `querySelectorAll` matched this element, we can assume `gotoFlow` will not be undefined
                const flowName = element.dataset.gotoFlow as FlowName;
                element.setAttribute(
                    'href',
                    buildHsidPath({
                        includeBase: true,
                        flowName,
                        portalBrand,
                        lang,
                    })
                );
            });
        });
