import { createContext } from 'react';

import type { EmptyObject } from 'src/app/types';
import type { PortalConfigData, RawPortalContentData } from 'src/features/PortalData/types';
import type { SupportedLanguageCode } from 'src/utils/lang';
import type { ILogger } from 'src/app/Logger/types';

export interface AppStoreContext {
    AEMData: RawPortalContentData;
    portalBrand: string;
    lang: SupportedLanguageCode;
    configData: PortalConfigData;
    isPingAccessEnabled: boolean;
    logger: ILogger;
}

// This gives out 2 properties { PROVIDER, CONSUMER }
const AppStore = createContext<AppStoreContext | EmptyObject>({});

export default AppStore;
