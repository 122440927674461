import React, { ReactElement } from 'react';
import classNames from 'classnames';

import type { ElementType, ReactNode } from 'react';

import HtmlContent from 'src/UI/HtmlContent/HtmlContent';

import type { ForwardedRef } from 'src/app/types';

const sizePropToRdsClassMap = {
    h6: 'is-rds-h6',
    b1: 'is-rds-body-1',
    b2: 'is-rds-body-2',
    b3: 'is-rds-body-3',
    small: 'is-rds-sm',
    micro: 'is-rds-micro',
};

// For limiting the tags a Text component can be
type ValidElements = Pick<
    JSX.IntrinsicElements,
    | 'p'
    | 'ul'
    | 'ol'
    | 'li'
    | 'dl'
    | 'dt'
    | 'dd'
    | 'div'
    | 'span'
    | 'label'
    | 'section'
    | 'article'
    | 'header'
    | 'footer'
>;
export type TextSize = keyof typeof sizePropToRdsClassMap;
interface BaseProps<C extends ElementType> {
    id?: string;
    children: ReactNode;
    size?: TextSize;
    tag?: C;
    html?: boolean | 'inline' | 'block';
}
// Creating two subtypes to prevent users from passing anything other than a string to innerHTML
interface HtmlProps<C extends ElementType> extends BaseProps<C> {
    html: true | 'inline' | 'block';
    children: string;
}
interface NonHtmlProps<C extends ElementType> extends BaseProps<C> {
    html?: false;
    children: ReactNode;
}
type Props<C extends ElementType> = HtmlProps<C> | NonHtmlProps<C>;

export type TextProps<C extends ElementType = 'p'> = Props<C> & Omit<React.ComponentPropsWithRef<C>, keyof Props<C>>;

// React.forwardRef cannot pass generics down to the component it renders, so we must split type the forwardRef and inner component separately
const TextInner = <C extends keyof ValidElements = 'p'>(
    { id, children, size = 'b3', tag, className, html = false, ...props }: TextProps<C>,
    ref?: ForwardedRef<C>
): ReactElement => {
    const rootClasses = classNames(sizePropToRdsClassMap[size], className);
    const TagName = tag || ('p' as ElementType);

    return (
        <TagName {...props} id={id} className={rootClasses} ref={ref}>
            {html && typeof children === 'string' ? (
                <HtmlContent content={children} block={html === 'block'} />
            ) : (
                children
            )}
        </TagName>
    );
};

TextInner.displayName = 'Text';

// This provides the forwardRef and asserts that Text is called with the inner component's signature (and allows the generics to work when we use the forwardRef component)
const Text = React.forwardRef(TextInner) as typeof TextInner;

export default Text;
