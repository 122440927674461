import { AemDataAttributes } from 'src/app/Constants';

import { modifyHtml } from './utils';

/**
 * Rewrite modal content links a[data-show-modal] for proper functionality and accessibility
 *
 * @note This rewritten HTML requires manual wiring of Spacebar and Enter/Return key activation for keyboard navigation.
 * Don't try this at home kids.
 * Links may have dual functionality depending on if they are clicked within a native app or web app (i.e. link may display in either a new tab or an iframe modal). We use isNativeApp and href as heuristics to determine whether or not to update link attributes.
 */
export const rewriteContentModalLinks =
    (isNativeApp = false) =>
    (html: string): string =>
        modifyHtml(html, wrapper => {
            wrapper.querySelectorAll<HTMLAnchorElement>(`a[${AemDataAttributes.SHOW_MODAL}]`).forEach(element => {
                const href = element.getAttribute('href') || '#';
                if (isNativeApp || ['#', '#!'].includes(href)) {
                    // Make it register as a button for AT
                    element.setAttribute('role', 'button');
                    // Have to remove the useless href and target attribute
                    element.removeAttribute('href');
                    element.removeAttribute('target');
                    // Without href, the <a> loses its focusability so we need to re-add that
                    element.setAttribute('tabindex', '0');
                }
            });
        });
