import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';

import SvgIcon from './SvgIcon';

export interface EditIconProps extends EmptyComponent {
    className?: string;
    alt?: string;
}

const EditIcon: FC<EditIconProps> = ({ className }) => (
    <SvgIcon size="sm" className={className}>
        <g fill="none" transform="translate(-4 -3)">
            <mask id="a" fill="#FFF">
                <path d="M14.78 6.892s-.678.676-.68.674L11.915 5.38l.675-.675 2.19 2.188Zm-4.946 4.945.002.003-1.574 1.573-2.19-2.192 1.572-1.572 3.513-3.514 2.19 2.19-3.513 3.512ZM5.11 12.886l.247-.76 1.996 1.998-2.978 1.018.368-1.128.367-1.128Z" />
            </mask>
            <g fill="#196ECF" mask="url(#a)">
                <path d="M0 0h20.314v20.314H0z" />
            </g>
        </g>
    </SvgIcon>
);

export default EditIcon;
