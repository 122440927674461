import React from 'react';
import { CalendarIcon } from 'src/UI/Icon';
import { withContent } from 'src/components/ContentProvider';
import noop from 'src/utils/noop';
import compose from 'src/utils/compose';
import type { WithContentProps } from 'src/components/ContentProvider';

import type { EmptyComponent } from 'src/app/types';
import type { FC, MouseEventHandler } from 'react';
import Button from './Button';

const mapContentToProps = {
    toggleCalendarButtonLabel: 'LblToggleCalendarWidget',
};

type CalendarButtonContentProps = WithContentProps<typeof mapContentToProps>;

interface CalendarButtonPublicApiProps extends EmptyComponent {
    id?: string;
    label: string;
    onClick?: MouseEventHandler;
}
export type CalendarButtonProps = CalendarButtonPublicApiProps & CalendarButtonContentProps;

const CalendarButton: FC<CalendarButtonProps> = ({ id, onClick = noop, toggleCalendarButtonLabel }) => (
    <Button variant="icon" id={id} clicked={onClick} aria-label={toggleCalendarButtonLabel}>
        <CalendarIcon />
    </Button>
);

export { CalendarButton };
export default compose(withContent(mapContentToProps))(CalendarButton) as FC<
    Omit<CalendarButtonProps, keyof CalendarButtonContentProps>
>;
