import { EmptyObject } from 'src/app/types';

const isEmptyObject = (x: unknown): x is EmptyObject => {
    if (x == null) {
        return false;
    }

    return (
        Object.prototype.toString.call(x) === '[object Object]' &&
        Object.keys(x as Record<string, unknown>).length === 0
    );
};

export default isEmptyObject;
