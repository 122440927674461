import type { PortalConfigRegExpSetting } from './PortalConfigData';

/**
 * Convert a RegExp setting from Portal Config into a RegExp
 *
 * @note Any RegExp special characters that should be matched literally must be manually escaped in the first tuple slot
 *
 * @param setting A string-based configuration for a RegExp
 * @returns A RegExp
 */
export const portalConfigRegExpSettingToRegExp = (setting: PortalConfigRegExpSetting): RegExp =>
    new RegExp(setting[0], setting[1]);
