import type { PortalContentKey, RawPortalContentData } from 'src/features/PortalData/types';

/**
 * Convert all {{...}} delimited placeholders in a string with matching keys from an object
 *
 * @param str A string with {{...}} delimited placeholders
 * @param content A content object with matching keys to use for replacement
 * @return The string with all possible placeholders replaced. Any missing content keys will remain as-is
 */
const replacePlaceholders = (str: string, content: RawPortalContentData): string =>
    str.replace(/{{([A-Za-z]+)}}/g, (match: string, p1: PortalContentKey) =>
        Object.prototype.hasOwnProperty.call(content, p1) ? content[p1] : match
    );

export default replacePlaceholders;
