import React from 'react';
import type { FC } from 'react';
import { prepend } from 'src/utils/prepend';
import Title from 'src/UI/Title/Title';
import type { TitleProps } from 'src/UI/Title/Title';

import classes from './SampleCards.module.scss';
import type { SampleCardsData, SampleCardDataEntry, SampleCardLegendEntry } from './types';

export interface SampleCardsProps {
    cards: SampleCardsData;
    headingLevel?: Exclude<TitleProps['headingLevel'], 1 | 2>;
    imgHost: string;
    canShowMultipleColumns?: boolean;
    showSpecificCards?: number[];
}

const getSampleCardsToDisplay = (
    cards: SampleCardsProps['cards'],
    showSpecificCards: NonNullable<SampleCardsProps['showSpecificCards']>
): SampleCardDataEntry[] => {
    if (showSpecificCards.length) {
        const cardsToShow = showSpecificCards.reduce<SampleCardDataEntry[]>((acc, cardIndex) => {
            const cardProp = `card${cardIndex + 1}` as keyof SampleCardsData;
            const card = cards[cardProp];
            if (card) {
                acc.push(card as SampleCardDataEntry);
            }
            return acc;
        }, []);

        if (cardsToShow.length) {
            return cardsToShow;
        }
    }

    return Object.entries<SampleCardLegendEntry | SampleCardDataEntry>(cards).reduce<SampleCardDataEntry[]>(
        (acc, [key, card]) => {
            if (key.startsWith('card')) {
                acc.push(card as SampleCardDataEntry);
            }
            return acc;
        },
        []
    );
};

const SampleCards: FC<SampleCardsProps> = ({
    cards,
    headingLevel = 3,
    imgHost,
    canShowMultipleColumns = false,
    showSpecificCards = [],
}) => (
    <div className={canShowMultipleColumns ? classes.multiColumn : undefined}>
        {getSampleCardsToDisplay(cards, showSpecificCards).map(card => (
            <div key={card.url} className={classes.cardContainer}>
                <Title size="h6" headingLevel={headingLevel} className="has-rds-mb-8 has-text-centered">
                    {card.title}
                </Title>
                <img className={classes.cardImg} src={prepend(imgHost)(card.url)} alt={card.msg} />
            </div>
        ))}
    </div>
);

export default SampleCards;
