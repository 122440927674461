const validDataTypes = ['string', 'number'];
const isValidDataType = (data: unknown): data is string | number => validDataTypes.includes(typeof data);

/**
 * Replace placeholders in a templated string with values provided by an object
 *
 * @param template A string with templated placeholders wrapped with [ and ]
 * @param data An object where the keys match the template placeholders and values are strings or numbers
 * @return The original string with as many placeholders replaced as there are matching valid data keys
 */
const format = (template = '', data: Record<string, unknown> = {}): string =>
    Object.keys(data).reduce((memo, placeholder) => {
        const replacement = data[placeholder];

        return isValidDataType(replacement) ? memo.replace(`[${placeholder}]`, replacement.toString()) : memo;
    }, template);

export default format;
