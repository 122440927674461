import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from 'src/assets/icons/Plus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/Minus.svg';

const propTypes = {
    isOpen: PropTypes.bool,
    className: PropTypes.string,
};

const defaultProps = {
    isOpen: false,
    className: undefined,
};

const PlusMinusButton = ({ isOpen, className }) =>
    isOpen ? (
        <MinusIcon className={className} focusable="false" />
    ) : (
        <PlusIcon className={className} focusable="false" />
    );

PlusMinusButton.propTypes = propTypes;
PlusMinusButton.defaultProps = defaultProps;

export default PlusMinusButton;
