import React from 'react';

import type { FC } from 'react';

import classNames from 'classnames';

import type { WithContentProps } from 'src/components/ContentProvider';
import type { EmptyComponent } from 'src/app/types';

import compose from 'src/utils/compose';
import { withContent } from '../../components/ContentProvider';

import classes from './LoadingDots.module.scss';

const mapContentToProps = {
    a11yText: 'LblProcessing',
} as const;

type LoadingDotsContentProps = WithContentProps<typeof mapContentToProps>;

type LoadingDotsPublicProps = {
    center?: boolean;
};

export type LoadingDotsProps = LoadingDotsPublicProps & LoadingDotsContentProps & EmptyComponent;

const LoadingDots: FC<LoadingDotsProps> = ({ a11yText, center = false }) => (
    <span className={classNames(classes.root, { [classes.center]: center })}>
        <span className="sr-only">{a11yText}</span>
        <span className={classes.dots} aria-hidden="true">
            <span />
            <span />
            <span />
        </span>
    </span>
);

export { LoadingDots };
export default compose(withContent(mapContentToProps)(LoadingDots)) as FC<
    Omit<LoadingDotsProps, keyof LoadingDotsContentProps>
>;
