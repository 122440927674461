// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CrossButton_root__T\\+Jv\\+ {\n  padding: 4px;\n}", "",{"version":3,"sources":["webpack://./src/UI/Button/CrossButton.module.scss"],"names":[],"mappings":"AAAA;EAGI,YAAA;AADJ","sourcesContent":[".root {\n    // NOTE Because the cross icon is 16x16 and the eye icon is 24x24, we need to add this extra\n    // padding to make this button align with the `EyeButton` in the form fields\n    padding: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CrossButton_root__T+Jv+"
};
export default ___CSS_LOADER_EXPORT___;
