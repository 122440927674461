import React from 'react';

import { InputField } from 'src/UI/Form';
import noop from 'src/utils/noop';
import { getPlainPhoneNumber as getPlainCountryCode, UsaCountryCode } from 'src/utils/phone';
import { noopValidator } from 'src/utils/validation';

import type { FC } from 'react';
import type { EmptyComponent } from 'src/app/types';
import type { Validator } from 'src/utils/validation';

import { useComponentApi, useComponentApiDef } from 'src/hooks';
import type { BaseFormFieldProps } from './types';
import type { InputFieldApi, InputFieldProps } from './InputField';

export type CountryCodeFieldProps = BaseFormFieldProps<HTMLInputElement> &
    Pick<InputFieldProps, 'renderError'> &
    EmptyComponent;

const CountryCodeField: FC<CountryCodeFieldProps> = ({
    name,
    label,
    subLabel,
    defaultValue = UsaCountryCode,
    labelVariant,
    required,
    validate = noopValidator,
    onFocus,
    onBlur,
    onValidityChange = noop,
    onChange,
    onValueChange = noop,
    renderError,
    api: setApi,
    'aria-describedby': describedBy,
}) => {
    const [fieldApi, setFieldApi] = useComponentApi<Record<string, InputFieldApi>>();

    useComponentApiDef<
        InputFieldApi & {
            setFieldValue(phoneNumber: string, countryCode: string): void;
        }
    >(
        {
            setFieldValue(countryCode) {
                fieldApi[name].setFieldValue(countryCode);
            },
            focus() {
                fieldApi[name].focus();
            },
            setFieldError(error) {
                fieldApi[name].setFieldError(error);
            },
            setFieldErrors(validationFailures) {
                fieldApi[name].setFieldErrors(validationFailures);
            },
            resetField() {
                fieldApi[name].resetField();
            },
        },
        setApi,
        name
    );

    return (
        <InputField
            id={name}
            name={name}
            subLabel={subLabel}
            type="text"
            label={label}
            defaultValue={defaultValue}
            autoComplete="tel"
            inputMode="numeric"
            labelVariant={labelVariant}
            required={required}
            onFocus={onFocus}
            onBlur={onBlur}
            maxLength={3}
            filter={getPlainCountryCode}
            validate={
                ((newValue: string) => validate(getPlainCountryCode(newValue))) as Validator<
                    string,
                    string,
                    { field: string; label: string; validationId?: string }
                >
            }
            onChange={onChange}
            onValidityChange={onValidityChange}
            onValueChange={(newValue, fieldName, ev) => onValueChange(getPlainCountryCode(newValue), fieldName, ev)}
            renderError={renderError}
            api={setFieldApi}
            aria-describedby={describedBy}
        />
    );
};
export default CountryCodeField;
