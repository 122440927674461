/* eslint-disable prefer-promise-reject-errors */
const isValid = (elm?: Node): boolean => {
    if (!elm) {
        return false;
    }
    if (elm.nodeType === 1) {
        const htmlElem = elm as HTMLElement;
        if (htmlElem.nodeName.toLowerCase() === 'script') {
            return false;
        }
        for (let i = 0; i < htmlElem.attributes.length; i++) {
            const { name } = htmlElem.attributes[i];
            // invalidate elements with event attributes
            if (typeof name === 'string' && name.toLowerCase().startsWith('on')) {
                return false;
            }
        }

        for (let i = 0; i < htmlElem.childNodes.length; i++) {
            // TODO: test visitor pattern in place of recursion as potential optimization
            if (!isValid(htmlElem.childNodes[i] as HTMLElement)) {
                return false;
            }
        }
    }
    return true;
};

const validateContent = (svgContent?: string | null): string => {
    const parser = new DOMParser();
    if (svgContent && typeof document !== 'undefined') {
        // Preferred method over parsing through innerHTML on an element generated through document.createElement. HTML5 will prevent <script>
        // tags from executing when injected through innerHTML, but it will run event handler content attributes (eg: onerror, onload).
        // DOMParser will not run event handler callbacks, but if the parsed HTML is appended to the Document, nefarious JS can still execute.
        // The `isValid` method below ensures there is no nefarious JS.
        const parsedSvgBody = parser.parseFromString(svgContent, 'text/html').body;
        // SVGs on CDN are expected to only have 1 root <svg> tag (childNode of parsed body)
        const svgElm = parsedSvgBody.childNodes.length === 1 ? parsedSvgBody.getElementsByTagName('svg')[0] : undefined;

        if (svgElm) {
            const svgClass = svgElm.getAttribute('class') || '';
            svgElm.setAttribute('class', `${svgClass} rally-icon`.trim());

            // root element must be an svg
            // lets double check we've got valid elements
            // do not allow scripts
            if (isValid(svgElm)) {
                return parsedSvgBody.innerHTML;
            }
        }
    }
    return '';
};

export const getCdnUrl = (name: string, env?: string): string => {
    const prefix = 'https://member';
    const baseUrl = env === 'prod' ? 'werally.com' : 'int.werally.in';
    const suffix = `assets/icons/${name}.svg`;
    const url = `${prefix}.${baseUrl}/${suffix}`;

    return url;
};

export const rallyIconContent = new Map<string, string>();
export const requestsCache = new Map<string, Promise<string | void>>();

export function makeRequest(url: string, method = 'GET'): Promise<string> {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        // request success or application level error (receives a status code back)
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                const validatedSvg = validateContent(this.response as string);
                if (validatedSvg) {
                    // cache for requests made for the same icon
                    rallyIconContent.set(url, validatedSvg);
                    resolve(validatedSvg);
                } else {
                    rallyIconContent.set(url, '');
                    reject(`Icon SVG fetched for URL=${url} is invalid, please check icon name`);
                }
            } else {
                // set to empty for ssr scenarios
                rallyIconContent.set(url, '');
                reject(`Icon Fetch Failed with status=${this.status} for URL=${url}, please check icon name`);
            }
        };
        // network level error
        xhr.onerror = function () {
            // set to empty for ssr scenarios
            rallyIconContent.set(url, '');
            reject(`Icon Fetch Failed due to a network error when requesting resource URL=${url}`);
        };
        xhr.open(method, url, true);
        xhr.send(null);
    });
}

export const getSvgContent = (url: string): Promise<string | void> => {
    // since we set errored url's to an empty string we cannot use the map's 'has' method, it will return true for an empty string
    const svg = rallyIconContent.get(url);
    const cachedReq = requestsCache.get(url);
    // true if undefined or empty string
    if (!svg) {
        if (cachedReq) {
            return cachedReq;
        }
        if (typeof XMLHttpRequest !== 'undefined' && typeof document !== 'undefined') {
            // eslint-disable-next-line no-console
            const req = makeRequest(url).catch((err: string) => console.error(err));
            requestsCache.set(url, req);
            return req;
        }
        // set to empty for ssr scenarios
        rallyIconContent.set(url, '');
        return Promise.resolve();
    }
    return Promise.resolve(svg);
};
