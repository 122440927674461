import { ensureArray } from 'src/utils/arr';
import { MimeType } from 'src/app/Constants';
import setProp from 'src/utils/setProp';

import type { PlainObject } from 'src/app/types';

import type { LogDataArg, LogFn } from '../types';

type AggregatorLoggerConfig = {
    url: URL;
    send?: (url: URL, logData: LogDataArg) => boolean;
};

/**
 * Obscure fields that contain: "password", "ssn", and "code" (except "zipcode")
 * Lookbehind solution (/password|(?<!zip)code|ssn/i) is not supported on some older browsers
 * Using this much uglier solution until there's better lookbehind support
 */
const reObscuredFieldNames = /password|ssn|((?!zipcode)\b\w*code\w*\b)/i;
/**
 * Sanitize LogData before sending to aggregator
 *
 * @private This is only exported for testing purposes
 * @param logData The LogData to sanitize
 * @returns The sanitized LogData
 */
export const sanitizeAggregatorLogData = (logData: LogDataArg): LogDataArg =>
    ensureArray(logData).map(ld => {
        const sanitized = { ...ld };
        if (sanitized.auditData.form?.data) {
            sanitized.auditData.form.data = Object.entries(sanitized.auditData.form.data).reduce(
                (cleaned, [field, value]) => {
                    const shouldObscure = reObscuredFieldNames.test(field);
                    return setProp(cleaned, field, shouldObscure ? '*'.repeat(String(value).length) : value);
                },
                {}
            );
        }
        return sanitized;
    });

const logDataToJSON = (logData: LogDataArg): PlainObject[] =>
    ensureArray(logData).map(ld => {
        const { optumCorrelationId, ...auditData } = ld.auditData;
        return {
            ...ld,
            /**
             * Direct Logger service expects `logLevel` so we remap it before sending (.toJSON() definition correctly
             * converts to level name string)
             */
            logLevel: ld.level,
            auditData: {
                ...auditData,
                /** Re-map Optum CID value */
                'OPTUM-CID-EXT': optumCorrelationId,
                ...(auditData.portalBrand ? { targetPortal: auditData.portalBrand.toUpperCase() } : null),
            },
        };
    });

const defaultSend: AggregatorLoggerConfig['send'] = (url, logData) => {
    // In order to send JSON via `sendBeacon()`, we have to use a Blob to capture the mime type info
    // See https://stackoverflow.com/a/41729668
    const jsonData = new Blob([JSON.stringify(logDataToJSON(logData))], {
        type: MimeType.Json,
    });
    const result = navigator.sendBeacon(url, jsonData);
    if (!result) {
        console.warn(`Failed to send log to aggregator endpoint: ${String(url)}`);
    }
    return result;
};

export const logToAggregator =
    ({ url, send = defaultSend }: AggregatorLoggerConfig): LogFn =>
    logData => {
        send(url, sanitizeAggregatorLogData(ensureArray(logData)));
    };
