import { FlowNames } from 'src/app/Constants';

const ucFirst = (str: string): string => str[0].toUpperCase() + str.slice(1);

const qaHeaderKeySuffixMap: Record<string, string> = {
    [FlowNames.FULL_STEP_UP]: 'FullStepUp',
    [FlowNames.VERIFY_LOGIN]: 'VerifyLogin',
};

export const getQaHeader = (content: Record<string, unknown>, flowName: string): string => {
    const contentKeySuffix = qaHeaderKeySuffixMap[flowName] ?? ucFirst(flowName);
    const contentKeyPrefix = flowName === FlowNames.LOGIN ? 'Lbl' : 'Hdr';
    return content[`${contentKeyPrefix}${contentKeySuffix}`] as string;
};

type HelpContentType = 'QA' | 'FAQ';

export interface HelpContentEntry {
    Q: string;
    A: string;
}

const getContentFallbackKey = (type: HelpContentType, flowName: string): string => {
    switch (flowName) {
        case FlowNames.FULL_STEP_UP:
        case FlowNames.NO_ACCESS:
        case FlowNames.REGISTER:
            return `HELP_${type}_REGISTER`;
        case FlowNames.SETTINGS:
            return `HELP_${type}_SETTINGS`;
        default:
            return `HELP_${type}_LOGIN`;
    }
};

export const getHelpContentByType =
    (type: HelpContentType) =>
    (portalContent: Record<string, unknown>, flowName: string): HelpContentEntry[] => {
        const flowKeySuffix = flowName.toUpperCase();

        /** The array of keys defines the selection order of content keys by priority.
        1. Flow-specific
        2. Flow fallback (Register or Login content)
        3. Default
    */
        const keyThatHasContent = [
            `HELP_${type}_${flowKeySuffix}`,
            /** NOTE Sometimes this fallback key will be a duplicate of the first entry. This potentially extra failing
            iteration is a negligible performance hit so the tradeoff for simpler code is acceptable. */
            getContentFallbackKey(type, flowName),
            `HELP_${type}_DEFAULT`,
        ].find(key => {
            const c = portalContent[key];
            return Array.isArray(c) && c.length > 0;
        }) as string;

        // Every portal should have the DEFAULT content defined so we won't account for its absence. Default to [] in case
        // the DEFAULT keys had bad JSON and were parsed to empty arrays.
        return (portalContent[keyThatHasContent] ?? []) as HelpContentEntry[];
    };

// Intentionally duplicated for now
interface ImageCardData {
    title: string;
    msg: string;
    url: string;
}

type LegendIndicatorType = 'GreenTriangleNotch' | 'GreenSquareNotch';

interface ImageLegendData {
    title: string;
    msg: string;
    url: string;
    type?: LegendIndicatorType;
}

export interface ImageCardDetails {
    card1?: ImageCardData;
    card2?: ImageCardData;
    legend1?: ImageLegendData;
    legend2?: ImageLegendData;
}

export const getImgCardDetails = (content: Record<string, unknown>): ImageCardDetails | null => {
    const imageCardDetails = content.ImgCardDetails as ImageCardDetails | 'undefined';
    return imageCardDetails === 'undefined' ? null : imageCardDetails;
};
