import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';

import SvgIcon from './SvgIcon';

export interface CheckIconProps extends EmptyComponent {
    fill?: 'currentColor' | 'green';
    className?: string;
}

const fillMap = {
    green: '#1EA21C',
    currentColor: 'currentColor',
};

const CheckIcon: FC<CheckIconProps> = ({ fill = 'currentColor', className }) => (
    <SvgIcon size="sm" className={className}>
        <path
            fill={fillMap[fill]}
            fillRule="evenodd"
            d="M15.708 2.317c.36.36.388.927.083 1.318l-.083.094-.001.002L5.384 14.02 3.97 12.607.294 8.942A.998.998 0 01.293 7.53a1.002 1.002 0 011.415 0l3.677 3.665 8.908-8.879a1.002 1.002 0 011.415.001z"
        />
    </SvgIcon>
);

export default CheckIcon;
