import React from 'react';
import classNames from 'classnames';
import type { RefObject, ReactElement, FC } from 'react';
import Text from 'src/UI/Text/Text';

export interface SubHeadingProps {
    children?: string | ReactElement;
    id?: string;
    className?: string;
    subHeadingRef?: RefObject<HTMLParagraphElement>;
    removeBottomSpacing?: boolean;
}

const SubHeading: FC<SubHeadingProps> = ({ id, className, children, removeBottomSpacing = false, subHeadingRef }) => {
    const commonProps = {
        id,
        size: 'b1',
        className: classNames(
            'has-rds-text-grey-darker has-rds-mh-auto has-text-centered',
            className,
            removeBottomSpacing ? 'has-rds-mb-none' : 'has-rds-mb-32'
        ),
        ref: subHeadingRef,
    } as const;

    return typeof children === 'string' ? (
        <Text {...commonProps} html>
            {children}
        </Text>
    ) : (
        <Text {...commonProps}>{children}</Text>
    );
};

export default SubHeading;
