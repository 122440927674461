import { Predicate } from 'src/app/types';

/**
 * Combine multiple Predicate functions into a new one that returns true if all of the individual Predicate functions
 * return true or false if one or more return false
 *
 * @param tests An array of Predicate functions
 * @returns A Predicate function
 */
export const isAllOf =
    <T = any>(tests: Predicate<T>[]): Predicate<T> =>
    value =>
        tests.every(test => test(value));
