import { createElement, modifyHtml, textNode } from './utils';

/**
 * Inject a11y text into links with `target="_blank"`
 */
export const addA11yTextToExternalLinks = (html: string, a11yText: string): string =>
    modifyHtml(html, wrapper => {
        wrapper.querySelectorAll<HTMLAnchorElement>('a[target="_blank"]').forEach(element => {
            if (element.firstElementChild?.className !== 'sr-only') {
                element.appendChild(textNode(' '));
                element.appendChild(createElement('span', { class: 'sr-only' }, a11yText.trim()));
            }
        });
    });
