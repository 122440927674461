import React, { useContext } from 'react';
import warning from 'warning';
import { getComponentName, wrapComponentName } from 'src/utils/componentUtility';

import identity from 'src/utils/identity';
import { isNullish } from 'src/utils/isNullish';
import setProp from 'src/utils/setProp';

import ContentContext from './ContentContext';
import replacePlaceholders from './utils';

/**
 * A recursive wrapper around `replacePlaceholder()` for use in processing portal content of any form
 *
 * @param {String|Array|Object} contentValue A string value or Array/Object to process
 * @param {Object} portalContent The portal content object
 * @return {String|Array|Object} The passed in contentValue with all curly brace placeholders replaced
 */
const recursiveReplacePlaceholders = (contentValue, portalContent) => {
    if (isNullish(contentValue) || ['number', 'boolean'].includes(typeof contentValue)) {
        // For a nullish, number, or boolean value, return as-is
        // FIXES DE517954 where a nullish `contentValue` would break the whole component
        return contentValue;
    }

    // Handle string value. This will be the most common so we attempt to return with it first
    if (typeof contentValue === 'string') {
        return replacePlaceholders(contentValue, portalContent);
    }

    // Handle array values
    if (Array.isArray(contentValue)) {
        return contentValue.map(v => recursiveReplacePlaceholders(v, portalContent));
    }

    // We've checked for string and array so last supported type is object which we can assume here
    return Object.entries(contentValue).reduce(
        (memo, [k, v]) => setProp(memo, k, recursiveReplacePlaceholders(v, portalContent)),
        {}
    );
};

const ContentHandler = config =>
    typeof config === 'string'
        ? { key: config, process: identity }
        : { key: config.key, process: config.process || identity };

const handleMapContentToPropsObject =
    (mapContentToProps, { suppressMissingKeyWarning = false, componentName = '' } = {}) =>
    portalContent =>
        Object.entries(mapContentToProps).reduce((outContent, [propKey, contentKey]) => {
            // Standardize the content key configuration
            const { key, process } = ContentHandler(contentKey);

            if (typeof portalContent === 'object' && portalContent !== null && key in portalContent) {
                return setProp(outContent, propKey, process(portalContent[key]));
            }

            warning(suppressMissingKeyWarning, `No content key ${key} in withContent(${componentName})`);
            return outContent;
        }, {});

/**
 * An HOC to declaratively retrieve content strings from the loaded content pack, modeled after Redux `connect()`
 *
 * `mapContentToProps` is an Object or Function:
 * - As an object, the values are the content keys and the keys are the props for the content in the wrapped component
 * - As a function, it receives the whole content object as the first arg and the own props of the wrapped component as
 *   the second arg. It should return an object with the keys mapping to the props for the content in the wrapped component
 *
 * ```js
 * const mapContentToProps = {
 *   destProp1: 'TxtContentKey1',
 *   destProp2: 'TxtContentKey2',
 * };
 *
 * // OR
 *
 * const mapContentToProps = ({ TxtContentKey1, TxtContentKey2 }, { ownProp1, ownProp2 }) => ({
 *   destProp1: TxtContentKey1.replace('[PLACEHOLDER]', ownProp1),
 *   destProp2: TxtContentKey2.replace('[PLACEHOLDER]', ownProp2),
 * });
 * ```
 *
 * @param {Function|Object} mapContentToProps The configuration for AEM key retrieval
 * @param {Object} config
 * @param {Boolean} config.suppressMissingKeyWarning When mapContentToProps is an Object, if referenced content are
 *                                                   missing, a warning will be logged in the console. Setting this to
 *                                                   true will suppress this warning. This should rarely be done.
 */
const withContent =
    (mapContentToProps, { suppressMissingKeyWarning = false } = {}) =>
    Component => {
        const mapHandler =
            typeof mapContentToProps === 'function'
                ? mapContentToProps
                : handleMapContentToPropsObject(mapContentToProps, {
                      suppressMissingKeyWarning,
                      componentName: getComponentName(Component),
                  });

        const WithContent = props => {
            const portalContent = useContext(ContentContext);

            if (!portalContent) {
                throw new Error('Cannot use `withContent()` without ContentProvider.');
            }

            const contentProps = Object.entries(mapHandler(portalContent, props)).reduce(
                (c, [key, value]) => setProp(c, key, recursiveReplacePlaceholders(value, portalContent)),
                {}
            );

            return <Component {...props} {...contentProps} />;
        };

        WithContent.displayName = wrapComponentName(Component, WithContent);

        return WithContent;
    };

export default withContent;
