import { SessionStorage } from 'src/app/Storage';
import { logout } from 'src/app/LogoutUtility';
import { buildHsidPath } from 'src/utils/urlUtility';
import { FlowNames } from 'src/app/Constants';
import CommonUtility from 'src/app/CommonUtility';
import { minutes } from 'src/utils/dateTimeUtility';

const storageKey = 'noAccessData';

export const setNoAccessMessageKey = (messageKey, referrerFlow) => {
    SessionStorage.put(storageKey, { messageKey, referrerFlow }, { expires: minutes(2) });
};

export const getNoAccessMessageKey = () => SessionStorage.get(storageKey)?.messageKey;

export const getNoAccessReferrerFlow = () => SessionStorage.get(storageKey)?.referrerFlow ?? null;

export const clearNoAccessMessageKey = () => {
    SessionStorage.remove(storageKey);
};

export const redirectToNoAccess = ({ messageKey, portalBrand, lang, referrerFlow }, useAikyamEndpoint) => {
    if (!messageKey) {
        throw new TypeError('redirectToNoAccess(): `messageKey` argument is required but not provided.');
    }

    setNoAccessMessageKey(messageKey, referrerFlow);

    // NOTE absolute URL required since redirect is handled by external service
    const noAccessUrl = CommonUtility.formURL(
        window.location.origin,
        buildHsidPath({
            includeBase: true,
            flowName: FlowNames.NO_ACCESS,
            portalBrand,
            lang,
        })
    );
    logout(noAccessUrl, useAikyamEndpoint, false);
};
