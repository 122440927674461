type ComponentType = React.ComponentType<any> | string;

/**
 * Get the display name of a React component
 *
 * @param Component A React component or string native component name
 * @return The name of the component or empty string if no component is passed
 */

const MISSING_COMPONENT_NAME = '<MissingComponent>';
export const getComponentName = (Component?: ComponentType): string => {
    // Handle missing data cases
    if (Component == null || Component === '') {
        return MISSING_COMPONENT_NAME;
    }

    return typeof Component === 'string' ? Component : Component.displayName || Component.name || 'Component';
};

/**
 * Create a wrapped component name for use with HOCs
 *
 * @param BaseComponent The component to be wrapped
 * @param hocName The wrapping component or the name of the wrapping component
 * @return A wrapped name generated from the 2 component names
 */
export const wrapComponentName = (BaseComponent: ComponentType, HocComponent: ComponentType): string =>
    `${getComponentName(HocComponent)}(${getComponentName(BaseComponent)})`;
