import React from 'react';

import type { FC } from 'react';

import { useEffectOnMounted, useInitialFocus } from 'src/hooks';
import Title from 'src/UI/Title/Title';
import Text from 'src/UI/Text/Text';
// NOTE DE525054 Disabling HSID logo until further notice but there is intent to re-enable it
// import { HsidLogo } from 'src/UI/PageHeader';

interface NotFoundProps {
    location: string;
}

const NotFound: FC<NotFoundProps> = ({ location }) => {
    useEffectOnMounted(() => {
        document.title = 'Page Not Found - HealthSafe ID\u00AE';
    });

    useInitialFocus();

    return (
        <div className="has-rds-mt-24">
            {/* <HsidLogo /> */}

            {/* We cannot use <Heading /> here because <NotFound /> is rendered when AEMContent fails to load */}
            <Title
                tabIndex={-1}
                size="h1"
                className="has-rds-text-grey-darker has-text-centered hidden-focus"
                headingLevel={1}
            >
                Page Not Found
            </Title>
            <Text className="has-text-centered">
                <strong>404</strong> The requested URL {location} was not found
            </Text>
        </div>
    );
};

export default NotFound;
