import { useHistory } from 'react-router';

import type { Location } from 'history';

import { extractQueryParamsFromRouterLocation } from 'src/utils/urlUtility';
import useConstant from './useConstant';
import useEffectOnMounted from './useEffectOnMounted';

type ParamsObject = Record<string, string>;

export const useConsumedUrlParams = (location: Location, paramsList: string[] = []): ParamsObject => {
    const [locationWithoutParams, consumedParams] = extractQueryParamsFromRouterLocation(location, paramsList);
    const history = useHistory();
    const params = useConstant(consumedParams);

    useEffectOnMounted(() => {
        if (Object.keys(consumedParams).length) {
            history.replace(locationWithoutParams);
        }
    });

    return params;
};

export default useConsumedUrlParams;
