import React from 'react';

import type { FC } from 'react';

import { withContent } from 'src/components/ContentProvider';

import type { WithContentProps } from 'src/components/ContentProvider';
import type { EmptyComponent } from 'src/app/types';

import compose from 'src/utils/compose';

import classes from './LoadingBar.module.scss';

const mapContentToProps = {
    a11yText: 'TxtLoadingPage',
} as const;

type LoadingBarContentProps = WithContentProps<typeof mapContentToProps>;

// a11yText must be optional because it is used in one instance before the content pack is loaded
type LoadingBarPublicProps = {
    a11yText?: string;
    isVisible?: boolean;
};

export type LoadingBarProps = LoadingBarContentProps & LoadingBarPublicProps & EmptyComponent;

const LoadingBar: FC<LoadingBarProps> = ({ a11yText = 'Loading Page', isVisible = false }) => (
    <div className={classes.root}>
        <span className="sr-only" aria-live="polite">
            {isVisible ? a11yText : null}
        </span>
        {isVisible ? <div className={classes.bar} aria-hidden="true" /> : null}
    </div>
);

export { LoadingBar };
// NOTE: Suppressing the missing key warning because this component can be used before content is fetched
export default compose(withContent(mapContentToProps, { suppressMissingKeyWarning: true })(LoadingBar)) as FC<
    Omit<LoadingBarProps, keyof LoadingBarContentProps>
>;
