import React from 'react';

import type { FC } from 'react';

import LoadingDots from 'src/UI/LoadingDots/LoadingDots';

import Button from './Button';
import classes from './SubmitButton.module.scss';

interface SubmitButtonProps {
    children: string;
    loading?: boolean;
    'aria-describedby'?: string;
    id?: string;
}

const SubmitButton: FC<SubmitButtonProps> = ({
    loading = false,
    children,
    id = 'submitBtn',
    'aria-describedby': describedBy,
}) => (
    <div aria-live="polite">
        {loading ? (
            <div className={classes.loadingDots}>
                <LoadingDots />
            </div>
        ) : (
            <Button id={id} type="submit" variant="primary" aria-describedby={describedBy}>
                {children}
            </Button>
        )}
    </div>
);

export default SubmitButton;
