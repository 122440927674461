import type { PlainObject } from 'src/app/types';
import type { Country } from './countries';
import { countryList, isoCountryCodes } from './countries';

export const countryCodeMap: PlainObject<Country> = {};
export const isoCountryCodeMap: PlainObject<Country> = {};

countryList.forEach(country => {
    countryCodeMap[country.countryCode] = country;
    isoCountryCodeMap[country.isoCountryCode] = country;
});

export const CountryCodeBGLocation: { [key: string]: string } = {};

isoCountryCodes.forEach((code, index) => {
    CountryCodeBGLocation[code] = String(-20 * index);
});
