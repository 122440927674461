import { useConstant, useObjectState } from 'src/hooks';

import type { ObjectStateApi } from 'src/hooks';
import type { Predicate } from 'src/app/types';

import type { FormFieldValidationFailure, FormFieldValidationResults } from '../types';

export const NO_ERROR_VALUE = null;

export type FieldState<E = string> = {
    value: string;
    /** @deprecated */
    error: E | typeof NO_ERROR_VALUE;
    errors: FormFieldValidationResults;
    hasFocus: boolean;
    changed: boolean;
    touched: boolean;
};

type FieldStateConstructor = {
    (state?: Partial<FieldState>): FieldState;
};

export const FieldState: FieldStateConstructor = (
    {
        value = '',
        error = NO_ERROR_VALUE,
        errors = [],
        hasFocus = false,
        changed = false,
        touched = false,
    } = {} as FieldState
) => ({
    value,
    error,
    errors,
    hasFocus,
    changed,
    touched,
});

type FieldStateApi = ObjectStateApi<FieldState> & {
    setFieldValue: (newValue: FieldState['value']) => void;
    clearFieldValue: () => void;
    /** @deprecated */
    setFieldError: (newError: FieldState['error']) => void;
    /** @deprecated */
    clearFieldError: () => void;
    setFieldErrors: (newErrors: FieldState['errors']) => void;
    clearFieldErrors: () => void;
    fieldWasTouched: () => void;
};

export const useFieldState = (initialState: FieldState = FieldState()): [FieldState, FieldStateApi] => {
    const [state, objectApi] = useObjectState(initialState);

    const api = useConstant<FieldStateApi>({
        ...objectApi,
        setFieldValue(newValue) {
            objectApi.setField('value', newValue);
        },
        clearFieldValue() {
            objectApi.setField('value', '');
        },
        setFieldError(newError) {
            objectApi.setField('error', newError);
        },
        clearFieldError() {
            objectApi.setField('error', NO_ERROR_VALUE);
        },
        setFieldErrors(newErrors) {
            objectApi.setField('errors', newErrors);
        },
        clearFieldErrors() {
            objectApi.setField('errors', []);
        },
        fieldWasTouched() {
            objectApi.setField('touched', true);
        },
    });

    return [state, api];
};

// Base top-level key selectors
export const getFieldValue = ({ value }: FieldState): FieldState['value'] => value;
export const getFieldErrors = (
    { errors }: FieldState,
    filter?: Predicate<FormFieldValidationFailure>
): FieldState['errors'] => (filter ? errors.filter(filter) : errors);
export const isFieldTouched = ({ touched }: FieldState): FieldState['touched'] => touched;

// Derived data selectors
export const doesFieldHaveValue = (fieldState: FieldState): boolean => getFieldValue(fieldState) !== '';
export const isFieldValid = (fieldState: FieldState): boolean => getFieldErrors(fieldState).length === 0;
export const getFieldFirstError = (
    fieldState: FieldState,
    filter?: Parameters<typeof getFieldErrors>[1]
): string | null => (isFieldValid(fieldState) ? null : getFieldErrors(fieldState, filter)[0]?.reason ?? null);
export const getErrorsForField = (fieldState: FieldState, fieldName: string): FieldState['errors'] =>
    getFieldErrors(fieldState, ({ meta: { field } }) => field === fieldName);
