import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';
import SvgIcon from './SvgIcon';

export interface CircleCheckIconProps extends EmptyComponent {
    className?: string;
}

const CircleCheckIcon: FC<CircleCheckIconProps> = ({ className }) => (
    <SvgIcon size="md" className={className}>
        <circle cx="12" cy="12" r="12" fill="#007000" />
        <polyline
            points="7,12 10,15 17,8"
            stroke="#FFF"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
        />
    </SvgIcon>
);

export default CircleCheckIcon;
