import React from 'react';
import { withContent } from 'src/components/ContentProvider';
import { HelpIcon } from 'src/UI/Icon';
import noop from 'src/utils/noop';
import compose from 'src/utils/compose';

import type { FC, MouseEventHandler } from 'react';
import type { WithContentProps } from 'src/components/ContentProvider';
import type { EmptyComponent } from 'src/app/types';
import Button from './Button';

const mapContentToProps = {
    label: 'LblHelp',
} as const;

type HelpButtonContentProps = WithContentProps<typeof mapContentToProps>;

type HelpButtonPublicProps = {
    id: string;
    className?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
} & EmptyComponent;

export type HelpButtonProps = HelpButtonPublicProps & HelpButtonContentProps;

const HelpButton: FC<HelpButtonProps> = ({ id, className, onClick = noop, label }) => (
    <Button id={id} className={className} variant="link-icon-left" clicked={onClick}>
        <HelpIcon className="has-rds-mr-8" />
        {label}
    </Button>
);

export { HelpButton };
export default compose(withContent(mapContentToProps))(HelpButton) as FC<
    Omit<HelpButtonProps, keyof HelpButtonContentProps>
>;
