import type { UserAuthenticationState } from 'src/flows/SecureAuth/types';
import type { SupportedLanguageCode } from 'src/utils/lang';

/** Generic response components */

export enum OBAErrorCode {
    ACCOUNT_ALREADY_EXISTS = 'account_already_exists',
    ACCOUNT_NOT_FOUND = 'account_not_found',
    AUTHENTICATION_REQUIRED = 'authentication_required',
    AUTHENTICATOR_NOT_FOUND = 'authenticator_not_found',
    BAD_REQUEST = 'bad_request',
    CREDENTIALS_INVALID = 'credentials_invalid',
    EMAIL_INVALID_FORMAT = 'email_invalid_format',
    INACTIVE_ACCOUNT = 'account_inactive',
    INVALID_ANSWER = 'invalid_answer',
    INVALID_CALLBACK = 'invalid_callback',
    INVALID_TOKEN = 'invalid_token',
    OBA_LOCKED = 'oba_locked',
    PASSWORD_VIOLATION = 'pwd_policy_violation',
    PASSWORD_RESET_REQUIRED = 'password_reset_required',
    PASSWORD_RESET_TOKEN_INVALID = 'pwd_reset_token_invalid',
    SESSION_INVALID = 'session_invalid',
    TECHNICAL_ERROR = 'technical_error',
    TOKEN_EXPIRED = 'token_expired', // "Token" in this context refers to the ensue token for an OBA challenge
    UNAUTHORIZED = 'unauthorized',
    VERIFICATION_FAILED = 'verification_failed',
    /* 
       These are the numeric HSID codes previously returned by the /login/preauth endpoint,
       but have now been subsumed under the /login HSID Adapter endpoint
    */
    SUCCESS_RECORD_FOUND = '1000', // Can be removed when /preauth deprecation confirmed
    MULTIPLE_ACCOUNT_FOUND = '1001',
    // Headless user login
    HEADLESS_USER = '1002',
    // Admin disabled
    DISABLED_HSID = '2004',
    // This occurs in an edge case when an admin-locked user calls support to unlock their account.
    // If they attempt to log in before following the e-mailed instructions to reset their MFA, they receive this response
    // And should be prompted to contact support again
    PARTIAL_PROFILE = '2008',
    UPDATE_TERMS = '2005',
    NOT_FOUND = '2001',
    GENERAL_ERROR = '3000',
}

export interface OBAError {
    error: OBAErrorCode;
    /** Human-readable ASCII text providing additional information, used to assist the client
     developer in understanding the error that occurred. */
    errorDescription?: string;
}

interface BaseOBAMeta {
    /** Operation was successful. */
    success: boolean;
    /** Correlation ID, which would be included to all log records. Length from 14 to 255, allowed characters
     ([a-z][A-Z][0-9]_-). */
    correlationId?: string;
    /** A list of errors that apply to the request. Provided and required only when success == false. */
    errors?: OBAError[];
}

export interface OBASuccessMeta extends BaseOBAMeta {
    success: true;
    errors?: never;
}

export interface OBAFailureMeta extends BaseOBAMeta {
    success: false;
    errors: OBAError[];
}

export type OBAMeta = OBASuccessMeta | OBAFailureMeta;

/** Authenticate */

/** Profiling bond authenticator used to complete risk assessment for the authentication process. */
interface ProfilingBondAuthenticator {
    type: 'profilingBond';
    name: 'profilingBond';
    /** JWT with state of profiling for device. */
    profilingBond?: string;
    target?: string;
}

interface PasswordAuthenticator {
    type: 'password';
    name: 'password';
    passwordValue: string;
}

interface EnsueObject {
    value: string;
}

/** Attestation data provided through OBA flow. */
interface AttestationAuthenticator {
    type: 'phone' | 'question' | 'attestationWithReEnrollment';
    name?: string;
    /** OBA attestation */
    attestation?: string;
    /** For OBA reenrollment */
    newAttestation?: string;
    /* Token indicating a new device should not be challenged as new again by TMX if user does
     not explicity trust the device. Does not preclude other risk-based challenges */
    requirementEnsue?: EnsueObject;
    /** Specify if the fact authentication with this specific authenticator
     was successful should be preserved and not required on the device.

     This flag causes creation/update of `DG` cookie. */
    bindMedium?: boolean;
}

/** Contains information needed to login a user and provision IS cookie. */
export interface AuthenticateRequest {
    /** Request to perform adaptive authentication. */
    authenticators: (ProfilingBondAuthenticator | AttestationAuthenticator | PasswordAuthenticator)[];
    namer?: { type: string; value: string };
    triggers?: Array<{ name: string; data: any }>;
    pingUri?: string;
    /** - volatile: volatile IdP Session, typically 12 hours(configurable) expiration
     TimeToIdle and cookie expiration times will be set on cookies by Baker service. */
    protectionLevel?: 'volatile';
}

interface AuthenticateFailureMeta extends BaseOBAMeta {
    /** Encrypted information about authentication methods that would help to authenticate.
     Only present on failed authentication attempt. */
    success: false;
    ensue: string;
}

type AuthenticateMeta = OBASuccessMeta | AuthenticateFailureMeta;

interface ProfilingBondAuthenticatorFeedback {
    type: 'profilingBond';
    name: 'profilingBond';
    ensue?: string;
    next: 'challenge' | 'reject' | 'pass';
}

interface AuthenticateResultData {
    location: string;
    aaToken: string;
    authenticatorFeedback: ProfilingBondAuthenticatorFeedback[];
}

export interface AuthenticateResponse {
    meta: AuthenticateMeta;
    data: AuthenticateResultData;
}

/** Authenticator */

export type AuthenticateMethodType = 'call' | 'sms' | 'question';

interface AuthenticatorMethod {
    method: AuthenticateMethodType;
    ensue: string;
}

export interface PhoneMethod extends AuthenticatorMethod {
    method: 'call' | 'sms';
}

interface QuestionMethod extends AuthenticatorMethod {
    method: 'question';
}

export type AttestationAuthenticatorType = 'phone' | 'question';

export interface Authenticator {
    type: AttestationAuthenticatorType;
    name: string;
    /** Shortened information about the authenticator. */
    hint?: string;
    methods: AuthenticatorMethod[];
}

export interface PhoneAuthenticator extends Authenticator {
    type: 'phone';
    /** Shortened information about the authenticator. */
    hint: string;
    methods: PhoneMethod[];
}

export const isPhoneAuthenticator = (authenticator: Authenticator): authenticator is PhoneAuthenticator =>
    (authenticator as PhoneAuthenticator).type === 'phone';

export interface QuestionAuthenticator extends Authenticator {
    type: 'question';
    methods: QuestionMethod[];
}

export const isQuestionAuthenticator = (authenticator: Authenticator): authenticator is QuestionAuthenticator =>
    (authenticator as QuestionAuthenticator).type === 'question';

export interface AuthenticatorResponse {
    meta: OBAMeta;
    data: (PhoneAuthenticator | QuestionAuthenticator)[];
}

export interface BidResponse {
    meta: OBAMeta;
    data: PhoneAuthenticator;
}

/** Challenge */

export interface ChallengeRequest {
    /** Token that tracks previous user choices before and including authentication method.
     Taken from authenticator response */
    methodEnsue: string;
    count?: number;
}

export interface ChallengeRequestParams {
    locale: SupportedLanguageCode;
}

export interface SecurityQuestion {
    questionId: string;
    questionText: string;
}

export interface Challenge {
    type: 'phone' | 'question';
    method: 'call' | 'sms' | 'question';
    ensue: string;
    question?: SecurityQuestion;
}

export interface QuestionChallenge extends Challenge {
    type: 'question';
    method: 'question';
    question: SecurityQuestion;
}

export interface PhoneChallenge extends Challenge {
    type: 'phone';
    method: 'call' | 'sms';
    question?: never;
}

export interface ChallengeResponse {
    meta: OBAMeta;
    data: Challenge[];
}

/** Attestation */

export interface AttestationAnswer {
    questionId: string;
    answerText: string;
}

interface AttestationRequest {
    /** Token which tracks previous user choices before and including challenge. */
    challengeEnsue: string;
    /** Specific to "phone" authentication — code specified by the user.
     Required, when "phone" authenticator is used. */
    code?: string;
    /** Answers to security questions. */
    answer?: AttestationAnswer;
}

export interface PhoneAttestationRequest extends AttestationRequest {
    code: string;
    answer?: never;
}

export interface QuestionAttestationRequest extends AttestationRequest {
    code?: never;
    answer: AttestationAnswer;
}

interface AttestationData {
    /** Confirmation of successful out-of-band authentication completion. */
    attestation: string;
}

export interface AttestationResponse {
    meta: OBAMeta;
    data?: AttestationData;
}

/** Find Authenticator */

/** Entity that captures real world identification of the account. */
interface AccountNamer {
    type: 'username' | 'email';
    value: string;
}

export interface AuthenticatorByNamersRequest {
    namer: AccountNamer;
    /** Operation for which authenticators should be received. */
    operation: 'reset-password';
}

interface TmxProfile {
    orgId: string;
    rbaSessionId: string;
}

interface ProfilingProvider {
    tmx: TmxProfile;
}

interface ProfilingMetadataResponseData {
    profilingBond: string;
    providers: ProfilingProvider[];
}

export interface ProfilingMetadataResponse {
    meta: OBAMeta;
    data: ProfilingMetadataResponseData;
}

export interface ProfilingMetadataRequestParams {
    target: string;
}

export interface AikyamTmxInfoResponseData {
    userAuthenticationState: UserAuthenticationState;
    aikyamSessionEnsue: string;
    tmxProfileUrl: string;
}

export interface AikyamTmxInfoRequestParams {
    HTTP_TARGETPORTAL: string;
    eventType?: string;
}
