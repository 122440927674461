import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import Modal from 'src/UI/Modal/Modal';
import Accordion from 'src/UI/Accordion/Accordion';
import Text from 'src/UI/Text/Text';
import AppStore from 'src/stores/AppStore';
import AnalyticUtility, { handleAnalyticsTriggerError } from 'src/app/AnalyticUtility';
import HsidPropTypes from 'src/prop-types';
import { LayoutContainer, ContentContainer } from 'src/components/Containers';
import { QaIds } from 'src/app/QaConstants';
import { withContent } from 'src/components/ContentProvider';
import compose from 'src/utils/compose';
import removeHtmlTags from 'src/utils/removeHtmlTags';
import { getContentHost } from 'src/features/PortalData';

import { prepend } from 'src/utils/prepend';
import { SampleCards } from 'src/UI/SampleCards';
import CardLegendIndicator from './subcomponents/CardLegendIndicator/CardLegendIndicator';
import { getHelpContentByType, getQaHeader, getImgCardDetails } from './selectors';

import classes from './HelpModal.module.scss';

// TODO Remove transitional code from uploaded images to standard SVG images after AEM content is updated
const getLegendTypeByPortal = (portalBrand, slotIdx) => {
    switch (portalBrand) {
        case 'fedvip':
        case 'healthnet':
        case 'lincolnfinancial':
        case 'morganwhite':
        case 'medigold':
        case 'solstice':
            return slotIdx === 0 ? 'GreenCircle' : 'BlueSquare';
        case 'mymedica':
            return slotIdx === 0 ? 'GreenOutline' : 'BlueSquareNotch';
        default:
            // Default to the standardized indicator
            return slotIdx === 0 ? 'GreenTriangleNotch' : 'GreenSquareNotch';
    }
};

const getQaContent = getHelpContentByType('QA');
const getFaqContent = getHelpContentByType('FAQ');

const mapContentToProps = (content, { flow }) => ({
    modalHeader: content.LblNeedHelp,
    standaloneSubHeader: content.SubHdrHelpModal,
    cardHelpSubHeader: content.SubHdrFindID,
    cardHelpNote: content.TxtCardHead,
    sampleCardsData: getImgCardDetails(content),
    faqHeader: content.HdrFaq,
    faqItems: getFaqContent(content, flow),
    qaHeader: getQaHeader(content, flow),
    qaItems: getQaContent(content, flow),
});

const helpContentEntryShape = PropTypes.shape({
    Q: PropTypes.string.isRequired,
    A: PropTypes.string.isRequired,
});

const sampleCardDataShape = PropTypes.shape({
    title: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
});

const sampleCardLegendDataShape = PropTypes.shape({
    title: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['GreenTriangleNotch', 'GreenSquareNotch']),
});

const propTypes = {
    onModalClose: PropTypes.func.isRequired,
    modalRef: HsidPropTypes.reactRef.isRequired,
    // NOTE `flow` is only used inside of `mapContentToProps`
    // eslint-disable-next-line react/no-unused-prop-types
    flow: PropTypes.string.isRequired,
    modalHeader: PropTypes.string.isRequired,
    standaloneSubHeader: PropTypes.string,
    cardHelpSubHeader: PropTypes.string.isRequired,
    cardHelpNote: PropTypes.string.isRequired,
    sampleCardsData: PropTypes.shape({
        card1: sampleCardDataShape,
        card2: sampleCardDataShape,
        card3: sampleCardDataShape,
        card4: sampleCardDataShape,
        card5: sampleCardDataShape,
        card6: sampleCardDataShape,
        legend1: sampleCardLegendDataShape,
        legend2: sampleCardLegendDataShape,
    }),
    faqHeader: PropTypes.string.isRequired,
    faqItems: PropTypes.arrayOf(helpContentEntryShape).isRequired,
    qaHeader: PropTypes.string.isRequired,
    qaItems: PropTypes.arrayOf(helpContentEntryShape).isRequired,
};

const defaultProps = {
    standaloneSubHeader: undefined,
    sampleCardsData: null,
};

const HelpModal = ({
    onModalClose,
    modalRef,
    modalHeader,
    standaloneSubHeader,
    cardHelpSubHeader,
    cardHelpNote,
    sampleCardsData,
    faqHeader,
    faqItems,
    qaHeader,
    qaItems,
}) => {
    const { configData, portalBrand } = useContext(AppStore);
    const prependHost = prepend(getContentHost(configData));

    // for each of the link in accordion
    const handleToggleItem = (isOpen, item) => {
        if (isOpen && item.title) {
            const ques = removeHtmlTags(item.title.toLowerCase());
            const accordionLinkData = {
                linkFriendlyName: ques,
                uhcLinkFriendlyName: `accordion:${ques}`,
                uuid: window.pageDataLayer && window.pageDataLayer.user ? window.pageDataLayer.user.uuid : undefined,
                isUhcUser: true,
            };
            AnalyticUtility.trackAccordionLinks(accordionLinkData).catch(
                handleAnalyticsTriggerError(accordionLinkData)
            );
        }
    };
    // for visit link in accordion
    const handleToggleAllItems = isGroupOpen => {
        if (!isGroupOpen) {
            const linkClickedData = {
                processName: 'trackLink',
                linkFriendlyName: 'VIEW_ALL_ANSWER',
                uhcLinkFriendlyName: 'VIEW_ALL_ANSWER_UHC',
            };
            AnalyticUtility.onLinkClickedData(linkClickedData).catch(handleAnalyticsTriggerError(linkClickedData));
        }
    };

    return (
        <Modal
            id={QaIds.HELP_MODAL}
            handleCloseModal={onModalClose}
            modalRef={modalRef}
            header={modalHeader}
            containContent={false}
            analyticsData={{ pageName: 'modal:help', uhcPageName: 'help' }}
        >
            <div className={classes.root}>
                {sampleCardsData ? (
                    <LayoutContainer>
                        <ContentContainer centerText className="has-rds-mb-40">
                            <Text size="b2" className="has-rds-mb-8" html>
                                {cardHelpSubHeader}
                            </Text>
                            <Text size="small" className="has-rds-mb-40">
                                {cardHelpNote}
                            </Text>

                            <div className={classes.helpImgBody}>
                                <SampleCards
                                    canShowMultipleColumns
                                    cards={sampleCardsData}
                                    imgHost={getContentHost(configData)}
                                />
                                <div className={`has-rds-mt-16 ${classes.legendsContainer}`}>
                                    {sampleCardsData.legend1 && (
                                        <div className={classes.legendContainer}>
                                            {/* TODO Remove transitional code from uploaded images to standard SVG images after AEM content is updated */}
                                            <CardLegendIndicator
                                                className={`has-rds-mr-8 ${classes.legendImg}`}
                                                type={
                                                    sampleCardsData.legend1.type ||
                                                    getLegendTypeByPortal(portalBrand, 0)
                                                }
                                                src={prependHost(sampleCardsData.legend1.url)}
                                                a11yText={sampleCardsData.legend1.msg}
                                            />
                                            <Text tag="span" size="b1">
                                                {sampleCardsData.legend1.title}
                                            </Text>
                                        </div>
                                    )}
                                    {sampleCardsData.legend2 && (
                                        <div className={classes.legendContainer}>
                                            {/* TODO Remove transitional code from uploaded images to standard SVG images after AEM content is updated */}
                                            <CardLegendIndicator
                                                className={`has-rds-mr-8 ${classes.legendImg}`}
                                                type={
                                                    sampleCardsData.legend2.type ||
                                                    getLegendTypeByPortal(portalBrand, 1)
                                                }
                                                src={prependHost(sampleCardsData.legend2.url)}
                                                a11yText={sampleCardsData.legend2.msg}
                                            />
                                            <Text tag="span" size="b1">
                                                {sampleCardsData.legend2.title}
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ContentContainer>
                    </LayoutContainer>
                ) : standaloneSubHeader ? (
                    <LayoutContainer>
                        <ContentContainer centerText className="has-rds-mb-40">
                            <Text size="b2" className="has-rds-mb-8" html>
                                {standaloneSubHeader}
                            </Text>
                        </ContentContainer>
                    </LayoutContainer>
                ) : null}

                <div className={classes.accordionOuter}>
                    <LayoutContainer>
                        {/* For FAQ Section */}
                        {faqItems.length > 0 ? (
                            <Accordion
                                id="helpModalFaq"
                                header={faqHeader}
                                content={faqItems}
                                onToggle={handleToggleItem}
                                onToggleAll={handleToggleAllItems}
                            />
                        ) : null}

                        {/* For QA Section */}
                        {qaItems.length > 0 ? (
                            <Accordion
                                id="helpModalQa"
                                header={qaHeader}
                                content={qaItems}
                                onToggle={handleToggleItem}
                                onToggleAll={handleToggleAllItems}
                            />
                        ) : null}
                    </LayoutContainer>
                </div>
            </div>
        </Modal>
    );
};

HelpModal.propTypes = propTypes;
HelpModal.defaultProps = defaultProps;

export default compose(withContent(mapContentToProps))(HelpModal);
export { HelpModal };
