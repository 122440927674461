import React from 'react';

import type { FC } from 'react';

import type { EmptyComponent } from 'src/app/types';

import SvgIcon from './SvgIcon';

export interface AlertIconProps extends EmptyComponent {
    fill?: 'currentColor' | 'red';
    className?: string;
}

const fillMap = {
    red: '#AC2B00',
    currentColor: 'currentColor',
};

const AlertIcon: FC<AlertIconProps> = ({ fill = 'currentColor', className }) => (
    <SvgIcon size="md" className={className}>
        <path
            fill={fillMap[fill]}
            fillRule="evenodd"
            d="M11.984 3c.854 0 1.706.557 2.349 1.673l7.108 12.254c1.284 2.23.282 4.073-2.254 4.073H4.813c-2.537 0-3.538-1.843-2.254-4.073L9.635 4.673C10.277 3.557 11.13 3 11.985 3zm-.01 12.6c-.69 0-1.25.55-1.25 1.228 0 .677.56 1.227 1.25 1.227s1.25-.55 1.25-1.227a1.24 1.24 0 00-1.25-1.228zm0-8.92c-.69 0-1.25.56-1.25 1.25v4.864a1.25 1.25 0 102.5 0V7.93c0-.69-.56-1.25-1.25-1.25z"
        />
    </SvgIcon>
);

export default AlertIcon;
