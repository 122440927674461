/**
 * Extensions to native {@link Error} object
 *
 * `.toJSON()` type definition added in `src/globals.d.ts`
 *
 * @fixes DE262881
 */
if (typeof Error.prototype.toJSON === 'undefined') {
    Object.defineProperty(Error.prototype, 'toJSON', {
        value(this: Error) {
            return {
                name: this.name,
                message: this.message,
                stack: this.stack,
                ...(this.cause ? { cause: this.cause } : null),
            };
        },
        writable: true,
        configurable: true,
    });
}

// NOTE empty export needed to compile under `isolatedModules`
export {};
